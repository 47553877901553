import React from 'react';
import { DxCard, DxCardContent, DxContainer } from '@dvag/design-system-react';

import { HouseholdLoading } from './household/load/HouseholdLoading';

import './style.css';
import '../style.css';

const rows = Array.from(Array(4).keys());

export const Loading = () => (
  <DxCard className="loading-card" data-testid="household-list-loading">
    <DxCardContent className="loading-wr">
      {rows.map((val) => (
        <DxContainer color="white" className="small-layout-item-loading" key={val}>
          <HouseholdLoading />
        </DxContainer>
      ))}
    </DxCardContent>
  </DxCard>
);
