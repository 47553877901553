import { useMutation, useQueryClient } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { ConsentPayload } from 'type/person';
import { useNotification } from 'utils/notification/NotificationContext';
import { CustomError } from '@dvag/dfs-api-client/error';

type ConsentPayloadType = {
  callbackLink?: string;
};

type ConsentPropsType = {
  personId: string;
  householdId: string;
  callbackLink?: string;
};

export const useSendConsent = ({ householdId, personId }: ConsentPropsType) => {
  const { showError } = useNotification();
  const queryClient = useQueryClient();
  const personService = personApi();

  return useMutation<
    Awaited<ReturnType<typeof personService.createConsent>> & { id: string },
    CustomError,
    ConsentPayloadType,
    unknown
  >({
    mutationFn: async (payload: ConsentPayloadType) => {
      const consentPayload: ConsentPayload = {
        householdId,
        ...(payload?.callbackLink?.length && { callbackLink: payload?.callbackLink }),
      };

      const response = await personService.createConsent(personId, consentPayload);

      return {
        ...response,
        id: personId,
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getHouseholdDashboard'] });
    },
    onError: (err: CustomError) => {
      showError({ status: err?.status });
    },
  });
};
