import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type ConfigSchemaDto = {
  __typename?: 'ConfigSchemaDto';
  pages: Array<PageDto>;
  settings: Array<SettingDto>;
};


export type ConfigSchemaDtoPagesArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};

export type PageDto = {
  __typename?: 'PageDto';
  aliases: Array<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  featureFlags: Array<Scalars['String']['output']>;
  isDefaultFavorite?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orderPreviousPage?: Maybe<Scalars['String']['output']>;
  permissions: Array<Scalars['String']['output']>;
  type: Array<Type>;
};

export type Query = {
  __typename?: 'Query';
  config: ConfigSchemaDto;
};

export type SettingDto = {
  __typename?: 'SettingDto';
  code: Scalars['String']['output'];
  default: Array<Scalars['String']['output']>;
  isAllowedMultiple: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
};

export enum Type {
  BurgerMenu = 'BURGER_MENU',
  BurgerMenuSearch = 'BURGER_MENU_SEARCH',
  Favorite = 'FAVORITE'
}

export type GetPagesConfigurationQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPagesConfigurationQuery = { __typename?: 'Query', config: { __typename?: 'ConfigSchemaDto', pages: Array<{ __typename?: 'PageDto', code: string, name: string, type: Array<Type>, isDefaultFavorite?: boolean | null, orderPreviousPage?: string | null, permissions: Array<string>, featureFlags: Array<string>, aliases: Array<string> }> } };

export type GetSettingsConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingsConfigurationQuery = { __typename?: 'Query', config: { __typename?: 'ConfigSchemaDto', settings: Array<{ __typename?: 'SettingDto', code: string, default: Array<string> }> } };



export const GetPagesConfigurationDocument = `
    query getPagesConfiguration($type: String) {
  config {
    pages(type: $type) {
      code
      name
      type
      isDefaultFavorite
      orderPreviousPage
      permissions
      featureFlags
      aliases
    }
  }
}
    `;

export const useGetPagesConfigurationQuery = <
      TData = GetPagesConfigurationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetPagesConfigurationQueryVariables,
      options?: Omit<UseQueryOptions<GetPagesConfigurationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetPagesConfigurationQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetPagesConfigurationQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['getPagesConfiguration'] : ['getPagesConfiguration', variables],
    queryFn: fetcher<GetPagesConfigurationQuery, GetPagesConfigurationQueryVariables>(client, GetPagesConfigurationDocument, variables, headers),
    ...options
  }
    )};

export const GetSettingsConfigurationDocument = `
    query getSettingsConfiguration {
  config {
    settings {
      code
      default
    }
  }
}
    `;

export const useGetSettingsConfigurationQuery = <
      TData = GetSettingsConfigurationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetSettingsConfigurationQueryVariables,
      options?: Omit<UseQueryOptions<GetSettingsConfigurationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetSettingsConfigurationQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetSettingsConfigurationQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['getSettingsConfiguration'] : ['getSettingsConfiguration', variables],
    queryFn: fetcher<GetSettingsConfigurationQuery, GetSettingsConfigurationQueryVariables>(client, GetSettingsConfigurationDocument, variables, headers),
    ...options
  }
    )};
