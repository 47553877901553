import React from 'react';
import { useParams } from 'react-router-dom';

import {
  DxCard,
  DxCardContent,
  DxContainer,
  DxIcon,
  DxList,
  DxText,
} from '@dvag/design-system-react';

import i18next from 'i18next';

import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { dashboardUtil } from 'service/model/dashboardUtil';
import { CooperationSectionListData } from 'type/sectionList';
import { checkIcon } from 'utils/util';
import { Item } from 'component/BottomCards/Card/Item';
import { getContext } from '@dvag/dfs-orchestrator-client';

import { useUtil } from './util';

import '../BottomCard/style.css';

const { ADVISOR, RECOMMENDATION, DREAM_JOB } = dashboardUtil;

interface CooperationCardProps {
  dashboardPath: string;
}

export const CooperationCard = ({ dashboardPath }: CooperationCardProps) => {
  const { householdId, section } = useParams();
  const recommendationPermission = getContext()?.user?.permissions.includes(
    'permission.vpd.app-web-vp-digital.empfehlungsnahme',
  );

  const teambuildingPermission = getContext()?.user?.permissions.includes(
    'permission.vpd.app-web-vp-digital.teamaufbau',
  );

  const { cooperationList, recommendationList, teambuildingList } = useUtil({
    recommendationPermission,
    teambuildingPermission,
    dashboardPath,
  });

  const hasRecommendationOrTeambuildingPermission =
    recommendationPermission || teambuildingPermission;

  if (!hasRecommendationOrTeambuildingPermission) {
    return null;
  }

  const pathToDashboard = `/haushalt/${householdId}/${dashboardPath}`;

  const translation = {
    cooperation: i18next.t('dashboard.cooperation'),
    teamBuilding: i18next.t('dashboard.cooperationList.dreamJob'),
    clientsAcquireClients: i18next.t('dashboard.cooperationList.clientsAcquireClients'),
  };

  let list: CooperationSectionListData[] = cooperationList;

  if (section === RECOMMENDATION) {
    list = recommendationList;
  }

  if (section === DREAM_JOB) {
    list = teambuildingList;
  }

  return (
    <DxCard style={{ overflow: 'hidden' }} data-testid="cooperation-card-container">
      <DxContainer color="white" className="title-container">
        {section !== RECOMMENDATION && section !== DREAM_JOB ? (
          <DxText className="bottom-list-title">{translation.cooperation}</DxText>
        ) : (
          <DxText
            onClick={() => handleNavigateToPath(`${pathToDashboard}`, '', ADVISOR)}
            className="bottom-list-title"
            data-testid="cooperation-list-title"
          >
            <DxIcon color="default" icon={checkIcon('pfeil-links')} size={24} className="chevron" />
            {section === RECOMMENDATION
              ? translation.clientsAcquireClients
              : translation.teamBuilding}
          </DxText>
        )}
      </DxContainer>
      <DxCardContent className="dashboard-card-content">
        <div className="flex column">
          <DxList size="m">
            {list.map(({ title, icon, path, id }) => (
              <Item
                id={id}
                key={`${id}-${path}`}
                cursorStyle={householdId ? 'pointer' : 'default'}
                onItemClick={() => {
                  if (!householdId) return;
                  handleNavigateToPath(path.route, householdId, path?.microApp);
                }}
                title={title}
                icon={icon}
              />
            ))}
          </DxList>
        </div>
      </DxCardContent>
    </DxCard>
  );
};
