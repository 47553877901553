import { DxTextInput } from '@dvag/design-system-react';
import React from 'react';
import './style.css';
import i18next from 'i18next';

type SearchInputProps = {
  isDisabled: boolean;
  onSearch: (value: string) => void;
  searchValue: string;
};

const SearchInput = ({ isDisabled, onSearch, searchValue }: SearchInputProps) => (
  <DxTextInput
    size="m"
    placeholder={i18next.t('general.pleaseEnter')}
    value={searchValue}
    data-testid="favorites-search"
    id="favorites-search"
    disabled={isDisabled}
    className="search-bar"
    icon="lupe"
    onValueChange={(e) => onSearch(e?.detail?.trim())}
  />
);

export default SearchInput;
