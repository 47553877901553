import React, { useState, ReactElement } from 'react';

import { AccordionItem } from './AccordionItem';

import './style.css';

interface AccordionProps {
  children: { id: string; isOpen: boolean; content: ReactElement }[];
}

export const Accordion = (props: AccordionProps) => {
  const expandedItems: Record<string, boolean> = {};
  const { children } = props;

  children
    .filter((section) => section.isOpen)
    .forEach(({ id }) => {
      expandedItems[id] = true;
    });

  const [openSections] = useState(expandedItems);

  return (
    <>
      {children.map((section) => (
        <AccordionItem
          key={`accordion-item-${section.id}`}
          isOpen={openSections[section.id]}
          content={section.content}
        />
      ))}
    </>
  );
};
