import { DxProcessHeader, DxProcessHeaderItem } from '@dvag/design-system-react';
import i18next from 'i18next';

export const SettingHeader = () => {
  const orchestratorContext = window.orchestratorContext.user;

  const { aussendienst, assistent } = {
    aussendienst: 'AUSSENDIENST',
    assistent: 'ASSISTENT',
  };
  const { firstName: advisorFirstName, lastName: AdvisorLastName } = orchestratorContext.advisor;

  const isAssistant =
    !!orchestratorContext?.parentUserId &&
    (orchestratorContext?.role === aussendienst || orchestratorContext?.role === assistent);

  const advisorName = `${advisorFirstName} ${AdvisorLastName}`;

  const name = isAssistant
    ? `${orchestratorContext?.assistant.firstName} ${orchestratorContext?.assistant.lastName}`
    : advisorName;

  const processHeaderItemLabel = isAssistant
    ? `${name} ${i18next.t('settings.behalf')} ${advisorName}`
    : name;

  return (
    <DxProcessHeader
      headline={i18next.t('settings.header')}
      peopleprefix={i18next.t('settings.advisor')}
      data-testid="setting-process-header"
    >
      <DxProcessHeaderItem
        data-testid="setting-process-header-item"
        label={processHeaderItemLabel}
      />
    </DxProcessHeader>
  );
};
