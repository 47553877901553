import { SelectedOption } from '../../graphql/advisor/generates';

export const CodeTypes = {
  CONCEPT_WITH_STRATEGY: 'CONCEPT_WITH_STRATEGY',
  QUICK_ACCESS_TOGGLE: 'QUICK_ACCESS_TOGGLE',
  WEB_VP_SLIDE: 'WEB_VP_SLIDE',
} as const;

export type CodeType = (typeof CodeTypes)[keyof typeof CodeTypes];

export type SettingDto = {
  code: CodeType;
  id?: string | null;
  isAllowedMultiple?: boolean | null;
  name?: string | null;
  options?: SelectedOption[] | null;
  selectedOptions: SelectedOption[];
};
