import { DxContainer, DxList, DxListSection } from '@dvag/design-system-react';
import i18next from 'i18next';
import { FC } from 'react';
import { FavoritePage } from '@dvag/dfs-ui-blocks/components';
import { EmptyFavoritesText } from '../EmptyFavoritesText';
import { FavoritesItem } from './FavoritesItem';
import './style.css';

type FavoritesCatalogProps = {
  catalogFavorites: FavoritePage[];
  onItemClick: (item: FavoritePage) => void;
};

export const FavoritesCatalog: FC<FavoritesCatalogProps> = ({ catalogFavorites, onItemClick }) => (
  <DxContainer className="favorites-selection-list" color="transparent">
    <DxListSection label={i18next.t('favorites.favoritesSelection')} />
    <DxList size="l" className="list-container" lang="de">
      {catalogFavorites.length ? (
        catalogFavorites
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => (
            <FavoritesItem key={item.code} label={item.name} onClick={() => onItemClick(item)} />
          ))
      ) : (
        <EmptyFavoritesText isCatalogText />
      )}
    </DxList>
  </DxContainer>
);
