import { DxContainer, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';
import React from 'react';

export const EmptyFavoritesText = ({ isCatalogText = false }: { isCatalogText?: boolean }) => (
  <DxContainer color="transparent" className="no-items-container" data-testid="no-items">
    <DxText type="ps" color="gray-66">
      {isCatalogText ? i18next.t('favorites.noItemsCanBeSaved') : i18next.t('favorites.noItems')}
    </DxText>
  </DxContainer>
);
