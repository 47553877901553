import React from 'react';
import { DxButton, DxContainer } from '@dvag/design-system-react';
import i18next from 'i18next';
import { checkIcon } from 'utils/util';
import './style.css';

type OGCButtonsProps = {
  id: string;
  'data-testid': string;
  handleForwardClick: () => void;
  handleBackClick: () => void;
};

export const OGCButtons = ({
  id,
  'data-testid': dataTestId,
  handleForwardClick,
  handleBackClick,
}: OGCButtonsProps) => (
  <DxContainer color="transparent" className="ogc-buttons">
    <DxContainer className="button-container" color="transparent">
      <DxButton
        stretch
        type="text"
        data-testid={`${dataTestId || id}-ogc-back`}
        id={`${id}-ogc-back`}
        onClick={handleBackClick}
        label={i18next.t('OGC.buttons.back')}
        icon={checkIcon('pfeil-links')}
      />
    </DxContainer>
    <DxContainer className="button-container" color="transparent">
      <DxButton
        stretch
        type="primary-s"
        data-testid={`${dataTestId || id}-ogc-next`}
        id={`${id}-ogc-next`}
        onClick={handleForwardClick}
        iconposition="right"
        label={i18next.t('OGC.buttons.next')}
        icon={checkIcon('pfeil-rechts')}
      />
    </DxContainer>
  </DxContainer>
);
