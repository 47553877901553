import { appNames } from '@dvag/dfs-constant-config/app-names';
import { navigate } from '@dvag/dfs-orchestrator-client/messengers';
import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { DxCard, DxCardContent, DxCardHeader, DxList } from '@dvag/design-system-react';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';

import { Item } from 'component/BottomCards/Card/Item';

import { leftCardSections } from './pages.config';

export const LeftCard = () => {
  const { householdId } = useParams();
  const navigateNext = (path: string) =>
    path === 'publicPension'
      ? navigate(
          `/${appNames.accounting}/haushalt/${householdId}/analyse/basisdaten/staatliche-rente`,
          `/${appNames.accounting}/haushalt/${householdId}/analyse/basisdaten/wohnsituation`,
        )
      : navigateToMicroApp(
          appNames.accounting,
          `/haushalt/${householdId}/analyse/basisdaten/wohnsituation`,
        );

  return (
    <DxCard>
      <DxCardHeader label={i18next.t('analyseDashboard.basicData.header')} />
      <DxCardContent className="dashboard-card-content">
        <div className="flex column">
          <DxList size="m">
            {leftCardSections.map(({ icon, id, path, title }) => (
              <Item
                id={id}
                key={id}
                title={title}
                icon={icon}
                onItemClick={() => navigateNext(path)}
              />
            ))}
          </DxList>
        </div>
      </DxCardContent>
    </DxCard>
  );
};
