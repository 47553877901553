export const route: Record<string, string> = {
  home: '/',
  householdDashboard: '/haushalt',
  householdDashboardId: '/haushalt/:householdId/uebersicht',
  householdDashboardIdSection: '/haushalt/:householdId/uebersicht/sektion/:section',
  advisoryDashboardIdSection: '/haushalt/:householdId/beratung/sektion/:section',
  search: '/haushalt/suche/:fullName',
  placeholder: '/haushalt/abfrage/:selectedPersonId',
  teamBuilding: '/haushalt/:householdId/teamaufbau',
  teamBuildingWithoutHH: '/haushalt/teamaufbau',
  recommendationPage: '/haushalt/:householdId/weiterempfehlung',
  recommendationPageSelectedSection: '/haushalt/:householdId/weiterempfehlung/:selectedSection',
  recommendationPresentation: '/haushalt/:householdId/empfehlungprasentation',
  teamBuildingPresentation: '/haushalt/:householdId/teamaufbauprasentation',
  advisoryDashboard: '/haushalt/:householdId/beratung',
  analyseDashboard: '/haushalt/:householdId/analyse',
  analyseDashboardSection: '/haushalt/:householdId/analyse/sektion/:section',
  favorites: '/haushalt/:householdId/favoriten',
  evaluateBefore: '/haushalt/:householdId/analyse/terminabschluss/bewertung-davor',
  evaluateAfter: '/haushalt/:householdId/analyse/terminabschluss/bewertung-danach',
  wishesAndReferrals:
    '/haushalt/:householdId/analyse/terminabschluss/empfehlung-und-anwerbung/wuensche-und-empfehlungen',
  recruiting: '/haushalt/:householdId/analyse/terminabschluss/empfehlung-und-anwerbung/werben',
  setting: '/einstellungen',
  settingId: '/haushalt/:householdId/einstellungen',
};
