import { useRef, useState, useEffect } from 'react';
import {
  addInitListener,
  removeInitListener,
  addContextUpdateListener,
  removeContextUpdateListener,
} from '@dvag/dfs-orchestrator-client';

import type { OrchestratorContext } from '@dvag/dfs-constant-config/interface/orchestrator-context';

type UseOrchestratorContextType = [boolean, OrchestratorContext | Record<string, unknown>];

export const useOrchestratorContext = (): UseOrchestratorContextType => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [context, setContext] = useState<OrchestratorContext | Record<string, unknown>>({});

  const initListener = useRef<number | null>(null);
  const contextUpdateListener = useRef<string | null>(null);

  useEffect(() => {
    initListener.current = addInitListener((initialContext: OrchestratorContext) => {
      window.orchestratorContext = initialContext;
      setIsInitialized(true);
      setContext(initialContext);
    });

    contextUpdateListener.current = addContextUpdateListener(
      (updatedContext: OrchestratorContext) => {
        window.orchestratorContext = updatedContext;
        setContext(updatedContext);
      },
    );

    return () => {
      setIsInitialized(false);
      setContext({});

      removeContextUpdateListener(contextUpdateListener.current as string);
      removeInitListener(initListener.current as number);
    };
  }, []);

  return [isInitialized, context] as UseOrchestratorContextType;
};
