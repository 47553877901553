import { DxCard, DxContainer, DxSpacer, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';
import { Booking } from 'type/advisor';
import { QRCodeComponent } from '../QRCodeComponent/QRCodeComponent';
import { RecommendationBasicPageActionButtons } from '../RecommendationBasicPageActionButtons/RecommendationBasicPageActionButtons';

interface RecommendationBasicPageTeambuildingCardProps {
  advisorUrl: string;
  teambuildingUrl: string;
  booking?: Booking;
}

export const RecommendationBasicPageTeambuildingCard = ({
  advisorUrl,
  teambuildingUrl,
  booking,
}: RecommendationBasicPageTeambuildingCardProps) => {
  const translation = {
    dreamJob: i18next.t('dreamJobPage.dreamJob'),
    homepageCardTextList1: i18next.t('recommendationPage.homepageCardTextList1'),
    homepageLinkTextList3: i18next.t('recommendationPage.homepageLinkTextList3'),
    dreamJobTextList2: i18next.t('dreamJobPage.dreamJobTextList2'),
    dreamJobCardText1: i18next.t('dreamJobPage.dreamJobCardText1'),
    dreamJobCardText2: i18next.t('dreamJobPage.dreamJobCardText2'),
  };
  return (
    <DxContainer color="transparent" type="page">
      <DxCard
        className="recommendation-basic-page-card recommendation-basic-page-teambuilding-card"
        data-testid="recommendation-basic-page-teambuilding-card"
      >
        <div className="recommendation-basic-page-card-content recommendation-basic-page-teambuilding-card-content">
          <div className="recommendation-basic-page-card-content-text">
            <DxText type="Headline-5">{translation.dreamJobCardText1}</DxText>
            <DxSpacer size="16" />
            <DxText>{translation.dreamJobCardText2}</DxText>
            <DxSpacer size="16" />
            <DxText>{translation.homepageCardTextList1}</DxText>
            <DxText>{translation.dreamJobTextList2}</DxText>
            <DxText>{translation.homepageLinkTextList3}</DxText>
          </div>
          <div className="recommendation-basic-page-card-content-qr">
            <QRCodeComponent ecLevel="H" url={advisorUrl} />
          </div>
        </div>
        <RecommendationBasicPageActionButtons
          teambuildingUrl={teambuildingUrl}
          teambuilding
          booking={booking}
        />
      </DxCard>
    </DxContainer>
  );
};
