import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { navigate } from '@dvag/dfs-orchestrator-client/messengers';
import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';
import { appNames } from '@dvag/dfs-constant-config/app-names';

import { buildPath } from 'service/buildPath';
import { advisoryDashboard } from 'config/asset/advisoryDashboard';
import { usePermission } from 'utils/permission/usePermission';
import { AdvisorySectionListData } from 'type/sectionList';
import { SimulationType } from 'config/asset/constant';

export interface UpperList {
  id: string;
  title: string;
  onClick: () => void;
  subtitle: string;
  img: string;
  show: boolean;
  coverPosition: string;
}

const navigateToMicroFrontend = (microAppId: string, path: string, nextUrl?: string) => {
  navigate(`/${microAppId}/${path}`, nextUrl);
};

const { beratungThumbnail, futureFinancialSituationThumbnail, futureHHBThumbnail, recapThumbnail } =
  advisoryDashboard;

export const useScreenData = () => {
  const { householdId } = useParams();

  const permission = usePermission();
  const canAccessNSimulation = useFeatureIsOn('permission.vpd.app-web-vp-digital.n-simulationen');

  const navigateToExpensesSimulation = async () => {
    navigateToMicroFrontend(
      appNames.accounting,
      `simulation/${SimulationType.FUTURE_HOUSEHOLD_BUDGET}/haushalt/${householdId}/gateway`,
      `/${appNames.advisor}${buildPath('/haushalt/:householdId/beratung/', {
        householdId: householdId as string,
      })}`,
    );
  };

  const upperList: UpperList[] = [
    {
      id: 'Recap',
      title: i18next.t('advisoryDashboard.recap.title'),
      onClick: () =>
        navigateToMicroFrontend(
          appNames.sectionReport,
          `haushalt/${householdId}/analyse/gespraechszusammenfassung/recap`,
          `/${appNames.advisor}${buildPath('/haushalt/:householdId/beratung/', {
            householdId: householdId as string,
          })}`,
        ),
      subtitle: i18next.t('advisoryDashboard.recap.subtitle'),
      img: recapThumbnail,
      show: permission.canAccessSectionReport,
      coverPosition: 'center 20%',
    },
    {
      id: 'Beratung',
      title: i18next.t('advisoryDashboard.beratung.title'),
      onClick: () =>
        navigateToMicroFrontend(
          appNames.catalog,
          buildPath(sharedAppRoutes.catalog.productCatalog, { householdId: householdId as string }),
        ),
      subtitle: i18next.t('advisoryDashboard.beratung.subtitle'),
      img: beratungThumbnail,
      show: true,
      coverPosition: permission.canAccessSectionReport ? 'center 20%' : 'center 30%',
    },
    {
      id: 'FinancialSituation',
      title: canAccessNSimulation
        ? i18next.t('advisoryDashboard.futureHHB.title')
        : i18next.t('advisoryDashboard.financeSituation.title'),
      onClick: navigateToExpensesSimulation,
      subtitle: canAccessNSimulation
        ? i18next.t('advisoryDashboard.futureHHB.subtitle')
        : i18next.t('advisoryDashboard.financeSituation.subtitle'),
      img: canAccessNSimulation ? futureHHBThumbnail : futureFinancialSituationThumbnail,
      show: true,
      coverPosition: permission.canAccessSectionReport ? 'center 35%' : 'center 30%',
    },
  ];
  const bottomList: AdvisorySectionListData[] = [
    {
      id: 'RentenInformation',
      title: i18next.t('advisoryDashboard.rentenInformation'),
      onClick: () => {
        navigateToMicroFrontend(
          appNames.accounting,
          `haushalt/${householdId}/analyse/basisdaten/staatliche-rente`,
          `/${appNames.advisor}${buildPath('/haushalt/:householdId/beratung/', {
            householdId: householdId as string,
          })}`,
        );
      },
      icon: 'hausrat-02',
      show: true,
    },
    {
      id: 'FinancialBalanceSheet',
      title: i18next.t('advisoryDashboard.financialBalanceSheet'),
      onClick: () => {
        navigateToMicroFrontend(
          appNames.accounting,
          `haushalt/${householdId}/analyse/vermoegensbilanz`,
          `/${appNames.advisor}${buildPath('/haushalt/:householdId/beratung/', {
            householdId: householdId as string,
          })}`,
        );
      },
      icon: 'rechtsschutz-02',
      show: permission.canAccessBalanceSheet,
    },
    {
      id: 'ContractOverview',
      title: i18next.t('advisoryDashboard.contractOverview.heading'),
      onClick: () => {
        navigateToMicroFrontend(
          appNames.accounting,
          `haushalt/${householdId}/analyse/haushaltsbudget/vertragsuebersicht`,
          `/${appNames.advisor}${buildPath('/haushalt/:householdId/beratung/', {
            householdId: householdId as string,
          })}`,
        );
      },
      icon: 'klemmbrett',
      show: true,
    },
  ];

  const buttonAppointment = {
    id: 'ButtonAppointment',
    label: i18next.t('advisoryDashboard.buttonAppointment'),
    onClick: () =>
      navigateToMicroFrontend(
        appNames.vpd,
        buildPath('haushalt/:householdId/analyse/terminabschluss/fuenf-schritte', {
          householdId: householdId as string,
        }),
      ),
  };
  return {
    upperList,
    bottomList,
    buttonAppointment,
  };
};
