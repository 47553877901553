import { DxButton, DxCard, DxCardContent, DxCardHeader, DxSpacer } from '@dvag/design-system-react';

import i18next from 'i18next';
import { checkIcon } from 'utils/util';

import { getEnvironmentAppUrls } from '@dvag/dfs-constant-config';

export const MoreSettings = () => {
  const envAppUrls = getEnvironmentAppUrls();

  return (
    <DxCard>
      <DxCardHeader
        slot="header"
        label={i18next.t('settings.moreSettings')}
        data-testid="more-setting-header"
      />
      <DxCardContent>
        <DxButton
          icon={checkIcon('link-extern')}
          iconposition="right"
          type="text"
          onClick={() => window.open(envAppUrls.userAccount, '_blank')}
          label={i18next.t('settings.userAccount')}
          data-testid="user-account-button"
        />
        <DxSpacer size="16v" />
        <DxButton
          icon={checkIcon('link-extern')}
          iconposition="right"
          type="text"
          onClick={() => window.open(envAppUrls.settingsCompanyPresentation, '_blank')}
          label={i18next.t('settings.companyPresentation')}
          data-testid="company-presentation-button"
        />
      </DxCardContent>
    </DxCard>
  );
};
