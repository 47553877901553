import {
  ConsentDto,
  PersonInHouseholdDto,
  useGetHouseholdDashboardQuery,
} from 'graphql/person/generates';
import { PersonConsentType } from 'type/consent';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { useParams } from 'react-router-dom';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';

export const useGetHouseholdDashboard = () => {
  const { householdId } = useParams();

  return useGetHouseholdDashboardQuery<
    { consentList: ConsentDto[]; personList: PersonInHouseholdDto[] },
    ErrorRequestEntity
  >(
    getGraphQLClient(),
    { householdId: householdId as string },
    {
      enabled: !!householdId,
      select: (response) => ({
        consentList:
          response?.household.consent?.filter(
            (consent) => consent.type === PersonConsentType.Gdpr,
          ) || [],
        personList: (response?.household.basicData?.personInHouseholdList || []).sort(
          (a, b) => a.order - b.order,
        ),
      }),
    },
  );
};
