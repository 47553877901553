import APIClient from '@dvag/dfs-api-client';
import * as context from '@dvag/dfs-orchestrator-client';

import { FamilyApprovalType } from 'type/twoFactor';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config/api-urls';
import { versions } from './versions';


const config = { baseURL: `${getEnvironmentApiUrls().recap}/${versions.v2}` };
const api = new APIClient({
  config,
  options: {
    getToken: context.getToken,
    getContext: context.getContext,
    hasDefaultRequestInterceptors: true,
  },
});

export const familyApprovalApi = () => {
  const sendFamilyApproval = async (
    payload: FamilyApprovalType,
    signal?: AbortSignal,
  ) =>
    api.post<FamilyApprovalType>
    (`/family-approval/${payload.householdId}/person/${payload.customerPersonId}/family-approval`, {
      approvalDuration: payload.duration.value,
    }, { signal });


  return {
    sendFamilyApproval,
  };
};