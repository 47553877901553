import React from 'react';
import { QRCode } from 'react-qrcode-logo';

interface QRCodeComponentProps {
  url: string;
  ecLevel: 'L' | 'M' | 'Q' | 'H';
}

export const QRCodeComponent = ({ url, ecLevel }: QRCodeComponentProps) => (
  <QRCode
    value={url}
    ecLevel={ecLevel}
    quietZone={0}
    enableCORS={false}
    size={270}
    bgColor="#FFFFFF"
    fgColor="#000000"
    logoImage="/assets/images/recommendationPage/Logo.png"
    logoWidth={64}
    logoHeight={64}
    logoOpacity={1}
    removeQrCodeBehindLogo
  />
);
