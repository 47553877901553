import { useMutation } from '@tanstack/react-query';
import { createISODate } from 'utils/dateTimeUtils';
import { appointmentApi } from 'service/api/appointmentApi';
import { Appointment } from 'type/advisor';
import { useNotification } from 'utils/notification/NotificationContext';
import { CustomError } from '@dvag/dfs-api-client/error';

export const useUpdateAppointment = ({
  householdId,
  personId,
  type,
}: {
  householdId: string;
  personId: string | undefined;
  type: string;
}) => {
  const { showError } = useNotification();
  const { patchAppointment } = appointmentApi();
  return useMutation<Appointment, CustomError, Appointment, unknown>({
    mutationFn: async ({ id, date, time }: Appointment) => {
      const startDateTime = date && time ? createISODate({ date, time }) : null;
      const response = await patchAppointment({
        id,
        householdId,
        personId,
        startDateTime,
        type,
      });
      return response.data;
    },
    onError: (error: CustomError) => {
      showError({ status: error?.status });
    },
  });
};
