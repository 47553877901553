import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { getContext } from '@dvag/dfs-orchestrator-client';
import { navigatePrevious, navigate } from '@dvag/dfs-orchestrator-client/messengers';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import {
  DxButton,
  DxCard,
  DxCardContent,
  DxContainer,
  DxGrid,
  DxProcessHeader,
  DxText,
  DxTextArea,
} from '@dvag/design-system-react';
import * as yup from 'yup';
import { buildPath } from 'service/buildPath';
import { route } from 'config/route';
import { revealCardsObject, wishesAndReferralsCards } from 'service/model/wishesAndReferralsCards';
import { checkIcon } from 'utils/util';
import { Feedback } from 'type/advisor';
import { useAppointment } from 'hooks/useAppointment';
import { appointmentType, interviewStepNames } from 'service/model/interviewConclusion';
import { useFeedback } from 'hooks/useFeedback';
import { useUpdateFeedback } from 'hooks/useUpdateFeedback';
import { useCreateFeedback } from 'hooks/useCreateFeedback';
import { useContinuation } from 'utils/useContinuation';
import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';
import { OGCButtons } from '../components/OGCButtons/OGCButtons';
import './style.css';
import { WishesAndReferralsCard } from './WishesAndReferralsCard/WishesAndReferralsCard';

export const WishesAndReferrals = () => {
  const { householdId = '' } = useParams();

  const { data: appointmentData } = useAppointment({
    householdId,
    type: appointmentType.financial,
  });

  const appointmentId = useMemo(() => appointmentData?.id, [appointmentData]);

  const handleForwardClick = () => {
    const path = `/${appNames.advisor}${buildPath(route.recruiting, {
      householdId,
    })}`;
    navigate(path);
  };

  const { data: feedbackData, isFetching: isFeedbackFetching } = useFeedback({
    householdId,
    appointmentId,
  });

  const { mutateAsync: updateFeedback } = useUpdateFeedback({
    householdId: householdId || '',
  });

  const { mutateAsync: createFeedback } = useCreateFeedback({
    householdId: householdId || '',
    appointmentId: appointmentId || '',
  });

  const { continuation, getContinuationHandler, getCanContinueHandler, onContinuationRequest } =
    useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        futureWishes: yup.string().nullable(),
        recommendedInterestedParties: yup.string().nullable(),
      }),
    [],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { flushChanges, setValue, getValues } = useAutosave<Feedback>({
    data: feedbackData,
    onIsDirtyChange: (isDirtyParam) => {
      if (!isDirtyParam) {
        getCanContinueHandler();
      }
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: setIsSubmitting,
    createResource: createFeedback,
    updateResource: updateFeedback,
    resourceSchema,
  });

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  const [revealCards, setRevealCards] = useState(revealCardsObject);

  const setRevealed = (id: keyof typeof revealCards) => {
    setRevealCards((prevState) => ({ ...prevState, [id]: true }));
  };

  const isDisabled = (id: keyof typeof revealCards) => {
    if (id === 'left-explanation-card') return false;
    if (id === 'middle-explanation-card') return !revealCards['left-explanation-card'];
    return !revealCards['middle-explanation-card'];
  };

  const isLastCardRevealed = () => revealCards['right-explanation-card'];

  const context = getContext();
  const hasHomepageUrl = context?.user?.advisor?.urlList?.homepage;
  const hasRecommendationPermissions = context?.user?.permissions.includes(
    'permission.vpd.app-web-vp-digital.empfehlungsnahme',
  );
  const navigateToRecommendationPresentation = () => {
    const path = `/${appNames.advisor}${buildPath(route.recommendationPresentation, {
      householdId,
    })}`;
    navigate(path);
  };

  const navigateToRecommendationDirect = () => {
    const path = `/${appNames.advisor}${buildPath(route.recommendationPage, {
      householdId,
    })}`;
    const nextPath = `/${appNames.advisor}${buildPath(route.householdDashboardId, {
      householdId,
    })}`;
    navigate(path, nextPath);
  };

  return (
    <>
      <DxContainer color="transparent">
        <DxProcessHeader
          headline={i18next.t('OGC.wishesAndReferrals.title')}
          sublabel={i18next.t('OGC.wishesAndReferrals.subtitle')}
        />
        <DxContainer color="transparent" type="page">
          <DxCard className="content-card">
            <DxCardContent>
              <DxText type="h5">
                {i18next.t('OGC.wishesAndReferrals.cardContent.yourExpectations')}
              </DxText>
              <DxTextArea
                disabled={isFeedbackFetching}
                onValueChange={(e) => setValue(interviewStepNames.futureWishes, e.detail)}
                value={getValues(interviewStepNames.futureWishes)}
                id="future-wishes"
                data-testid="future-wishes"
                lines={3}
                className="wishes-and-referrals-text-area"
              />
              <DxText type="h5" className="wishes-and-referrals-label">
                {i18next.t('OGC.wishesAndReferrals.cardContent.myExpectations')}
              </DxText>
              <DxGrid
                className="cards-container"
                mq1="12/12/12"
                mq2="12/12/12"
                mq3="4-4-4"
                mq4="4-4-4"
                mq5="4-4-4"
              >
                {wishesAndReferralsCards.map((card) => (
                  <WishesAndReferralsCard
                    key={card.id}
                    card={card}
                    isRevealed={
                      revealCards[card.id] || !!feedbackData?.recommendedInterestedParties
                    }
                    setRevealed={() => setRevealed(card.id)}
                    disabled={isDisabled(card.id)}
                  />
                ))}
              </DxGrid>
              <DxContainer style={{ position: 'relative' }} color="transparent">
                {!!feedbackData?.recommendedInterestedParties ||
                  (!isLastCardRevealed() && (
                    <DxContainer className="tell-about-placeholder" color="white" />
                  ))}
                <DxText type="h5">
                  {i18next.t('OGC.wishesAndReferrals.cardContent.tellAboutConsultation')}
                </DxText>
                <DxTextArea
                  disabled={isFeedbackFetching}
                  onValueChange={(e) =>
                    setValue(interviewStepNames.recommendedInterestedParties, e.detail)
                  }
                  value={getValues(interviewStepNames.recommendedInterestedParties)}
                  id="recommended-interested-parties"
                  data-testid="recommended-interested-parties"
                  lines={3}
                  className="wishes-and-referrals-text-area"
                />
              </DxContainer>

              {hasRecommendationPermissions && (
                <>
                  <DxText className="wishes-and-referrals-label" type="h5">
                    {i18next.t('OGC.wishesAndReferrals.cardContent.customersRecruitCustomers')}
                  </DxText>

                  <DxContainer className="wishes-and-referrals-buttons" color="transparent">
                    <DxContainer color="transparent">
                      <DxButton
                        id="recommendation-presentation-button"
                        data-testid="recommendation-presentation-button"
                        iconposition="right"
                        type="secondary-m"
                        label={i18next.t('OGC.wishesAndReferrals.cardContent.presentation')}
                        icon={checkIcon('information')}
                        stretch
                        onClick={getContinuationHandler(navigateToRecommendationPresentation)}
                      />
                    </DxContainer>
                    <DxContainer color="transparent">
                      {hasHomepageUrl && (
                        <DxButton
                          id="recommendation-direct-button"
                          data-testid="recommendation-direct-button"
                          iconposition="right"
                          type="secondary-m"
                          label={i18next.t('OGC.wishesAndReferrals.cardContent.directAccess')}
                          icon={checkIcon('pfeil-rechts')}
                          stretch
                          onClick={getContinuationHandler(navigateToRecommendationDirect)}
                        />
                      )}
                    </DxContainer>
                  </DxContainer>
                </>
              )}
            </DxCardContent>
          </DxCard>
          <OGCButtons
            id="wishes-and-referrals"
            data-testid="wishes-and-referrals"
            handleForwardClick={getContinuationHandler(handleForwardClick)}
            handleBackClick={getContinuationHandler(navigatePrevious)}
          />
        </DxContainer>
      </DxContainer>
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </>
  );
};
