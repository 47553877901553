import React from 'react';

import './style.css';

interface NoResultProps {
  id: string | undefined;
  message: string;
}

export const NoResult = ({ message, id }: NoResultProps) => (
  <div id={id} data-testid="no-result-container">
    <div id="emptyBlock">
      <small>{message}</small>
    </div>
  </div>
);
