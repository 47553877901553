import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum AcademicTitle {
  Bakk = 'BAKK',
  Betriebswirt = 'BETRIEBSWIRT',
  Di = 'DI',
  DiplomJur = 'DIPLOM_JUR',
  DiplomKauffrau = 'DIPLOM_KAUFFRAU',
  DiplomKaufmann = 'DIPLOM_KAUFMANN',
  DiplIng = 'DIPL_ING',
  Dr = 'DR',
  DrDr = 'DR_DR',
  DrHc = 'DR_HC',
  DrIng = 'DR_ING',
  DrJur = 'DR_JUR',
  DrMed = 'DR_MED',
  Ingenieur = 'INGENIEUR',
  IngenieurMag = 'INGENIEUR_MAG',
  Mag = 'MAG',
  Prof = 'PROF',
  ProfDr = 'PROF_DR',
  Unbekannt = 'UNBEKANNT'
}

export type BankAccountDto = {
  __typename?: 'BankAccountDto';
  bic?: Maybe<Scalars['String']['output']>;
  financialInstitution?: Maybe<Scalars['String']['output']>;
  iban: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  isReadOnly: Scalars['Boolean']['output'];
  isUsedForCalculation: Scalars['Boolean']['output'];
};

export type BankAccountMutationInputDto = {
  personId: Scalars['String']['input'];
  /** Required for: CREATE, UPDATE, DELETE */
  transactionList: Array<BankAccountMutationTransactionInputDto>;
};

export type BankAccountMutationOutputDto = {
  __typename?: 'BankAccountMutationOutputDto';
  personId: Scalars['String']['output'];
  transactionList: Array<BankAccountMutationTransactionOutputUnion>;
};

export type BankAccountMutationTransactionDataInputDto = {
  /** Required for: CREATE & UPDATE */
  iban?: InputMaybe<Scalars['String']['input']>;
  /** Required for: UPDATE & DELETE */
  id?: InputMaybe<Scalars['Float']['input']>;
  isUsedForCalculation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BankAccountMutationTransactionInputDto = {
  data: BankAccountMutationTransactionDataInputDto;
  operation: Operation;
};

export type BankAccountMutationTransactionOutputUnion = DeleteMutationTransactionOutputDto | UpsertBankAccountMutationTransactionOutputDto;

export type BaseHouseholdDto = {
  __typename?: 'BaseHouseholdDto';
  address?: Maybe<BasicAddressDto>;
  personInHouseholdList?: Maybe<Array<PersonInHouseholdDto>>;
};

export type BaseSaverProfileDto = {
  __typename?: 'BaseSaverProfileDto';
  hasAsset: Scalars['Boolean']['output'];
  hasConsumerLoan: Scalars['Boolean']['output'];
  hasConsumption: Scalars['Boolean']['output'];
  hasDebt: Scalars['Boolean']['output'];
  hasDiscipline: Scalars['Boolean']['output'];
  hasDuePaymentNotice: Scalars['Boolean']['output'];
  hasOverdraft: Scalars['Boolean']['output'];
  hasPlusMinusZero: Scalars['Boolean']['output'];
  hasRevenueInterest: Scalars['Boolean']['output'];
  hasSavingBook: Scalars['Boolean']['output'];
  hasStrategy: Scalars['Boolean']['output'];
  hasTwoAccountModel: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  saverProfileCurrent?: Maybe<Scalars['Int']['output']>;
  saverProfileDesired?: Maybe<Scalars['Int']['output']>;
};

export type BasicAddressDto = {
  __typename?: 'BasicAddressDto';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryCode>;
  number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<State>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CommunicationChannelDto = {
  __typename?: 'CommunicationChannelDto';
  context: Context;
  id: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  type: CommunicationChannelType;
  value: Scalars['String']['output'];
};

export enum CommunicationChannelType {
  EMailGeschaeftlich = 'E_MAIL_GESCHAEFTLICH',
  EMailPrivat = 'E_MAIL_PRIVAT',
  FaxGeschaeftlich = 'FAX_GESCHAEFTLICH',
  FaxPrivat = 'FAX_PRIVAT',
  MobilGeschaeftlich = 'MOBIL_GESCHAEFTLICH',
  MobilPrivat = 'MOBIL_PRIVAT',
  TelefonGeschaeftlich = 'TELEFON_GESCHAEFTLICH',
  TelefonPrivat = 'TELEFON_PRIVAT'
}

export type ConsentDto = {
  __typename?: 'ConsentDto';
  advertisementEmail: Scalars['Boolean']['output'];
  advertisementPhone: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  personId: Scalars['String']['output'];
  status: ConsentStatus;
  type: ConsentType;
};

export enum ConsentStatus {
  AccountConnected = 'ACCOUNT_CONNECTED',
  ConsentGranted = 'CONSENT_GRANTED',
  EinwilligungErteilt = 'EINWILLIGUNG_ERTEILT',
  EmailSent = 'EMAIL_SENT',
  Erstellt = 'ERSTELLT',
  NichtVorhanden = 'NICHT_VORHANDEN',
  Versendet = 'VERSENDET'
}

export enum ConsentType {
  Eewe = 'EEWE',
  FinleapConnect = 'FINLEAP_CONNECT'
}

export enum Context {
  Haushalt = 'HAUSHALT',
  Person = 'PERSON'
}

export enum CountryCode {
  A = 'A',
  Adn = 'ADN',
  Afg = 'AFG',
  Ag = 'AG',
  Al = 'AL',
  And = 'AND',
  Ang = 'ANG',
  Anl = 'ANL',
  Ant = 'ANT',
  Ark = 'ARK',
  Arm = 'ARM',
  Aru = 'ARU',
  As = 'AS',
  Aus = 'AUS',
  Ax = 'AX',
  Az = 'AZ',
  B = 'B',
  Bd = 'BD',
  Bds = 'BDS',
  Ber = 'BER',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bhu = 'BHU',
  Bih = 'BIH',
  Bol = 'BOL',
  Br = 'BR',
  Brn = 'BRN',
  Bru = 'BRU',
  Bs = 'BS',
  Bur = 'BUR',
  By = 'BY',
  C = 'C',
  Cdn = 'CDN',
  Cgo = 'CGO',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Co = 'CO',
  Cr = 'CR',
  Cy = 'CY',
  Cz = 'CZ',
  D = 'D',
  De = 'DE',
  Dk = 'DK',
  Dom = 'DOM',
  Dsi = 'DSI',
  Dy = 'DY',
  Dz = 'DZ',
  E = 'E',
  Eak = 'EAK',
  Eas = 'EAS',
  Eat = 'EAT',
  Eau = 'EAU',
  Eaz = 'EAZ',
  Ec = 'EC',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Est = 'EST',
  Et = 'ET',
  Eth = 'ETH',
  F = 'F',
  Fal = 'FAL',
  Fg = 'FG',
  Fin = 'FIN',
  Fji = 'FJI',
  Fl = 'FL',
  Fp = 'FP',
  Fr = 'FR',
  Fsm = 'FSM',
  Gab = 'GAB',
  Gb = 'GB',
  Gba = 'GBA',
  Gbg = 'GBG',
  Gbi = 'GBI',
  Gbj = 'GBJ',
  Gbm = 'GBM',
  Gbz = 'GBZ',
  Gca = 'GCA',
  Ge = 'GE',
  Gh = 'GH',
  Gr = 'GR',
  Gua = 'GUA',
  Gui = 'GUI',
  Gum = 'GUM',
  Guy = 'GUY',
  H = 'H',
  Hk = 'HK',
  Hn = 'HN',
  Hr = 'HR',
  I = 'I',
  Il = 'IL',
  Ind = 'IND',
  Ir = 'IR',
  Irl = 'IRL',
  Irq = 'IRQ',
  Is = 'IS',
  J = 'J',
  Ja = 'JA',
  Jor = 'JOR',
  K = 'K',
  Kai = 'KAI',
  Kan = 'KAN',
  Kav = 'KAV',
  Kir = 'KIR',
  Kn = 'KN',
  Kom = 'KOM',
  Kos = 'KOS',
  Kr = 'KR',
  Ks = 'KS',
  Ksa = 'KSA',
  Kwt = 'KWT',
  Kz = 'KZ',
  L = 'L',
  Lao = 'LAO',
  Lar = 'LAR',
  Lb = 'LB',
  Ls = 'LS',
  Lt = 'LT',
  Lv = 'LV',
  M = 'M',
  Ma = 'MA',
  Mac = 'MAC',
  Mal = 'MAL',
  Mat = 'MAT',
  May = 'MAY',
  Mc = 'MC',
  Md = 'MD',
  Mex = 'MEX',
  Mh = 'MH',
  Mk = 'MK',
  Mld = 'MLD',
  Mne = 'MNE',
  Moc = 'MOC',
  Mon = 'MON',
  Mot = 'MOT',
  Ms = 'MS',
  Mw = 'MW',
  Mya = 'MYA',
  N = 'N',
  Na = 'NA',
  Nam = 'NAM',
  Nau = 'NAU',
  Nep = 'NEP',
  Nic = 'NIC',
  Nka = 'NKA',
  Nl = 'NL',
  Nma = 'NMA',
  Nok = 'NOK',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  P = 'P',
  Pa = 'PA',
  Pac = 'PAC',
  Pe = 'PE',
  Pie = 'PIE',
  Pk = 'PK',
  Pl = 'PL',
  Png = 'PNG',
  Prc = 'PRC',
  Pri = 'PRI',
  Py = 'PY',
  Q = 'Q',
  Ra = 'RA',
  Rb = 'RB',
  Rca = 'RCA',
  Rcb = 'RCB',
  Rch = 'RCH',
  Reu = 'REU',
  Rfc = 'RFC',
  Rh = 'RH',
  Ri = 'RI',
  Rim = 'RIM',
  Rl = 'RL',
  Rm = 'RM',
  Rmm = 'RMM',
  Rn = 'RN',
  Ro = 'RO',
  Rok = 'ROK',
  Rou = 'ROU',
  Rp = 'RP',
  Rsm = 'RSM',
  Rt = 'RT',
  Ru = 'RU',
  Rus = 'RUS',
  Rwa = 'RWA',
  S = 'S',
  Sd = 'SD',
  Sgp = 'SGP',
  Sik = 'SIK',
  Sk = 'SK',
  Slo = 'SLO',
  Sme = 'SME',
  Sn = 'SN',
  Sol = 'SOL',
  Sp = 'SP',
  Srb = 'SRB',
  Ssd = 'SSD',
  Stp = 'STP',
  Sy = 'SY',
  Syr = 'SYR',
  Tch = 'TCH',
  Tha = 'THA',
  Tj = 'TJ',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tok = 'TOK',
  Tr = 'TR',
  Tt = 'TT',
  Tuc = 'TUC',
  Tuv = 'TUV',
  Tw = 'TW',
  Ua = 'UA',
  Uae = 'UAE',
  Usa = 'USA',
  Uz = 'UZ',
  V = 'V',
  Vn = 'VN',
  Vu = 'VU',
  Wag = 'WAG',
  Wal = 'WAL',
  Wan = 'WAN',
  Wd = 'WD',
  Wg = 'WG',
  Wl = 'WL',
  Ws = 'WS',
  Wv = 'WV',
  Yv = 'YV',
  Z = 'Z',
  Za = 'ZA',
  Zw = 'ZW'
}

export type CustomError = {
  __typename?: 'CustomError';
  code: Scalars['Float']['output'];
  message: Scalars['String']['output'];
};

export type DeleteMutationTransactionOutputDto = {
  __typename?: 'DeleteMutationTransactionOutputDto';
  errorList?: Maybe<Array<CustomError>>;
  /** DELETE */
  operation: Operation;
};

export type EmployerDto = {
  __typename?: 'EmployerDto';
  address?: Maybe<BasicAddressDto>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum FamilyRelationship {
  Erwachsen = 'ERWACHSEN',
  Kind = 'KIND'
}

export type FullAddressDto = {
  __typename?: 'FullAddressDto';
  city?: Maybe<Scalars['String']['output']>;
  context?: Maybe<Context>;
  country?: Maybe<CountryCode>;
  householdId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPostalAddress?: Maybe<Scalars['Boolean']['output']>;
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<State>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type HouseholdDto = {
  __typename?: 'HouseholdDto';
  address?: Maybe<BasicAddressDto>;
  id: Scalars['String']['output'];
  personInHouseholdList?: Maybe<Array<PersonInHouseholdDto>>;
};

export type HouseholdSchema = {
  __typename?: 'HouseholdSchema';
  basicData: BaseHouseholdDto;
  consent: Array<ConsentDto>;
  id: Scalars['String']['output'];
  saverProfile?: Maybe<BaseSaverProfileDto>;
};

export enum InvestmentTerm {
  LongTerm = 'LONG_TERM',
  MidTerm = 'MID_TERM',
  ShortTerm = 'SHORT_TERM'
}

export enum MaritalState {
  EheaehnlicheGemeinschaft = 'EHEAEHNLICHE_GEMEINSCHAFT',
  Geschieden = 'GESCHIEDEN',
  GetrenntLebend = 'GETRENNT_LEBEND',
  LebenspartnerschaftAufgehoben = 'LEBENSPARTNERSCHAFT_AUFGEHOBEN',
  LebenspartnerVerstorben = 'LEBENSPARTNER_VERSTORBEN',
  Ledig = 'LEDIG',
  Verheiratet = 'VERHEIRATET',
  Verpartnert = 'VERPARTNERT',
  Verwitwet = 'VERWITWET'
}

export type Mutation = {
  __typename?: 'Mutation';
  bankAccount: BankAccountMutationOutputDto;
  wishGoal: PersonWishGoalMutationOutputDto;
};


export type MutationBankAccountArgs = {
  bankAccountInput: BankAccountMutationInputDto;
};


export type MutationWishGoalArgs = {
  wishGoalInput: PersonWishGoalMutationInputDto;
};

export enum OccupationType {
  Angestellte = 'ANGESTELLTE',
  AngestellteImD = 'ANGESTELLTE_IM_D',
  Arbeiter = 'ARBEITER',
  ArbeiterImD = 'ARBEITER_IM_D',
  Arbeitslose = 'ARBEITSLOSE',
  Auszubildende = 'AUSZUBILDENDE',
  Beamte = 'BEAMTE',
  BeamteAufProbe = 'BEAMTE_AUF_PROBE',
  BeamteAufWiderruf = 'BEAMTE_AUF_WIDERRUF',
  DienstunfaehigeBeamte = 'DIENSTUNFAEHIGE_BEAMTE',
  Erwerbsminderungsrentner = 'ERWERBSMINDERUNGSRENTNER',
  GeringfuegigBeschaeftigte = 'GERINGFUEGIG_BESCHAEFTIGTE',
  HausfrauenMaenner = 'HAUSFRAUEN_MAENNER',
  Kind = 'KIND',
  Kindererziehende = 'KINDERERZIEHENDE',
  Landwirte = 'LANDWIRTE',
  Pensionaere = 'PENSIONAERE',
  Rentner = 'RENTNER',
  Schueler = 'SCHUELER',
  SelbstaendigeFreiberuflich = 'SELBSTAENDIGE_FREIBERUFLICH',
  SelbstaendigeGewerblich = 'SELBSTAENDIGE_GEWERBLICH',
  SelbstaendigeHandwerklich = 'SELBSTAENDIGE_HANDWERKLICH',
  Soldaten = 'SOLDATEN',
  Studenten = 'STUDENTEN',
  WehrUndZivildienstleistende = 'WEHR_UND_ZIVILDIENSTLEISTENDE'
}

export enum Operation {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type PersonDto = {
  __typename?: 'PersonDto';
  academicTitle?: Maybe<AcademicTitle>;
  allFirstNames?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthName?: Maybe<Scalars['String']['output']>;
  countryOfBirth?: Maybe<CountryCode>;
  familyRelationship?: Maybe<FamilyRelationship>;
  firstName: Scalars['String']['output'];
  householdList?: Maybe<Array<HouseholdDto>>;
  id: Scalars['String']['output'];
  isSmoker?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  maritalState?: Maybe<MaritalState>;
  nationality?: Maybe<CountryCode>;
  noOfChildren?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  salutation: Salutation;
  status?: Maybe<PersonStatus>;
};

export type PersonInHouseholdDto = {
  __typename?: 'PersonInHouseholdDto';
  birthDate?: Maybe<Scalars['String']['output']>;
  familyRelationship?: Maybe<FamilyRelationship>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  maritalState?: Maybe<MaritalState>;
  order: Scalars['Int']['output'];
  salutation?: Maybe<Salutation>;
};

export type PersonSchema = {
  __typename?: 'PersonSchema';
  address: Array<FullAddressDto>;
  bankAccount: Array<BankAccountDto>;
  basicData: PersonDto;
  communicationChannel: Array<CommunicationChannelDto>;
  consent: Array<ConsentDto>;
  household: Array<HouseholdDto>;
  id: Scalars['String']['output'];
  profession?: Maybe<ProfessionDto>;
  tax?: Maybe<TaxDto>;
  wishGoal: Array<PersonWishGoalDto>;
};


export type PersonSchemaAddressArgs = {
  householdId?: InputMaybe<Scalars['String']['input']>;
};


export type PersonSchemaBasicDataArgs = {
  householdId?: InputMaybe<Scalars['String']['input']>;
};

export enum PersonStatus {
  Altkunde = 'ALTKUNDE',
  Interessent = 'INTERESSENT',
  Kunde = 'KUNDE'
}

export type PersonWishGoalDto = {
  __typename?: 'PersonWishGoalDto';
  comment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  investmentTerm?: Maybe<InvestmentTerm>;
  isDesired?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  personId?: Maybe<Scalars['String']['output']>;
  wishGoalId: Scalars['String']['output'];
};

export type PersonWishGoalMutationInputDto = {
  personId: Scalars['String']['input'];
  /** Required for: CREATE, UPDATE */
  transactionList: Array<PersonWishGoalMutationTransactionInputDto>;
};

export type PersonWishGoalMutationOutputDto = {
  __typename?: 'PersonWishGoalMutationOutputDto';
  personId: Scalars['String']['output'];
  transactionList: Array<UpsertPersonWishGoalMutationTransactionOutputDto>;
};

export type PersonWishGoalMutationTransactionDataInputDto = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Required for UPDATE */
  id?: InputMaybe<Scalars['String']['input']>;
  investmentTerm?: InputMaybe<InvestmentTerm>;
  isDesired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required for: CREATE & UPDATE */
  order?: InputMaybe<Scalars['Int']['input']>;
  /** Required for: CREATE & UPDATE */
  wishGoalId?: InputMaybe<Scalars['String']['input']>;
};

export type PersonWishGoalMutationTransactionInputDto = {
  data: PersonWishGoalMutationTransactionDataInputDto;
  operation: Operation;
};

export type ProfessionDto = {
  __typename?: 'ProfessionDto';
  employer?: Maybe<EmployerDto>;
  employmentStartDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  occupationGroup?: Maybe<OccupationType>;
  professionId?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  household: HouseholdSchema;
  person: PersonSchema;
};


export type QueryHouseholdArgs = {
  householdId: Scalars['String']['input'];
};


export type QueryPersonArgs = {
  personId: Scalars['String']['input'];
};

export enum Salutation {
  Eheleute = 'EHELEUTE',
  Firma = 'FIRMA',
  Frau = 'FRAU',
  Herr = 'HERR'
}

export enum State {
  Ausland = 'AUSLAND',
  BadenWuerttemberg = 'BADEN_WUERTTEMBERG',
  Bayern = 'BAYERN',
  BerlinOst = 'BERLIN_OST',
  BerlinWest = 'BERLIN_WEST',
  Brandenburg = 'BRANDENBURG',
  Bremen = 'BREMEN',
  Hamburg = 'HAMBURG',
  Hessen = 'HESSEN',
  MecklenburgVorpommern = 'MECKLENBURG_VORPOMMERN',
  Niedersachsen = 'NIEDERSACHSEN',
  NordrheinWestfalen = 'NORDRHEIN_WESTFALEN',
  RheinlandPfalz = 'RHEINLAND_PFALZ',
  Saarland = 'SAARLAND',
  Sachsen = 'SACHSEN',
  SachsenAnhalt = 'SACHSEN_ANHALT',
  SchleswigHolstein = 'SCHLESWIG_HOLSTEIN',
  Thueringen = 'THUERINGEN'
}

export type TaxDto = {
  __typename?: 'TaxDto';
  childAllowance?: Maybe<Scalars['Float']['output']>;
  churchTax?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  incomeSocialInsurancePreviousYear?: Maybe<Scalars['Float']['output']>;
  incomeType?: Maybe<Scalars['String']['output']>;
  taxAdvisor?: Maybe<Scalars['String']['output']>;
  taxAdvisorSyncId?: Maybe<Scalars['Float']['output']>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  taxNumberSyncId?: Maybe<Scalars['Float']['output']>;
  taxReturnType?: Maybe<TaxReturnType>;
  taxableIncomePreviousYear?: Maybe<Scalars['Float']['output']>;
};

export enum TaxReturnType {
  Einzeln = 'EINZELN',
  Unbekannt = 'UNBEKANNT',
  Zusammen = 'ZUSAMMEN'
}

export type UpsertBankAccountMutationTransactionOutputDto = {
  __typename?: 'UpsertBankAccountMutationTransactionOutputDto';
  data?: Maybe<BankAccountDto>;
  errorList?: Maybe<Array<CustomError>>;
  /** CREATE or UPDATE */
  operation: Operation;
};

export type UpsertPersonWishGoalMutationTransactionOutputDto = {
  __typename?: 'UpsertPersonWishGoalMutationTransactionOutputDto';
  data?: Maybe<PersonWishGoalDto>;
  errorList?: Maybe<Array<CustomError>>;
  /** CREATE or UPDATE */
  operation: Operation;
};

export type GetConsentModalDataQueryVariables = Exact<{
  householdId?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['String']['input'];
}>;


export type GetConsentModalDataQuery = { __typename?: 'Query', person: { __typename?: 'PersonSchema', id: string, basicData: { __typename?: 'PersonDto', academicTitle?: AcademicTitle | null, allFirstNames?: string | null, birthDate?: string | null, birthName?: string | null, countryOfBirth?: CountryCode | null, familyRelationship?: FamilyRelationship | null, firstName: string, isSmoker?: boolean | null, lastName: string, maritalState?: MaritalState | null, nationality?: CountryCode | null, noOfChildren?: number | null, placeOfBirth?: string | null, salutation: Salutation, status?: PersonStatus | null }, communicationChannel: Array<{ __typename?: 'CommunicationChannelDto', id: string, type: CommunicationChannelType, value: string }> } };

export type GetHouseholdDashboardQueryVariables = Exact<{
  householdId: Scalars['String']['input'];
}>;


export type GetHouseholdDashboardQuery = { __typename?: 'Query', household: { __typename?: 'HouseholdSchema', consent: Array<{ __typename?: 'ConsentDto', personId: string, status: ConsentStatus, type: ConsentType, email?: string | null, advertisementEmail: boolean, advertisementPhone: boolean }>, basicData: { __typename?: 'BaseHouseholdDto', personInHouseholdList?: Array<{ __typename?: 'PersonInHouseholdDto', firstName: string, lastName: string, order: number, id: string, familyRelationship?: FamilyRelationship | null, birthDate?: string | null }> | null } } };

export type GetPersonIdListQueryVariables = Exact<{
  householdId: Scalars['String']['input'];
}>;


export type GetPersonIdListQuery = { __typename?: 'Query', household: { __typename?: 'HouseholdSchema', basicData: { __typename?: 'BaseHouseholdDto', personInHouseholdList?: Array<{ __typename?: 'PersonInHouseholdDto', id: string }> | null } } };



export const GetConsentModalDataDocument = `
    query getConsentModalData($householdId: String, $personId: String!) {
  person(personId: $personId) {
    id
    basicData(householdId: $householdId) {
      academicTitle
      allFirstNames
      birthDate
      birthName
      countryOfBirth
      familyRelationship
      firstName
      isSmoker
      lastName
      maritalState
      nationality
      noOfChildren
      placeOfBirth
      salutation
      status
    }
    communicationChannel {
      id
      type
      value
    }
  }
}
    `;

export const useGetConsentModalDataQuery = <
      TData = GetConsentModalDataQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetConsentModalDataQueryVariables,
      options?: Omit<UseQueryOptions<GetConsentModalDataQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetConsentModalDataQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetConsentModalDataQuery, TError, TData>(
      {
    queryKey: ['getConsentModalData', variables],
    queryFn: fetcher<GetConsentModalDataQuery, GetConsentModalDataQueryVariables>(client, GetConsentModalDataDocument, variables, headers),
    ...options
  }
    )};

export const GetHouseholdDashboardDocument = `
    query getHouseholdDashboard($householdId: String!) {
  household(householdId: $householdId) {
    consent {
      personId
      status
      type
      email
      advertisementEmail
      advertisementPhone
    }
    basicData {
      personInHouseholdList {
        firstName
        lastName
        order
        id
        familyRelationship
        birthDate
      }
    }
  }
}
    `;

export const useGetHouseholdDashboardQuery = <
      TData = GetHouseholdDashboardQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetHouseholdDashboardQueryVariables,
      options?: Omit<UseQueryOptions<GetHouseholdDashboardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetHouseholdDashboardQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetHouseholdDashboardQuery, TError, TData>(
      {
    queryKey: ['getHouseholdDashboard', variables],
    queryFn: fetcher<GetHouseholdDashboardQuery, GetHouseholdDashboardQueryVariables>(client, GetHouseholdDashboardDocument, variables, headers),
    ...options
  }
    )};

export const GetPersonIdListDocument = `
    query getPersonIdList($householdId: String!) {
  household(householdId: $householdId) {
    basicData {
      personInHouseholdList {
        id
      }
    }
  }
}
    `;

export const useGetPersonIdListQuery = <
      TData = GetPersonIdListQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetPersonIdListQueryVariables,
      options?: Omit<UseQueryOptions<GetPersonIdListQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetPersonIdListQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetPersonIdListQuery, TError, TData>(
      {
    queryKey: ['getPersonIdList', variables],
    queryFn: fetcher<GetPersonIdListQuery, GetPersonIdListQueryVariables>(client, GetPersonIdListDocument, variables, headers),
    ...options
  }
    )};
