import React, { useEffect, useMemo, useState } from 'react';
import {
  DxButton,
  DxCard,
  DxCardCollapsable,
  DxCardContent,
  DxCardHeader,
  DxContainer,
  DxText,
} from '@dvag/design-system-react';
import { navigate } from '@dvag/dfs-orchestrator-client/messengers';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import { UpdateManyResources } from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import * as yup from 'yup';

import i18next from 'i18next';
import { checkIcon } from 'utils/util';
import { buildPath } from 'service/buildPath';
import { route } from 'config/route';
import { useGetFavoritesWithAction } from 'hooks/useGetFavoritesWithAction';
import { usePermission } from 'utils/permission/usePermission';
import { useGetQuickAccessSetting } from 'hooks/useGetQuickAccessSetting';
import { useCreateOrUpdateSettings } from 'hooks/useCreateOrUpdateSettings';
import { SelectedOption, SettingDto } from 'graphql/advisor/generates';
import { useContinuation } from 'utils/useContinuation';
import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';
import featureFlag from 'service/featureFlag';
import { permissionMap } from 'utils/permission/permissionMap';
import { getSessionStore, sessionStoreKeys } from 'service/sessionStore';

import { useParams } from 'react-router-dom';
import { useFavoritesUpdatedListener } from './useFavoritesUpdatedListener';

import './style.css';

export const QuickAccess = () => {
  const { householdId } = useParams<{ householdId: string }>();
  const permission = usePermission();
  const { data: favorites } = useGetFavoritesWithAction(householdId);
  const disabledStyle = !householdId ? { opacity: '0.6', pointerEvents: 'none' } : {};
  const isSettingsFeatureOn =
    featureFlag.gb?.isOn(permissionMap.settingFlag) && permission.canAccessUserSettings;

  const onEditClick = () => {
    if (householdId) {
      navigate(`/${appNames.advisor}${buildPath(route.favorites, { householdId })}`);
    }
  };

  const { continuation, getCanContinueHandler, onContinuationRequest } = useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const quickAccessOpenSetting = useGetQuickAccessSetting(isSettingsFeatureOn);
  const createOrUpdateUserSettings = useCreateOrUpdateSettings();

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        id: yup.string().nullable(),
        selectedOptions: yup.array(),
        code: yup.string(),
      }),
    [],
  );

  const { register, setValue, getValues, flushChanges } = useAutosave<SettingDto>({
    data: quickAccessOpenSetting.data,
    updateManyResources:
      createOrUpdateUserSettings.mutateAsync as unknown as UpdateManyResources<SettingDto>,
    resourceSchema,
    onIsDirtyChange: (isDirtyParam: boolean) => {
      if (!isDirtyParam) {
        getCanContinueHandler();
      }
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: setIsSubmitting,
  });

  const isQuickAccessOpenStore = getSessionStore(sessionStoreKeys.isQuickAccessOpen);
  const isQuickAccessStored =
    isQuickAccessOpenStore.getItem() !== null ? !!isQuickAccessOpenStore.getItem() : true;

  const onOpenChange = (value: CustomEvent) => {
    if (isSettingsFeatureOn) {
      setValue('selectedOptions', value.detail ? [SelectedOption.On] : [SelectedOption.Off]);
    } else {
      isQuickAccessOpenStore.setItem(value.detail);
    }
  };

  const isQuickAccessOpen = isSettingsFeatureOn
    ? getValues('selectedOptions')?.[0] === SelectedOption.On
    : isQuickAccessStored;

  useFavoritesUpdatedListener();

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  if (!permission.canAccessFavorites) return null;

  return (
    <>
      <DxCard style={disabledStyle}>
        <DxCardCollapsable
          data-testid="quick-access-card"
          open={isQuickAccessOpen}
          onOpenChange={onOpenChange}
          {...register('selectedOptions')}
        >
          <DxCardHeader
            slot="header"
            label={i18next.t('favorites.quickAccess')}
            data-testid="quick-access-header"
          >
            <DxButton
              data-testid="quick-access-icon"
              className="quick-access-icon"
              icon={checkIcon('bearbeiten')}
              type="text"
              onClick={onEditClick}
            />
          </DxCardHeader>
          <DxCardContent>
            <DxContainer color="transparent" className="quick-access-container">
              {favorites.length > 0 ? (
                [...Array(5).keys()].map((_, index) => (
                  <DxContainer
                    key={_}
                    color="transparent"
                    className={`quick-access-button-container ${
                      favorites[index] && 'quick-access-button-container-border'
                    }`}
                  >
                    {favorites[index] && (
                      <DxContainer
                        color="transparent"
                        className="quick-access-button"
                        onClick={favorites[index]?.onClick}
                      >
                        <DxText type="its" color="gray-83" className="quick-access-button-text">
                          {favorites[index]?.name}
                        </DxText>
                      </DxContainer>
                    )}
                  </DxContainer>
                ))
              ) : (
                <DxText>{i18next.t('favorites.noItems')}</DxText>
              )}
            </DxContainer>
          </DxCardContent>
        </DxCardCollapsable>
      </DxCard>
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </>
  );
};
