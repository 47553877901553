import React, { useEffect, useMemo, useState } from 'react';
import {
  DxCard,
  DxCardContent,
  DxContainer,
  DxGrid,
  DxProcessHeader,
  DxText,
} from '@dvag/design-system-react';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import * as yup from 'yup';

import { appNames } from '@dvag/dfs-constant-config/app-names';
import { navigate, navigatePrevious } from '@dvag/dfs-orchestrator-client/messengers';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';

import {
  appointmentType,
  interviewStepNames,
  preImpression,
} from 'service/model/interviewConclusion';
import { useAppointment } from 'hooks/useAppointment';
import { useFeedback } from 'hooks/useFeedback';
import { OGCButtons } from '../components/OGCButtons/OGCButtons';
import { buildPath } from '../../../service/buildPath';
import { route } from '../../../config/route';

import './style.css';
import { useUpdateFeedback } from '../../../hooks/useUpdateFeedback';
import { useCreateFeedback } from '../../../hooks/useCreateFeedback';
import { OGCStateCard } from '../components/OGCStateCard/OGCStateCard';
import { UnsavedOrPendingModal } from '../../../component/UnsavedOrPendingModal';
import { useContinuation } from '../../../utils/useContinuation';
import { Feedback } from '../../../type/advisor';

export const EvaluateBefore = () => {
  const { householdId = '' } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: appointmentData } = useAppointment({
    householdId,
    type: appointmentType.financial,
  });

  const appointmentId = useMemo(() => appointmentData?.id, [appointmentData]);

  const { data: feedbackData, isFetching: isFeedbackFetching } = useFeedback({
    householdId,
    appointmentId,
  });

  const [preImpressionState, setPreImpressionState] = useState(feedbackData?.preImpression);

  const { mutateAsync: updateFeedback } = useUpdateFeedback({
    householdId: householdId || '',
  });

  const { mutateAsync: createFeedback } = useCreateFeedback({
    householdId: householdId || '',
    appointmentId: appointmentId || '',
  });

  const { continuation, getContinuationHandler, getCanContinueHandler, onContinuationRequest } =
    useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        preImpression: yup.string().nullable(),
      }),
    [],
  );

  const { flushChanges, setValue } = useAutosave<Feedback>({
    data: feedbackData,
    onIsDirtyChange: (isDirtyParam) => {
      if (!isDirtyParam) {
        getCanContinueHandler();
      }
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: setIsSubmitting,
    createResource: createFeedback,
    updateResource: updateFeedback,
    resourceSchema,
  });

  const onCardSelect = (value: string) => {
    setValue(interviewStepNames.preImpression, value);
    setPreImpressionState(value);
  };

  const handleForwardClick = () => {
    const path = `/${appNames.advisor}${buildPath(route.evaluateAfter, {
      householdId,
    })}`;
    navigate(path);
  };

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  useEffect(() => {
    setPreImpressionState(feedbackData?.preImpression);
  }, [feedbackData?.preImpression]);

  return (
    <>
      <DxProcessHeader
        headline={i18next.t('OGC.interviewConclusion.evaluateBefore.title')}
        sublabel={i18next.t('OGC.interviewConclusion.evaluateBefore.subtitle')}
      />
      <DxContainer color="transparent" type="page">
        <DxCard className="evaluate-before-container">
          <DxCardContent>
            <DxText type="h5">
              {i18next.t('OGC.interviewConclusion.evaluateBefore.question')}
            </DxText>
            <DxGrid
              mq1="12/12/12"
              mq2="12/12/12"
              mq3="4-4-4"
              mq4="4-4-4"
              mq5="4-4-4"
              className="evaluate-before-content"
            >
              {preImpression.map(({ id, label, icon, value }) => (
                <OGCStateCard
                  key={id}
                  id={id}
                  data-testid={`ogc-evaluate-before-state-card-${id}`}
                  label={label}
                  icon={icon}
                  onSelect={() => onCardSelect(value)}
                  isRevealed={value === preImpressionState}
                  disabled={!appointmentId || isFeedbackFetching}
                />
              ))}
            </DxGrid>
          </DxCardContent>
        </DxCard>
        <OGCButtons
          id="evaluate-before"
          data-testid="evaluate-before"
          handleForwardClick={getContinuationHandler(handleForwardClick)}
          handleBackClick={getContinuationHandler(navigatePrevious)}
        />
      </DxContainer>
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </>
  );
};
