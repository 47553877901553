import { DxContainer, DxText, DxTextList } from '@dvag/design-system-react';
import i18next from 'i18next';
import { CodeTypes, CodeType } from '../../types';

import '../../style.css';

interface PopoverContentProp {
  settingCode: CodeType;
}

const contentMap: Record<CodeType, JSX.Element> = {
  [CodeTypes.CONCEPT_WITH_STRATEGY]: (
    <>
      <DxText type="it" className="popover-content-text">
        {i18next.t(`settings.settingItem.CONCEPT_WITH_STRATEGY.info1`)}
      </DxText>
      <DxContainer className="popover-content-text">
        <DxText type="it">{i18next.t(`settings.settingItem.CONCEPT_WITH_STRATEGY.info2`)}</DxText>
      </DxContainer>
    </>
  ),
  [CodeTypes.QUICK_ACCESS_TOGGLE]: (
    <>
      <DxText type="it" className="popover-content-text">
        {i18next.t(`settings.settingItem.QUICK_ACCESS_TOGGLE.info1`)}
      </DxText>
      <DxContainer className="popover-content-text">
        <DxText type="it">{i18next.t(`settings.settingItem.QUICK_ACCESS_TOGGLE.info2`)}</DxText>
      </DxContainer>
      <DxText type="it" className="popover-content-text">
        {i18next.t(`settings.settingItem.QUICK_ACCESS_TOGGLE.info3`)}
      </DxText>
    </>
  ),
  [CodeTypes.WEB_VP_SLIDE]: (
    <>
      <DxText type="it" className="popover-content-text">
        {i18next.t(`settings.settingItem.WEB_VP_SLIDE.info1`)}
      </DxText>
      <DxContainer className="popover-content-text">
        <DxText type="it">{i18next.t(`settings.settingItem.WEB_VP_SLIDE.info2`)}</DxText>
        <DxTextList>
          <DxText type="it">{i18next.t(`settings.settingItem.WEB_VP_SLIDE.infoOption1`)}</DxText>
          <DxText type="it">{i18next.t(`settings.settingItem.WEB_VP_SLIDE.infoOption2`)}</DxText>
        </DxTextList>
      </DxContainer>
      <DxText type="it" className="popover-content-text">
        {i18next.t(`settings.settingItem.WEB_VP_SLIDE.info3`)}
      </DxText>
    </>
  ),
};

export const PopoverContent = ({ settingCode }: PopoverContentProp) => (
  <DxContainer
    color="transparent"
    className="popover-content"
    data-testid="setting-info-popover-content"
  >
    {contentMap[settingCode]}
  </DxContainer>
);
