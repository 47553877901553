import { useQuery } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { Address } from 'type/address';

export const useHouseholdList = (householdId?: string) => {
  const personService = personApi();
  return useQuery({
    queryKey: ['last-accessed'],
    queryFn: async () => {
      const response = await personService.getLastAccessed();
      return response?.data?.map((household) => ({
        address: (household.address || {}) as Address,
        householdId: household.id,
        personList: household.personInHouseholdList?.map((person) => ({
          firstName: person.firstName,
          id: person.id,
          lastName: person.lastName,
          order: person.order,
        })),
      }));
    },
    initialData: [],
    enabled: !householdId,
  });
};
