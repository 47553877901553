import { DxContainer, DxList, DxListSection } from '@dvag/design-system-react';
import i18next from 'i18next';
import { FC } from 'react';
import { FavoritePage, Favorites } from '@dvag/dfs-ui-blocks/components';
import { EmptyFavoritesText } from '../EmptyFavoritesText';
import './style.css';

type UserFavoritesProps = {
  pages: FavoritePage[];
  onItemMove: (from: number, to: number) => void;
  onItemRemove: (index: number, page: FavoritePage) => void;
  canMoveItems: boolean;
};

export const UserFavorites: FC<UserFavoritesProps> = ({ pages, canMoveItems, ...props }) => (
  <DxContainer
    className="favorites-current-list"
    color="transparent"
    data-testid="favorites-current-list"
  >
    <DxListSection label={i18next.t('favorites.favoritesCurrent')} />
    <DxList size="l" className="list-container" lang="de">
      {pages.length ? (
        <Favorites
          {...props}
          rootElementId="root"
          data={pages}
          editMode
          canMoveItems={canMoveItems}
        />
      ) : (
        <EmptyFavoritesText />
      )}
    </DxList>
  </DxContainer>
);
