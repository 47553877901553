import React from 'react';
import { DxCard, DxContainer, DxIcon, DxText } from '@dvag/design-system-react';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';
import './style.css';

type RecruitingCardProps = { icon: IconName; text: string };

export const RecruitingCard = ({ icon, text }: RecruitingCardProps) => (
  <DxCard id={icon} className="recruit-card" key={text}>
    <DxContainer className="icon-container">
      <DxIcon size={72} icon={icon} />
    </DxContainer>
    <DxText className="card-text">{text}</DxText>
  </DxCard>
);
