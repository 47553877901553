import { useQuery } from '@tanstack/react-query';

import { advisorApi } from 'service/api/advisorApi';

export const useGetAdvisor = () => {
  const advisorService = advisorApi();

  return useQuery({
    queryKey: [`get-advisor-info`],
    queryFn: async () => advisorService.getAdvisorInformation(),
    select: (response) => response?.data,
  });
};
