import APIClient from '@dvag/dfs-api-client';
import * as context from '@dvag/dfs-orchestrator-client';

import {
  FamilyApprovalTwoFAType,
  KundanzuganHaushaltDetailResponse,
  KundanzuganHaushaltDetailType,
  KundanzuganTransactionStatusResponse,
  KundanzuganTransactionStatusType,
} from 'type/twoFactor';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config';

const config = { baseURL: getEnvironmentApiUrls().registration };
const api = new APIClient({
  config,
  options: {
    getToken: context.getToken,
    getContext: context.getContext,
    hasDefaultRequestInterceptors: true,
  },
});

export const familyApprovalTwoFaApi = () => {
  const sendFamilyApprovalTwoFA = async (
    payload: FamilyApprovalTwoFAType,
    signal?: AbortSignal,
  ) => {
    const { keycloakId, ...restParam } = payload;
    return api.post<string, FamilyApprovalTwoFAType>(
      `/rest/v1/dvag-pass/users/${keycloakId}/confirmation/familienfreigabe`,
      restParam,
      { signal },
    );
  };
  const kundanzuganTransactionStatusApi = async (
    payload: KundanzuganTransactionStatusType,
    signal?: AbortSignal,
  ) =>
    api.get<KundanzuganTransactionStatusResponse>(
      `/rest/v1/dvag-pass/transactions/${payload.transactionId}/status`,
      { signal },
    );

  const kundanzuganHaushaltDetailApi = async (
    payload: KundanzuganHaushaltDetailType,
    signal?: AbortSignal,
  ) =>
    api.get<KundanzuganHaushaltDetailResponse[]>(
      `/rest/v1/webcomponent/vb/${payload.advisorId}/kunden/haushalt/${payload.householdId}/status/detail`,
      { signal },
    );

  return {
    sendFamilyApprovalTwoFA,
    kundanzuganHaushaltDetailApi,
    kundanzuganTransactionStatusApi,
  };
};
