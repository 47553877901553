import React from 'react';

import { DxText } from '@dvag/design-system-react';

import '../style.css';

interface ModalHeaderProps {
  name: string;
  subtitle: string;
}

export const ModalHeader = ({ name, subtitle }: ModalHeaderProps) => (
  <div className="modal-header-container flex column align-center">
    <div className="modal-title-container">
      <DxText type="paragraph-big-bold" color="gray-50">
        {name}
      </DxText>
    </div>
    <div className="modal-subtitle-container">
      <DxText type="it" color="gray-50">
        {subtitle}
      </DxText>
    </div>
  </div>
);
