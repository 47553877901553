import i18next from 'i18next';
import { isPersonYoungerThan18 } from 'screen/HouseholdDashboard/component/BottomCard/util';

export const formatDate = (value: Date) => value.toISOString().split('T')[0];

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const changeDateFormat = (date: string) =>
  new Date(date).toLocaleDateString(navigator.language, dateFormatOptions);

const currentDate = new Date();
export const formattedCurrentData = formatDate(currentDate);

export const earlierDataError = i18next.t('general.earlierDateError', {
  date: changeDateFormat(formattedCurrentData),
});

const yearLimit = 100;

export const MINDATE = formatDate(new Date('1920-01-01'));
export const MAXDATE = formatDate(
  new Date(currentDate.setFullYear(currentDate.getFullYear() + yearLimit)),
);

export const oldDataError = i18next.t('general.oldDateError', { date: changeDateFormat(MINDATE) });
export const longFutureDateError = i18next.t('general.longFutureDateError');
export const youngerThen18Error = i18next.t('general.youngerThen18Error');

export const futureDateOnly = (value: string | undefined | null): boolean => {
  if (!value) return true;
  const selectedDate = formatDate(new Date(value));
  const isDateAfterMaxDate = selectedDate < formattedCurrentData;
  return !isDateAfterMaxDate;
};

export const validateConsentDate = (
  value: string | undefined | null,
  maxDate: string = MAXDATE,
): string | boolean => {
  if (!value) return true;
  const selectedDate = formatDate(new Date(value));
  const isDateBeforeMinDate = selectedDate < MINDATE;
  const isDateAfterMaxDate = selectedDate > maxDate;
  const isYoungerThen18 = isPersonYoungerThan18(value);
  if (isDateBeforeMinDate) return oldDataError;
  if (isDateAfterMaxDate) return longFutureDateError;
  if (isYoungerThen18) return youngerThen18Error;
  return true;
};
