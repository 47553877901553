import React from 'react';
import { DxCard, DxCardContent, DxContainer, DxIcon, DxText } from '@dvag/design-system-react';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';
import { checkIcon } from 'utils/util';

import './style.css';

export type OGCStateCardProps = {
  id: string;
  icon: IconName;
  label: string;
  isRevealed: boolean;
  disabled: boolean;
  onSelect: () => void;
  'data-testid'?: string;
};

export const OGCStateCard = ({
  id,
  icon,
  label,
  isRevealed,
  onSelect,
  disabled,
  ...props
}: OGCStateCardProps) => (
  <DxCard
    id={id}
    className={`${isRevealed ? 'ogc-state-card-selected' : 'ogc-state-card'} ${disabled && 'ogc-state-card-disabled'}`}
    onClick={onSelect}
    data-testid={props['data-testid']}
  >
    <DxCardContent className="ogc-state-card-content justify-center">
      <DxContainer
        color="transparent"
        className="flex column align-center justify-center ogc-state-card-content-container"
      >
        <DxIcon
          icon={checkIcon(icon)}
          size={72}
          color={isRevealed ? 'white' : 'default'}
          className="ogc-state-icon"
        />
        <DxText color={isRevealed ? 'white' : 'default'}>{label}</DxText>
      </DxContainer>
    </DxCardContent>
  </DxCard>
);
