import React from 'react';
import { useParams } from 'react-router-dom';

import { DxContainer, DxGrid } from '@dvag/design-system-react';

import { HouseholdWidgetPlaceholder } from 'component/HouseholdWidget/HouseholdWidgetPlaceholder';
import { HouseholdWidget } from 'component/HouseholdWidget/HouseholdWidget';
import { useGetHouseholdDashboard } from 'hooks/useGetHouseholdDashboard';
import { BottomCard } from './component/BottomCard/BottomCard';
import { DashboardHeader } from './component/DashboardHeader/DashboardHeader';
import { UpperList } from './component/UpperList/UpperList';
import { CooperationCard } from './component/CooperationCard/CooperationCard';
import { CardListContainer } from './component/CardListContainer';
import { QuickAccess } from './component/QuickAccess/QuickAccess';

import './style.css';

export const HouseholdDashboard = () => {
  const params = useParams<{
    selectedPersonId?: string;
  }>();
  const householdDashboard = useGetHouseholdDashboard();

  return (
    <>
      <DashboardHeader />
      <DxContainer
        color="transparent"
        type="page"
        className="dashboard-content-container"
        data-testid="household-dashboard-container"
      >
        <DxGrid mq1="12/12/12/12/12/12/*">
          {params?.selectedPersonId ? (
            <HouseholdWidgetPlaceholder />
          ) : (
            <HouseholdWidget
              match={{ params }}
              personList={householdDashboard.data?.personList || []}
            />
          )}
          <UpperList personId={householdDashboard?.data?.personList[0]?.id} />
          <CardListContainer>
            <BottomCard />
            <CooperationCard dashboardPath="uebersicht" />
          </CardListContainer>
          <QuickAccess />
        </DxGrid>
      </DxContainer>
    </>
  );
};
