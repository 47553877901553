import React from 'react';
import { DxButton, DxCard, DxCardImage, DxContainer, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';
import { checkIcon } from 'utils/util';
import './style.css';

type WishesAndReferralsCardProp = {
  card: {
    id: string;
    imageSrc: string;
    imageAlt: string;
    description: string;
  };
  disabled?: boolean;
  isRevealed: boolean;
  setRevealed: (id: string) => void;
};

export const WishesAndReferralsCard = ({
  card,
  disabled,
  isRevealed,
  setRevealed,
}: WishesAndReferralsCardProp) => (
  <DxCard
    className={`wishes-and-referrals-card ${!isRevealed && !disabled ? 'placeholder-border' : ''}`}
    key={card.id}
    id={card.id}
    data-testid={card.id}
  >
    {!isRevealed && (
      <DxContainer
        color="white"
        onClick={() => {
          if (!disabled) setRevealed(card.id);
        }}
        className={`placeholder-container ${disabled ? 'cursor-default' : ''}`}
      >
        <DxButton
          disabled={disabled}
          id={`${card.id}-click-here-button`}
          data-testid={`${card.id}-click-here-button`}
          label={i18next.t('OGC.wishesAndReferrals.cardContent.clickHere')}
          icon={checkIcon('auge')}
          iconposition="right"
          type="text"
        />
      </DxContainer>
    )}
    <DxCardImage
      fit="cover"
      coverheightmq1="152px"
      coverheightmq2="250px"
      coverheightmq3="135px"
      coverheightmq4="160px"
      coverheightmq5="185px"
      id={`${card.id}-image`}
      url={card.imageSrc}
    />
    <DxContainer
      id={`${card.id}-description-container`}
      color="transparent"
      className="card-text-container"
    >
      <DxText id={`${card.id}-description`} type="ps">
        {card.description}
      </DxText>
    </DxContainer>
  </DxCard>
);
