import { useQuery } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';

export const usePersonHousehold = (id: string | undefined) => {
  const personService = personApi();
  return useQuery({
    queryKey: [`person-household-list`, id],
    queryFn: async () => {
      const data = await personService.getPersonHouseholdList(id);
      return data.data;
    },
    initialData: [],
    enabled: !!id,
  });
};
