import { appNames } from '@dvag/dfs-constant-config/app-names';
import { navigate as orchestratorNavigate } from '@dvag/dfs-orchestrator-client/messengers';
import {
  DxCard,
  DxCardContent,
  DxContainer,
  DxIcon,
  DxList,
  DxNotificationToast,
  DxText,
} from '@dvag/design-system-react';
import i18next from 'i18next';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ConsentModal } from 'component/ConsentModal';
import { useGetHouseholdDashboard } from 'hooks/useGetHouseholdDashboard';
import { getConsentStatus, useUtil } from 'screen/HouseholdDashboard/component/BottomCard/util';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { dashboardUtil } from 'service/model/dashboardUtil';
import {
  checkIcon,
  getConsentStatusTranslation,
  getPersonFullName,
  hasApprovedConsent,
} from 'utils/util';

import type { PersonInHouseholdDto } from 'graphql/person/generates';
import type { AnalyseSectionListData, SectionListData } from 'type/sectionList';
import { rightCardSections } from './pages.config';
import { Item } from '../Card/Item';

interface dataInterface {
  personId: string;
  status: string;
}

const CLOSE_SECONDS_TIMEOUT = 3;

const { PRIVACY_POLICY, HHB_AND_CONTRACT_OVERVIEW, PERSONAL_DATA, ADVISOR, ACCOUNTING } =
  dashboardUtil;

export const RightCard: FC = () => {
  const navigate = useNavigate();
  const { householdId, section } = useParams();
  const householdDashboard = useGetHouseholdDashboard();
  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [isConsentEmailSent, setIsConsentEmailSent] = useState('');
  const [chosenPerson, setChosenPerson] = useState<PersonInHouseholdDto | undefined>();
  const [personListWithConsent, setPersonListWithConsent] = useState<dataInterface[]>([]);
  const { hhbContract } = useUtil(householdId as string);

  const pathToDashboard = `/haushalt/${householdId}/analyse`;
  const personList = useMemo(
    () => householdDashboard.data?.personList,
    [householdDashboard.data?.personList],
  );

  const showConsentModal = () => setConsentModalVisibility(true);
  const hideConsentModal = () => setConsentModalVisibility(false);
  const showSuccessConsentModal = (str: string) => setIsConsentEmailSent(str);

  let list: SectionListData[] = rightCardSections;

  const gdprDataList: AnalyseSectionListData[] = useMemo(
    () =>
      (personList || [])?.map((person, index) => {
        const consent = householdDashboard.data?.consentList?.find(
          (data) => data?.personId === person.id,
        );
        const consentStatus = getConsentStatus(consent?.status);

        return {
          id: `person-${index}`,
          gdprStatus: consentStatus,
          householdId,
          personId: person?.id,
          path: PRIVACY_POLICY,
          title: getPersonFullName(person),
          icon: 'mensch',
          renderRightSection: () => (
            <div>
              {consentStatus && (
                <div className="gdpr-status" data-testid={`status-${index}`}>
                  <DxText type="it" color="gray-66" style={{ padding: 0 }}>
                    {getConsentStatusTranslation(consentStatus.descriptionKey)}
                  </DxText>
                  <DxContainer
                    color="white"
                    className="gdpr-icon flex row align-center justify-center"
                    style={{ color: '#337a96' }}
                  >
                    <consentStatus.Icon />
                  </DxContainer>
                </div>
              )}
            </div>
          ),
        };
      }),
    [householdDashboard.data?.consentList, householdId, personList],
  );

  const goToPerson = (personId: string) =>
    `/haushalt/${householdId}/analyse/persoenliche-daten/${personId}`;

  const personalDataList: AnalyseSectionListData[] = (
    householdDashboard.data?.personList || []
  ).map((person, index) => ({
    id: `person-${index}`,
    path: goToPerson(person.id),
    title: getPersonFullName(person),
    icon: 'mensch',
  }));

  if (section === PERSONAL_DATA) {
    list = personalDataList;
  }

  if (section === PRIVACY_POLICY) {
    list = personList ? gdprDataList : [];
  }

  if (section === HHB_AND_CONTRACT_OVERVIEW) {
    list = hhbContract;
  }

  const onItemClick = (path: string, id?: string | null) => {
    if (section === HHB_AND_CONTRACT_OVERVIEW) {
      handleNavigateToPath(path, householdId, ACCOUNTING);
    }
    if (section === PERSONAL_DATA) {
      orchestratorNavigate(
        `/${appNames.person}${path}`,
        `/berater/haushalt/${householdId}/analyse/sektion/persoenliche-daten`,
      );
      return;
    }
    if (path === PRIVACY_POLICY && id?.length) {
      const selectedPerson = personList?.find((person) => id === person.id);
      showConsentModal();
      setChosenPerson(selectedPerson);
    }
    handleNavigateToPath(`${pathToDashboard}/sektion/${path}`, '', ADVISOR);
  };

  useEffect(() => {
    if (householdDashboard.data?.consentList) {
      const listOfConsentGiven = householdDashboard.data?.consentList?.filter(({ status }) =>
        hasApprovedConsent(status),
      );
      setPersonListWithConsent(listOfConsentGiven);
    }
  }, [householdDashboard.data?.consentList]);

  useEffect(() => {
    if (section !== PRIVACY_POLICY) {
      hideConsentModal();
    }
  }, [section]);

  const buildPageTitle = useCallback(
    (path?: string, title?: string, id?: string) => {
      const consentCount = householdDashboard.data?.consentList?.length
        ? `(${personListWithConsent?.length}/${personList?.length})`
        : '';
      const privacyTitle = `${i18next.t('dashboard.bottomList.gdpr')} ${consentCount}`;
      if (
        path === PRIVACY_POLICY &&
        id === PRIVACY_POLICY &&
        !!householdDashboard.data?.consentList?.length
      ) {
        return privacyTitle;
      }
      return title;
    },
    [
      householdDashboard.data?.consentList?.length,
      personListWithConsent?.length,
      personList?.length,
    ],
  );

  const chosenPersonConsentStatus =
    !!chosenPerson &&
    householdDashboard.data?.consentList?.find((data) => data?.personId === chosenPerson.id)
      ?.status;

  const translation = {
    success: i18next.t('sendConsent.success'),
    basicFunctions: i18next.t('dashboard.basicFunctions'),
  };

  const isConsentFetching = householdDashboard.isLoading || householdDashboard.isRefetching;
  return (
    <DxCard style={{ overflow: 'hidden' }}>
      {chosenPerson && (
        <ConsentModal
          visible={consentModalVisibility}
          onClosed={hideConsentModal}
          person={chosenPerson}
          consent={householdDashboard.data?.consentList.find(
            (consent) => consent.personId === chosenPerson.id,
          )}
          isConsentFetching={isConsentFetching}
          onSubmitSuccess={hideConsentModal}
          personConsentStatus={chosenPersonConsentStatus as string}
          setIsConsentEmailSent={showSuccessConsentModal}
        />
      )}
      <DxNotificationToast
        id="consent-email-success"
        data-testid="consent-email-success"
        visible={!!isConsentEmailSent}
        icon={checkIcon('check-kreis')}
        iconcolor="green-80"
        style={{ zIndex: 10000 }}
        closeafter={CLOSE_SECONDS_TIMEOUT}
        onCloseNotification={() => {
          setIsConsentEmailSent('');
        }}
      >
        <div className="notification-text-container">
          <DxText>{translation.success}</DxText>
        </div>
      </DxNotificationToast>
      <DxContainer color="white" className="title-container">
        {![HHB_AND_CONTRACT_OVERVIEW, PRIVACY_POLICY, PERSONAL_DATA].includes(
          section as string,
        ) && (
          <DxText type="ps" className="bottom-list-title">
            {i18next.t('analyseDashboard.personalInformation.header')}
          </DxText>
        )}
        {section === PERSONAL_DATA && (
          <DxText
            onClick={() => {
              handleNavigateToPath(`${pathToDashboard}`, '', ADVISOR);
            }}
            className="bottom-list-title"
            data-testid="personal-data-title"
          >
            <DxIcon color="default" icon={checkIcon('pfeil-links')} size={24} className="chevron" />
            {i18next.t('dashboard.bottomList.personalData')}
          </DxText>
        )}
        {section === PRIVACY_POLICY && (
          <DxText
            onClick={() => navigate(pathToDashboard)}
            className="bottom-list-title"
            data-testid="privacy-policy-title"
          >
            <DxIcon color="default" icon={checkIcon('pfeil-links')} size={24} className="chevron" />
            {i18next.t('sendConsent.dataProtection')}
          </DxText>
        )}
        {section === HHB_AND_CONTRACT_OVERVIEW && (
          <DxText
            onClick={() => handleNavigateToPath(`${pathToDashboard}`, '', ADVISOR)}
            className="bottom-list-title"
            data-testid="hhb-contract-overview-title"
          >
            <DxIcon color="default" icon={checkIcon('pfeil-links')} size={24} className="chevron" />
            {i18next.t('dashboard.bottomList.hhbAndContractOverview')}
          </DxText>
        )}
      </DxContainer>
      <DxCardContent className="dashboard-card-content">
        <div className="flex column">
          <DxList size="m">
            {list?.map(({ title, icon, path, id, personId, renderRightSection }) => (
              <Item
                id={id}
                key={id}
                onItemClick={() => onItemClick(path, personId)}
                title={buildPageTitle(path, title, id)}
                icon={icon}
                renderRightSection={renderRightSection}
              />
            ))}
          </DxList>
        </div>
      </DxCardContent>
    </DxCard>
  );
};
