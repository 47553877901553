import APIClient from '@dvag/dfs-api-client';
import * as context from '@dvag/dfs-orchestrator-client';

import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config/api-urls';
import { Advisor } from '../../type/advisor';
import { versions } from './versions';

const config = { baseURL: `${getEnvironmentApiUrls().advisor}/${versions.v4}` };
const api = new APIClient({
  config,
  options: {
    getToken: context.getToken,
    getContext: context.getContext,
    hasDefaultRequestInterceptors: true,
  },
});

export const advisorApi = () => {
  const getAdvisorInformation = async () => api.get<Advisor>(`/advisor`);

  return {
    getAdvisorInformation,
  };
};
