import { reverse, Routes, ReverseParams } from 'named-urls';

export const buildPath = (route: string | Routes, params: ReverseParams): string => {
  let finalRoute = String(route);

  if (finalRoute.slice(-1) === '/') {
    finalRoute = finalRoute.slice(0, -1);
  }

  return reverse(finalRoute, params);
};
