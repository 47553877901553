import { useMutation } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { CommunicationChannelSectionDataType } from 'type/person';
import { TransformParameters } from 'type/taxData';
import { queryKeyStr, transformEmptyToNull } from 'utils/util';
import { useNotification } from 'utils/notification/NotificationContext';
import { CustomError } from '@dvag/dfs-api-client/error';

export const useCreateOrUpdateCommunicationChannel = (personId: string | undefined) => {
  const { showError } = useNotification();
  const personService = personApi();
  const mutationKey = [`${queryKeyStr}${personId}`];

  const mutationFn = async (communicationChannelData: CommunicationChannelSectionDataType) => {
    const { id, value, ...restData } = communicationChannelData;
    const rawData = {
      value: value?.toLowerCase(),
      type: restData.type,
      prefix: restData.prefix,
      birthDate: restData.birthDate,
    };
    const transformedData = transformEmptyToNull(rawData);
    const dataToSubmit = typeof transformedData === 'object' ? transformedData : {};
    const payload: CommunicationChannelSectionDataType | TransformParameters = {
      ...dataToSubmit,
    };
    const response = id
      ? await personService.updateCommunicationChannel(id, payload, personId)
      : await personService.createCommunicationChannel(payload, personId);
    return response.data as CommunicationChannelSectionDataType;
  };

  return useMutation<
    CommunicationChannelSectionDataType,
    CustomError,
    CommunicationChannelSectionDataType
  >({
    mutationKey,
    mutationFn,
    onError: (error) => {
      showError({ status: error?.status });
    },
  });
};
