import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { queryClient } from 'config/reactQuery';
import { Authenticator } from 'service/authentication/Authenticator';
import { NotificationProvider } from 'utils/notification/NotificationProvider';

import { I18nextProvider } from 'react-i18next';
import i18n, { defaultNS } from 'locale/i18n';
import { Router } from './Router';
import featureFlag from './service/featureFlag';
import { getAppInsights } from './service/getAppInsights';

export const App = () => {
  featureFlag.init();

  return (
    <AppInsightsContext.Provider value={getAppInsights().reactPlugin}>
      <I18nextProvider i18n={i18n} defaultNS={defaultNS}>
        <GrowthBookProvider growthbook={featureFlag.gb}>
          <QueryClientProvider client={queryClient}>
            <Authenticator>
              <NotificationProvider>
                <Router />
              </NotificationProvider>
            </Authenticator>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </GrowthBookProvider>
      </I18nextProvider>
    </AppInsightsContext.Provider>
  );
};
