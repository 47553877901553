import { DxListItem } from '@dvag/design-system-react';
import i18next from 'i18next';

import { Address as TAddress } from 'type/address';
import { PersonNameList } from 'type/household';
import { getPersonFullName } from './nameList/NameList';

interface HouseholdProps {
  personList?: PersonNameList[];
  address: TAddress;
  onClick: () => void;
}

export const Household = ({ personList, onClick, address }: HouseholdProps) => {
  if (!Array.isArray(personList)) return null;

  const personListName = personList.map((person) => getPersonFullName(person));

  const householdSublabel = () => {
    const { countryName, street, zipCode, city } = address;

    const addressLabel = [];
    if (street) addressLabel.push(street);
    if (zipCode) addressLabel.push(zipCode);
    if (city) addressLabel.push(city);
    if (countryName) addressLabel.push(countryName);

    const personsInHousehold = i18next.t('dashboard.personsInHousehold', {
      personList: personList.length,
    });

    return addressLabel.length
      ? `${addressLabel.join(', ')} \n ${personsInHousehold}`
      : personsInHousehold;
  };

  return (
    <DxListItem
      label={personListName.join(', ')}
      actionindicator="navigate"
      sublabel={householdSublabel()}
      data-testid="select-household"
      className="name-wr small-layout-item"
      onClick={onClick}
    />
  );
};
