import { getEnvironmentGTMContainerId } from '@dvag/dfs-constant-config/gtm';
import { addInitListener } from '@dvag/dfs-orchestrator-client';
import { defineCustomElements as designSystemDefineElements } from '@dvag/design-system/loader';
import { defineCustomElements as defineDesignSystemIcons } from '@dvag/design-system-icons/loader';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { App } from 'App';
import { getLocale } from 'locale/i18n';

const htmlEl = document.documentElement || document.getElementsByTagName('html')[0];
htmlEl.lang = getLocale();

interface WindowExtended extends Window {
  dataLayer?: { ['gtm.start']: number; event: string }[];
}
// Google Tag Manager
((w: WindowExtended, d, s, l: 'dataLayer', i) => {
  const w1 = w;
  w1[l] = w1[l] || [];
  w1[l]?.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0];
  const j: { async: boolean; src: string } = d.createElement(s) as HTMLElement & {
    async: boolean;
    src: string;
  };
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}`;
  if (f.parentNode) f.parentNode.insertBefore(j as unknown as Node, f);
})(window, document, 'script', 'dataLayer', getEnvironmentGTMContainerId());
// End Google Tag Manager

addInitListener(() => {
  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('Failed to find the root element');
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
  designSystemDefineElements();

  defineDesignSystemIcons();
});
