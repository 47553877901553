import { addContextUpdateListener, getContext } from '@dvag/dfs-orchestrator-client';
import { FeatureDefinition, GrowthBook } from '@growthbook/growthbook-react';

type FeatureFlag = {
  gb: GrowthBook<Record<string, unknown>>;
  init: () => void;
};

const featureFlag: FeatureFlag = {
  gb: new GrowthBook(),
  init: () => {
    const initFeatures = getContext().featureFlags as unknown as Record<
      string,
      FeatureDefinition<unknown>
    >;
    const payload = {
      features: initFeatures,
      attributes: {
        advisorId: getContext().user.advisor.id,
      },
    };
    featureFlag.gb = new GrowthBook(payload);

    featureFlag.gb.initSync({
      payload,
    });
    addContextUpdateListener(({ featureFlags }) => {
      const updatedFeatures = featureFlags as unknown as Record<string, FeatureDefinition<unknown>>;
      featureFlag.gb.setPayload({
        ...payload,
        features: updatedFeatures,
      });
    });
  },
};

export default featureFlag;
