import { appNames } from '@dvag/dfs-constant-config/app-names';
import { DxButton, DxCard, DxCardContent, DxContainer, DxGrid } from '@dvag/design-system-react';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';

import { route } from 'config/route';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { checkIcon } from 'utils/util';
import { checkIsMobile } from 'utils/windowSize';

import { PersonInHouseholdDto } from 'graphql/person/generates';
import { HouseholdInterface } from 'type/household';
import { NameList } from './household/nameList/NameList';
import { Separator } from './separator';

import './style.css';

interface HouseholdHeaderProps {
  household: HouseholdInterface | undefined;
  selectedHouseholdId: string | undefined;
  personList: PersonInHouseholdDto[];
}

const translation = {
  personalInformation: i18next.t('general.personalInformation'),
  switchHousehold: i18next.t('general.switchHousehold'),
};

export const HouseholdHeader = ({
  household,
  selectedHouseholdId,
  personList,
}: HouseholdHeaderProps) => {
  const { householdId, selectedPersonId }: { householdId?: string; selectedPersonId?: string } =
    useParams();

  const isMobile = checkIsMobile(759);
  const list = (household?.personList ?? personList).sort((a, b) => a.order - b.order);

  return (
    <DxCard data-testid="household-header">
      <DxCardContent>
        <DxGrid mq1="12/*" mq2="12/*" mq3="3-9" mq4="5-7" mq5="6-6">
          <div className="household-name">
            {!selectedPersonId && list ? (
              <NameList list={list} />
            ) : (
              <DxContainer color="gray-16" className="placeholder-dashboard-container" />
            )}
          </div>

          <div className="view-person-container">
            <DxButton
              disabled={!!selectedPersonId}
              data-testid="view-person"
              id="view-person"
              onClick={() =>
                handleNavigateToPath(
                  '/haushalt/:householdId/verwalten',
                  selectedHouseholdId ?? householdId,
                  appNames.person,
                )
              }
              label={translation.personalInformation}
              type={isMobile ? 'secondary-s' : 'secondary-m'}
              icon={checkIcon('bearbeiten')}
              iconposition="left"
              stretch={isMobile}
            />
            <Separator />
            <DxButton
              disabled={!!selectedPersonId}
              data-testid="switch-household"
              id="switch-household"
              onClick={() =>
                handleNavigateToPath(route.householdDashboard, undefined, appNames.advisor)
              }
              label={translation.switchHousehold}
              type={isMobile ? 'secondary-s' : 'secondary-m'}
              icon={checkIcon('loop')}
              iconposition="left"
              stretch={isMobile}
            />
          </div>
        </DxGrid>
      </DxCardContent>
    </DxCard>
  );
};
