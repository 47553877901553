import React, { ReactNode } from 'react';
import { DxContainer, DxGrid } from '@dvag/design-system-react';
import { useParams } from 'react-router-dom';
import { getContext } from '@dvag/dfs-orchestrator-client';

interface CardListContainerProps {
  children: ReactNode;
}

export const CardListContainer = ({ children }: CardListContainerProps) => {
  const recommendationPermission = getContext()?.user?.permissions.includes(
    'permission.vpd.app-web-vp-digital.empfehlungsnahme',
  );
  const teambuildingPermission = getContext()?.user?.permissions.includes(
    'permission.vpd.app-web-vp-digital.teamaufbau',
  );

  const hasRecommendationOrTeambuildingPermission =
    recommendationPermission || teambuildingPermission;
  const { householdId } = useParams();
  const disabledStyle = !householdId
    ? {
        opacity: '0.6',
        pointerEvents: 'none',
      }
    : {};
  return (
    <div style={{ overflow: 'hidden' }} data-testid="card-list">
      <DxContainer color="transparent" style={disabledStyle}>
        <DxContainer color="transparent" className="dashboard-bottom-card-container">
          <DxGrid
            mq1="12/*"
            mq2="12/*"
            mq4={hasRecommendationOrTeambuildingPermission ? '6-6' : '12'}
            mq5={hasRecommendationOrTeambuildingPermission ? '6-6' : '12'}
          >
            {children}
          </DxGrid>
        </DxContainer>
      </DxContainer>
    </div>
  );
};
