import i18next from 'i18next';
import { constant } from '../../config/asset/constant';

const local = 'wishesAndReferrals';

export const revealCardsObject = {
  'left-explanation-card': false,
  'middle-explanation-card': false,
  'right-explanation-card': false,
};

type WishesAndReferralsCards = {
  id: keyof typeof revealCardsObject;
  imageSrc: string;
  imageAlt: string;
  description: string;
};

export const wishesAndReferralsCards: WishesAndReferralsCards[] = [
  {
    id: 'left-explanation-card',
    imageSrc: `${constant.route}${local}/leftExplanationCard.png`,
    imageAlt: 'left-explanation-card',
    description: i18next.t(
      'OGC.wishesAndReferrals.cardContent.myExpectationCards.leftExplanationCard',
    ),
  },
  {
    id: 'middle-explanation-card',
    imageSrc: `${constant.route}${local}/middleExplanationCard.png`,
    imageAlt: 'middle-explanation-card',
    description: i18next.t(
      'OGC.wishesAndReferrals.cardContent.myExpectationCards.middleExplanationCard',
    ),
  },
  {
    id: 'right-explanation-card',
    imageSrc: `${constant.route}${local}/rightExplanationCard.png`,
    imageAlt: 'right-explanation-card',
    description: i18next.t(
      'OGC.wishesAndReferrals.cardContent.myExpectationCards.rightExplanationCard',
    ),
  },
];
