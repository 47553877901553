import { useMutation } from '@tanstack/react-query';
import { appointmentApi } from 'service/api/appointmentApi';
import { useNotification } from 'utils/notification/NotificationContext';
import { CustomError } from '@dvag/dfs-api-client/error';
import { Feedback } from '../type/advisor';

interface PropTypes {
  householdId: string;
  appointmentId: string;
}

export const useCreateFeedback = ({ householdId, appointmentId }: PropTypes) => {
  const { showError } = useNotification();
  const appointmentService = appointmentApi();
  return useMutation<Feedback, CustomError, Feedback, unknown>({
    mutationFn: async (values: Feedback) => {
      const response = await appointmentService.postFeedback({
        appointmentId,
        householdId,
        ...values,
      });
      return response.data;
    },
    onError: (error) => {
      showError({ status: error?.status });
    },
  });
};
