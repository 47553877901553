import { addMessageListener, removeMessageListener } from '@dvag/dfs-orchestrator-client';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export function useFavoritesUpdatedListener() {
  const queryClient = useQueryClient();

  useEffect(() => {
    const customMsgId = addMessageListener('favoritesUpdated', () =>
      queryClient.invalidateQueries({ queryKey: ['getUserFavorites'] }),
    );
    return () => {
      removeMessageListener(customMsgId);
    };
  }, [queryClient]);
}
