import { DxContainer, DxSpacer } from '@dvag/design-system-react';

import { SettingHeader } from './component/SettingHeader/SettingHeader';
import { VPDigitalSettings } from './component/VPDigitalSettings/VPDigitalSettings';
import { MoreSettings } from './component/MoreSettings/MoreSettings';

import './style.css';

export const SettingPage = () => (
  <>
    <SettingHeader />
    <DxContainer
      color="transparent"
      type="page"
      className="setting-page-container"
      data-testid="setting-page-container"
    >
      <VPDigitalSettings />
      <DxSpacer size="24v" />
      <MoreSettings />
    </DxContainer>
  </>
);
