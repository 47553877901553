import React, { ReactElement } from 'react';

import { DxAccordion, DxAccordionGroup } from '@dvag/design-system-react';

import { Accordion } from './accordion/Accordion';
import { getSummaryText } from './searchInformation/getSummaryText';

import './style.css';

interface HouseholdListAccordionProps {
  name: string;
  isLoading: boolean;
  count: number;
  children: ReactElement;
  reloadList: (event: React.MouseEvent<HTMLDxAccordionElement, MouseEvent>) => void;
  hidden: boolean;
}

export const HouseholdListAccordion = ({
  name,
  isLoading,
  count,
  children,
  reloadList,
  hidden,
}: HouseholdListAccordionProps) =>
  !hidden ? (
    <Accordion>
      {[
        {
          id: 'accordion',
          isOpen: true,
          content: (
            <DxAccordionGroup>
              <DxAccordion
                onClick={(e) => reloadList(e)}
                id="showRecentHouseholds"
                data-testid="showRecentHouseholds"
                className="accordion"
                label={getSummaryText(name, isLoading, count)}
              >
                {children}
              </DxAccordion>
            </DxAccordionGroup>
          ),
        },
      ]}
    </Accordion>
  ) : (
    children
  );
