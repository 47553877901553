import i18next from 'i18next';

import { AnalyseBasicSectionListData } from 'type/sectionList';
import { LIVING_SITUATION, PENSION } from './utils';

export const leftCardSections: AnalyseBasicSectionListData[] = [
  {
    id: PENSION,
    path: 'publicPension',
    params: ['householdId'],
    title: i18next.t('analyseDashboard.basicData.pension'),
    icon: 'hausrat-02',
  },
  {
    id: LIVING_SITUATION,
    path: 'housing',
    params: ['householdId'],
    title: i18next.t('analyseDashboard.basicData.livingSituation'),
    icon: 'haus',
  },
];
