import React, { Suspense, useEffect, useRef } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation, matchPath } from 'react-router-dom';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { logout, sendTitle } from '@dvag/dfs-orchestrator-client/messengers';
import { syncOrchestratorUrl } from '@dvag/dfs-orchestrator-client/navigation';
import { Error404, Error403, ErrorCrashedApp } from '@dvag/dfs-ui-blocks/components';

import { route } from 'config/route';
import { TError } from 'config/reactQuery';
import { RecommendationPage } from 'screen/RecommendationPage/RecommendationPage';
import { RecommendationPresentation } from 'screen/RecommendationPresentation/RecommendationPresentation';
import { HouseholdDashboard } from 'screen/HouseholdDashboard/HouseholdDashboard';
import { AdvisoryDashboard } from 'screen/AdvisoryDashboard/AdvisoryDashboard';
import { AnalyseDashboard } from 'screen/AnalyseDashboard/Dashboard';
import i18next from 'i18next';
import { Favorites } from './screen/Favorites/Favorites';
import { EvaluateBefore } from './screen/onGoodCooperation/EvaluateBefore/EvaluateBefore';
import { EvaluateAfter } from './screen/onGoodCooperation/EvaluateAfter/EvaluateAfter';
import { WishesAndReferrals } from './screen/onGoodCooperation/WishesAndReferrals/WishesAndReferrals';
import { Recruiting } from './screen/onGoodCooperation/Recruiting/Recruiting';
import { SettingPage } from './screen/SettingPage/SettingPage';
import { getAppInsights } from './service/getAppInsights';

const {
  home,
  householdDashboard,
  householdDashboardId,
  householdDashboardIdSection,
  advisoryDashboardIdSection,
  search,
  placeholder,
  teamBuilding,
  teamBuildingWithoutHH,
  recommendationPage,
  recommendationPageSelectedSection,
  recommendationPresentation,
  teamBuildingPresentation,
  advisoryDashboard,
  analyseDashboard,
  analyseDashboardSection,
  favorites,
  evaluateBefore,
  evaluateAfter,
  wishesAndReferrals,
  recruiting,
  setting,
  settingId,
} = route;

const titleTranslations: Record<string, string> = {
  householdDashboard: i18next.t('dashboard.welcome'),
  householdDashboardId: i18next.t('dashboard.clientWelcome'),
  householdDashboardIdSection: i18next.t('dashboard.clientWelcome'),
  advisoryDashboardIdSection: i18next.t('advisoryDashboard.headline'),
  search: i18next.t('dashboard.welcome'),
  placeholder: i18next.t('dashboard.welcome'),
  teamBuilding: i18next.t('recommendationPage.recommendation'),
  teamBuildingWithoutHH: i18next.t('dreamJobPage.dreamJob'),
  recommendationPage: i18next.t('recommendationPage.recommendation'),
  recommendationPageSelectedSection: i18next.t('recommendationPage.recommendation'),
  recommendationPresentation: i18next.t('recommendationPage.recommendation'),
  teamBuildingPresentation: i18next.t('dreamJobPage.dreamJob'),
  advisoryDashboard: i18next.t('advisoryDashboard.headline'),
  analyseDashboard: i18next.t('analyseDashboard.headline'),
  analyseDashboardSection: i18next.t('analyseDashboard.headline'),
  favorites: i18next.t('favorites.headline'),
  evaluateBefore: i18next.t('OGC.interviewConclusion.evaluateBefore.title'),
  evaluateAfter: i18next.t('OGC.interviewConclusion.evaluateAfter.title'),
  wishesAndReferrals: i18next.t('OGC.wishesAndReferrals.title'),
  recruiting: i18next.t('OGC.interviewConclusion.recruitPage.title'),
};

const App = () => {
  const isInitialMount = useRef(true);
  const location = useLocation();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (location.key) {
      syncOrchestratorUrl(`${location.pathname}`);
    }

    const currentPath = Object.keys(route).find((key) => matchPath(route[key], location.pathname));

    if (currentPath) {
      sendTitle(titleTranslations[currentPath] ? titleTranslations[currentPath] : 'Berater');
    }
  }, [location]);

  const fallbackComponent = ({ error }: FallbackProps) => {
    const { response } = error as TError;
    if ([response?.status, error.cause, error.status].includes(403)) return <Error403 />;
    if ([response?.status, error.cause, error.status].includes(404)) return <Error404 />;
    return <ErrorCrashedApp />;
  };

  const { appInsights } = getAppInsights();

  const errorHandler = (error: Error) => {
    appInsights.trackException({
      exception: new Error(error.message),
      severityLevel: 3,
    });
    const { response } = error as TError;
    if (response?.status === 401) logout();
  };

  return (
    <Suspense fallback={null}>
      <ErrorBoundary
        FallbackComponent={fallbackComponent}
        onError={errorHandler}
        resetKeys={[location]}
      >
        <kundenzugang-webcomponent />
        <Routes>
          <Route path={analyseDashboard} element={<AnalyseDashboard />} />
          <Route path={analyseDashboardSection} element={<AnalyseDashboard />} />
          <Route path={recommendationPage} element={<RecommendationPage teamBuilding={false} />} />
          <Route
            path={recommendationPageSelectedSection}
            element={<RecommendationPage teamBuilding={false} />}
          />
          <Route path={teamBuilding} element={<RecommendationPage teamBuilding />} />
          <Route path={teamBuildingWithoutHH} element={<RecommendationPage teamBuilding />} />
          <Route
            path={recommendationPresentation}
            element={<RecommendationPresentation teamBuilding={false} />}
          />
          <Route
            path={teamBuildingPresentation}
            element={<RecommendationPresentation teamBuilding />}
          />
          <Route path={search} element={<HouseholdDashboard />} />
          <Route path={placeholder} element={<HouseholdDashboard />} />
          <Route path={householdDashboard} element={<HouseholdDashboard />} />
          <Route path={householdDashboardId} element={<HouseholdDashboard />} />
          <Route path={householdDashboardIdSection} element={<HouseholdDashboard />} />
          <Route path={home} element={<Navigate to={householdDashboard} />} />
          <Route path={advisoryDashboard} element={<AdvisoryDashboard />} />
          <Route path={advisoryDashboardIdSection} element={<AdvisoryDashboard />} />
          <Route path={favorites} element={<Favorites />} />
          <Route path={evaluateBefore} element={<EvaluateBefore />} />
          <Route path={evaluateAfter} element={<EvaluateAfter />} />
          <Route path={wishesAndReferrals} element={<WishesAndReferrals />} />
          <Route path={recruiting} element={<Recruiting />} />
          <Route path={setting} element={<SettingPage />} />
          <Route path={settingId} element={<SettingPage />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
};

export const Router = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
