import { useCallback, useMemo } from 'react';
import { PageCodes } from '@dvag/dfs-constant-config';
import i18next from 'i18next';
import { Type, useGetPagesConfigurationQuery } from 'graphql/configuration/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';
import { FavoritePage } from '@dvag/dfs-ui-blocks/components';

export const useGetFavoritesCatalog = (nSimulationFlag?: boolean) => {
  const query = useGetPagesConfigurationQuery(getGraphQLClient('configuration'), {
    type: Type.Favorite,
  });

  const getPageName = useCallback(
    (code: string) => {
      if (code === 'SIMULATION' && nSimulationFlag) {
        return i18next.t(`pages.FUTURE_HOUSEHOLD_BUDGET`, { ns: 'pages' });
      }
      return i18next.t(`pages.${code as PageCodes}`, code, { ns: 'pages' });
    },
    [nSimulationFlag],
  );

  const catalog = useMemo(
    () =>
      query.data?.config.pages.map(
        (page) => ({
          permissions: page.permissions,
          code: page.code,
          name: getPageName(page.code),
          aliases: page.aliases,
          featureFlags: page.featureFlags,
        }),
        [query.data?.config.pages],
      ),
    [getPageName, query.data?.config.pages],
  ) as FavoritePage[];

  return { ...query, data: catalog };
};
