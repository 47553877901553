import { useEffect, useMemo, useState } from 'react';
import debounce from 'debounce';
import { PendingRequestModal } from 'component/PendingRequestModal';
import { UnsavedDataModal } from '../UnsavedDataModal';

interface UnsavedOrPendingModalProps {
  showPending: boolean;
  showUnsaved?: boolean;
  onCancel?: (() => void) | undefined;
  onConfirm?: (() => void) | undefined;
}

export const UnsavedOrPendingModal = ({
  showPending: currentShowPending,
  showUnsaved: currentShowUnsaved = false,
  onCancel,
  onConfirm,
}: UnsavedOrPendingModalProps) => {
  const [showUnsaved, setShowUnsaved] = useState(currentShowUnsaved);
  const [showPending, setShowPending] = useState(currentShowPending);
  const debouncedSetShowUnsaved = useMemo(() => debounce(setShowUnsaved, 120), []);
  const debouncedSetShowPending = useMemo(() => debounce(setShowPending, 120), []);
  useEffect(() => {
    debouncedSetShowUnsaved(currentShowUnsaved);
  }, [debouncedSetShowUnsaved, currentShowUnsaved]);

  useEffect(() => {
    debouncedSetShowPending(currentShowPending);
  }, [debouncedSetShowPending, currentShowPending]);
  return (
    <>
      <UnsavedDataModal onCancel={onCancel} onConfirm={onConfirm} showModal={!!showUnsaved} />
      <PendingRequestModal visible={showPending} subscribeOnModalClose={onCancel} />
    </>
  );
};
