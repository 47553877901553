import React from 'react';
import { DxNotificationToast, DxText } from '@dvag/design-system-react';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';

import { useNotificationContext } from './NotificationContext';
import { NotificationType } from './types';
import { checkIcon } from '../util';

const CLOSE_TIMEOUT = 5;

const iconMap: Record<NotificationType, IconName> = {
  default: 'check-kreis',
  success: 'check-kreis',
  error: 'warndreieck',
};

export const NotificationComponent = () => {
  const { data, hide } = useNotificationContext();

  const type = data?.type || 'default';
  const hasNoTitle = data?.noTitle;
  const title = hasNoTitle ? '' : data?.title || '';
  const description = data?.description || '';

  return (
    <DxNotificationToast
      id="notification-toast"
      data-testid="notification-toast"
      visible={!!data}
      closeafter={CLOSE_TIMEOUT}
      icon={checkIcon(iconMap[type])}
      type={type}
      titlestring={title}
      onCloseNotification={hide}
    >
      {description && <DxText>{description}</DxText>}
    </DxNotificationToast>
  );
};
