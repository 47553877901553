import { SimulationTypeKey } from 'type/accounting';

const route = '/assets/images/';

export const constant: { route: string } = {
  route,
};

export const SimulationType: { [key in SimulationTypeKey]: key } = {
  FUTURE_HOUSEHOLD_BUDGET: 'FUTURE_HOUSEHOLD_BUDGET',
  RETIREMENT: 'RETIREMENT',
  MANPOWER: 'MANPOWER',
} as const;
