import React from 'react';
import { DxContainer, DxIcon } from '@dvag/design-system-react';
import { checkIcon } from 'utils/util';
import './style.css';

export const ListsSeparator = () => (
  <DxContainer className="flex favorites-lists-separator" color="transparent">
    <DxContainer color="transparent" className="favorites-lists-separator-line" />
    <DxContainer className="favorites-lists-separator-icons-container" color="transparent">
      <DxIcon
        className="favorites-lists-separator-icons"
        data-testid="left-icon"
        id="left-icon"
        icon={checkIcon('pfeil-links')}
        size={32}
      />
      <DxIcon
        className="favorites-lists-separator-icons"
        data-testid="right-icon"
        id="right-icon"
        icon={checkIcon('pfeil-rechts')}
        size={32}
      />
    </DxContainer>
    <DxContainer color="transparent" className="favorites-lists-separator-line" />
  </DxContainer>
);
