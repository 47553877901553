import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { navigate, navigatePrevious } from '@dvag/dfs-orchestrator-client/messengers';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import {
  DxCard,
  DxCardContent,
  DxContainer,
  DxGrid,
  DxProcessHeader,
  DxText,
  DxTextArea,
} from '@dvag/design-system-react';
import i18next from 'i18next';
import * as yup from 'yup';
import { buildPath } from 'service/buildPath';
import { route } from 'config/route';
import {
  appointmentType,
  interviewStepNames,
  postImpression,
} from 'service/model/interviewConclusion';
import { Feedback } from 'type/advisor';
import { useFeedback } from 'hooks/useFeedback';
import { useAppointment } from 'hooks/useAppointment';
import { OGCButtons } from '../components/OGCButtons/OGCButtons';
import { OGCStateCard } from '../components/OGCStateCard/OGCStateCard';
import { UnsavedOrPendingModal } from '../../../component/UnsavedOrPendingModal';
import { useContinuation } from '../../../utils/useContinuation';
import { useUpdateFeedback } from '../../../hooks/useUpdateFeedback';
import { useCreateFeedback } from '../../../hooks/useCreateFeedback';
import './style.css';

const componentId = 'ogc-evaluate-after';

export const EvaluateAfter = () => {
  const { householdId = '' } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { continuation, getContinuationHandler, getCanContinueHandler, onContinuationRequest } =
    useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const appointmentQuery = useAppointment({
    householdId,
    type: appointmentType.financial,
  });

  const appointmentId = useMemo(() => appointmentQuery.data?.id, [appointmentQuery.data]);

  const feedbackQuery = useFeedback({ householdId, appointmentId });

  const [postImpressionState, setPostImpressionState] = useState(
    feedbackQuery.data?.postImpression,
  );
  const [postImpressionCommentState, setPostImpressionCommentState] = useState(
    feedbackQuery.data?.postImpressionComment,
  );

  const updateFeedbackMutation = useUpdateFeedback({
    householdId: householdId || '',
  });

  const createFeedbackMutation = useCreateFeedback({
    householdId: householdId || '',
    appointmentId: appointmentId || '',
  });

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        postImpression: yup.string().nullable(),
        postImpressionComment: yup.string().nullable(),
      }),
    [],
  );

  const { flushChanges, setValue } = useAutosave<Feedback>({
    data: feedbackQuery.data,
    onIsDirtyChange: (isDirtyParam) => {
      if (!isDirtyParam) {
        getCanContinueHandler();
      }
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: setIsSubmitting,
    createResource: createFeedbackMutation.mutateAsync,
    updateResource: updateFeedbackMutation.mutateAsync,
    resourceSchema,
  });

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  useEffect(() => {
    setPostImpressionState(feedbackQuery.data?.postImpression);
    setPostImpressionCommentState(feedbackQuery.data?.postImpressionComment);
  }, [feedbackQuery.data?.postImpression, feedbackQuery.data?.postImpressionComment]);

  const handleForwardClick = () => {
    const path = `/${appNames.advisor}${buildPath(route.wishesAndReferrals, {
      householdId,
    })}`;
    navigate(path);
  };

  return (
    <>
      <DxProcessHeader
        id={`${componentId}-header`}
        headline={i18next.t('OGC.interviewConclusion.evaluateAfter.title')}
        sublabel={i18next.t('OGC.interviewConclusion.evaluateAfter.subtitle')}
      />
      <DxContainer color="transparent" type="page">
        <DxCard className="ogc-evaluate-after-container">
          <DxCardContent>
            <DxContainer color="transparent" className="ogc-evaluate-after-card-container">
              <DxText type="h5" data-testid={`${componentId}-question-title`}>
                {i18next.t('OGC.interviewConclusion.evaluateAfter.question')}
              </DxText>
              <DxGrid
                mq1="12/12/12"
                mq2="12/12/12"
                mq3="4-4-4"
                mq4="4-4-4"
                mq5="4-4-4"
                className="ogc-evaluate-after-grid"
              >
                {postImpression.map(({ id, label, icon, value }) => (
                  <OGCStateCard
                    key={id}
                    id={id}
                    label={label}
                    icon={icon}
                    onSelect={() => {
                      setPostImpressionState(value);
                      setValue(interviewStepNames.postImpression, value);
                    }}
                    isRevealed={value === postImpressionState}
                    data-testid={`${componentId}-state-card-${id}`}
                    disabled={!appointmentId || feedbackQuery.isFetching}
                  />
                ))}
              </DxGrid>
              {postImpressionState && (
                <DxTextArea
                  disabled={!appointmentId || feedbackQuery.isFetching}
                  id={`${componentId}-text-area-reason`}
                  data-testid={`${componentId}-text-area-reason`}
                  label={i18next.t('OGC.interviewConclusion.evaluateAfter.entryField')}
                  value={postImpressionCommentState}
                  onValueChange={(event) => {
                    setPostImpressionCommentState(event.detail);
                    setValue(interviewStepNames.postImpressionComment, event.detail);
                  }}
                />
              )}
            </DxContainer>
          </DxCardContent>
        </DxCard>
        <OGCButtons
          id={`${componentId}-nav-buttons`}
          data-testid={`${componentId}-nav-buttons`}
          handleForwardClick={getContinuationHandler(handleForwardClick)}
          handleBackClick={getContinuationHandler(navigatePrevious)}
        />
      </DxContainer>
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </>
  );
};
