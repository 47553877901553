import React, { ReactNode, createContext, useMemo } from 'react';

import { useRouteAuthentication } from './useRouteAuthentication';

interface AuthenticationContextType {
  profile?: { [key: string]: string | number | boolean };
}
interface AuthenticatorProps {
  children: ReactNode;
}

export const AuthenticationContext = createContext({} as AuthenticationContextType);

export const Authenticator = ({ children }: AuthenticatorProps) => {
  const { orchestratorIsInitialized, profile } = useRouteAuthentication();
  const memoProfile = useMemo(() => ({ profile }), [profile]);
  if (orchestratorIsInitialized && profile) {
    return (
      <AuthenticationContext.Provider value={memoProfile}>
        {children}
      </AuthenticationContext.Provider>
    );
  }

  return null;
};
