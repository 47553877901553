import React from 'react';

import './style.css';

export const HouseholdLoading = () => (
  <div className="loading-placeholder-wr" data-testid="loading-placeholder-wr">
    <div className="person-list-box">
      <span className="block-placeholder" />
    </div>

    <div className="arrow-box">
      <div className="icon-placeholder" />
    </div>
  </div>
);
