import { FC } from 'react';
import { DxButton, DxContainer, DxListItem } from '@dvag/design-system-react';
import { checkIcon } from 'utils/util';

type FavoriteItemProps = {
  label: string;
  onClick: () => void;
};

export const FavoritesItem: FC<FavoriteItemProps> = ({ label, onClick }) => (
  <DxListItem
    className="favorites-selection-list-item"
    label={label}
    id={`${label}-list-item`}
    onClick={onClick}
    data-testid={`${label}-list-item`}
    labelwordbreak="break-word"
  >
    <DxContainer slot="content" className="favorites-selection-item-icon-container">
      <DxButton
        id={`${label}-list-button`}
        type="text"
        icon={checkIcon('stern-01')}
        color="default"
      />
    </DxContainer>
  </DxListItem>
);
