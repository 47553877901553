import { createContext, useContext } from 'react';
import { NotificationContextType } from './types';

export const NotificationContext = createContext<NotificationContextType | null>(null);

export const useNotificationContext = () =>
  useContext(NotificationContext) as NotificationContextType;

export const useNotification = () => {
  const { showSuccess, showError } = useNotificationContext();

  return {
    showSuccess,
    showError,
  };
};
