import React from 'react';

const NotSyncedGdpr = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8.5" stroke="currentColor" />
    <circle cx="12" cy="16" r="0.5" stroke="currentColor" />
    <rect x="11.75" y="7.25" width="0.5" height="6.5" stroke="currentColor" strokeWidth="0.5" />
  </svg>
);

const ApprovedGdpr = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white" />
    <circle cx="12" cy="12" r="9" fill="currentColor" />
    <path d="M7.78125 11.625L10.5938 14.4375L16.2188 8.8125" stroke="white" />
  </svg>
);

const PendingApproval = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.83333 5H18.1667V5.51192C18.1667 6.29322 17.8014 7.02958 17.1794 7.50233L14.9279 9.21343C13.085 10.6141 13.085 13.3859 14.9279 14.7866L17.1794 16.4977C17.8014 16.9704 18.1667 17.7068 18.1667 18.4881V19H5.83333V18.4881C5.83333 17.7068 6.19859 16.9704 6.82063 16.4977L9.07208 14.7866C10.915 13.3859 10.915 10.6141 9.07208 9.21343L6.82063 7.50233C6.19859 7.02958 5.83333 6.29322 5.83333 5.51192V5Z"
      stroke="currentColor"
    />

    <path
      d="M5.83333 5H18.1667V5.51192C18.1667 6.29322 17.8014 7.02958 17.1794 7.50233L14.9279 9.21343C13.085 10.6141 13.085 13.3859 14.9279 14.7866L17.1794 16.4977C17.8014 16.9704 18.1667 17.7068 18.1667 18.4881V19H5.83333V18.4881C5.83333 17.7068 6.19859 16.9704 6.82063 16.4977L9.07208 14.7866C10.915 13.3859 10.915 10.6141 9.07208 9.21343L6.82063 7.50233C6.19859 7.02958 5.83333 6.29322 5.83333 5.51192V5Z"
      stroke="currentColor"
    />

    <path
      d="M5.66667 2.5H18.3333C19.1618 2.5 19.8333 3.17157 19.8333 4V4.83333H4.16667V4C4.16667 3.17157 4.83824 2.5 5.66667 2.5Z"
      stroke="currentColor"
    />

    <path d="M12 15.527L14.5805 17.3333H9.41952L12 15.527Z" stroke="currentColor" />
  </svg>
);

const NoIcon = () => null;

export const gdprIcons = {
  NotSyncedGdpr,
  ApprovedGdpr,
  PendingApproval,
  NoIcon,
};
