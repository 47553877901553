import { useMutation } from '@tanstack/react-query';
import { appointmentApi } from 'service/api/appointmentApi';
import { createISODate } from 'utils/dateTimeUtils';
import { Appointment } from 'type/advisor';
import { useNotification } from 'utils/notification/NotificationContext';
import { CustomError } from '@dvag/dfs-api-client/error';

export const useCreateAppointment = ({
  householdId,
  personId,
  type,
}: {
  householdId: string;
  personId: string | undefined;
  type: string;
}) => {
  const { showError } = useNotification();
  const { postAppointment } = appointmentApi();
  return useMutation<Appointment, CustomError, Appointment, unknown>({
    mutationFn: async (values: Appointment | undefined) => {
      const startDateTime =
        values?.time && values.date
          ? createISODate({ date: values.date, time: values.time })
          : null;

      const requestBody = {
        householdId,
        personId,
        ...(startDateTime && {
          startDateTime,
        }),
        type,
      };

      const response = await postAppointment(requestBody);
      return response.data;
    },
    onError: (error: CustomError) => {
      showError({ status: error?.status });
    },
  });
};
