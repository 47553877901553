import i18next from 'i18next';

import { dashboardUtil } from 'service/model/dashboardUtil';
import { AnalyseSectionListData } from 'type/sectionList';

const { PERSONAL_DATA, PRIVACY_POLICY, HHB_AND_CONTRACT_OVERVIEW } = dashboardUtil;

export const rightCardSections: AnalyseSectionListData[] = [
  {
    icon: 'ausweis-check',
    id: PERSONAL_DATA,
    path: 'persoenliche-daten',
    title: i18next.t('analyseDashboard.personalInformation.personalData'),
  },
  {
    icon: 'absicherung',
    title: i18next.t('dashboard.bottomList.gdpr'),
    path: 'datenschutzbestimmungen',
    id: PRIVACY_POLICY,
  },
  {
    icon: 'immobilienfinanzierung',
    title: i18next.t('dashboard.bottomList.hhbAndContractOverview'),
    path: 'hhb-und-vertragsuebersicht',
    id: HHB_AND_CONTRACT_OVERVIEW,
  },
];
