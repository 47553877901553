import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import i18next from 'i18next';
import {
  DxAccordion,
  DxAccordionGroup,
  DxButton,
  DxCard,
  DxCardContent,
  DxCardHeader,
  DxContainer,
  DxInfo,
  DxList,
  DxListItem,
  DxMediaQueryValue,
  DxOverflowMenu,
  DxOverflowMenuItem,
  DxPopover,
} from '@dvag/design-system-react';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import { UpdateManyResources } from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';

import { checkIcon } from 'utils/util';
import { usePermission } from 'utils/permission/usePermission';
import { useGetSettingList } from 'hooks/useGetSettingList';
import { SelectedOption } from 'graphql/advisor/generates';
import { useContinuation } from 'utils/useContinuation';
import { useCreateOrUpdateSettings } from 'hooks/useCreateOrUpdateSettings';
import { useGetSettingListCatalog } from 'hooks/useGetSettingListCatalog';
import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';
import { PopoverContent } from '../PopoverContent/PopoverContent';
import { SettingDto, CodeType } from '../../types';

import '../../style.css';

export const VPDigitalSettings = () => {
  const settingList = useGetSettingList();
  const settingListCatalog = useGetSettingListCatalog();
  const createOrUpdateUserSettings = useCreateOrUpdateSettings();

  const { continuation, getCanContinueHandler, onContinuationRequest } = useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(true);

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        id: yup.string().nullable(),
        selectedOptions: yup.array(),
        code: yup.string(),
      }),
    [],
  );

  const { register, setValue, getValues, flushChanges, resources } = useAutosave<SettingDto>({
    data: settingList.data,
    updateManyResources:
      createOrUpdateUserSettings.mutateAsync as unknown as UpdateManyResources<SettingDto>,
    resourceSchema,
    onIsDirtyChange: (isDirtyParam: boolean) => {
      if (!isDirtyParam) {
        getCanContinueHandler();
      }
      onCanContinueChange(!isDirtyParam);
    },
    onIsSubmittingChange: setIsSubmitting,
  });

  const onResetSettings = () => {
    resources.forEach((setting, index) => {
      const catalogSetting = settingListCatalog.data?.find((item) => item.code === setting.code);
      if (catalogSetting?.default) {
        setValue('selectedOptions', catalogSetting.default, index);
      }
    });
  };

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  const permission = usePermission();

  const hasPermission = useCallback(
    (code: CodeType) => {
      if (code === 'QUICK_ACCESS_TOGGLE') return permission.canAccessFavorites;
      if (code === 'WEB_VP_SLIDE') return permission.canAccessShoppingCart;
      return true;
    },
    [permission.canAccessFavorites, permission.canAccessShoppingCart],
  );

  return (
    <>
      <DxCard data-testid="vpdigital-settings-card">
        <DxCardHeader
          slot="header"
          label={i18next.t('settings.VPDigitalSettings')}
          sublabel={i18next.t('settings.VPDigitalSettingsSublabel')}
        >
          <DxMediaQueryValue
            property="contentbelow"
            mq1="true"
            mq2="true"
            mq3="false"
            mq4="false"
            mq5="false"
          />
          <DxContainer color="transparent" className="reset-action-container">
            <DxButton
              data-testid="settings-reset-button"
              onClick={onResetSettings}
              icon={checkIcon('wiedergabe')}
              iconposition="right"
              type="text"
              label={i18next.t('settings.resetToDefault')}
            />
          </DxContainer>
        </DxCardHeader>
        <DxCardContent>
          <DxAccordionGroup>
            <DxAccordion
              label={i18next.t('settings.visibility')}
              open={isCardOpen}
              onOpenChange={(e) => {
                setIsCardOpen(e.detail);
              }}
              id="settings-visibility"
              data-testid="settings-visibility"
            >
              <DxList size="l">
                <DxMediaQueryValue
                  property="contentbelow"
                  mq1="true"
                  mq2="true"
                  mq3="false"
                  mq4="false"
                  mq5="false"
                />
                {resources?.map(
                  (setting, index) =>
                    hasPermission(setting.code) && (
                      <DxListItem
                        data-testid={`${setting.code}-list-item`}
                        id={setting.code}
                        key={setting.code}
                        label={i18next.t(`settings.settingItem.${setting.code}.label`)}
                      >
                        <DxInfo id="info" slot="content">
                          <DxOverflowMenu
                            id={`option-selector-${index}`}
                            data-testid={`option-selector-${index}`}
                            label={i18next.t(
                              `settings.generalOption.${
                                getValues('selectedOptions', index)?.[0] as SelectedOption
                              }`,
                            )}
                            onSelection={(e) => {
                              setValue('selectedOptions', [e.detail.value], index);
                            }}
                            {...register('selectedOptions', index)}
                          >
                            {setting?.options?.map((option) => (
                              <DxOverflowMenuItem
                                id={option}
                                key={option}
                                label={i18next.t(`settings.generalOption.${option}`)}
                                value={option}
                              />
                            ))}
                          </DxOverflowMenu>

                          <DxPopover id="popover">
                            <PopoverContent settingCode={setting.code} />
                          </DxPopover>
                        </DxInfo>
                      </DxListItem>
                    ),
                )}
              </DxList>
            </DxAccordion>
          </DxAccordionGroup>
        </DxCardContent>
      </DxCard>
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </>
  );
};
