import { appNames } from '@dvag/dfs-constant-config/app-names';
import { DxButton, DxContainer, DxText } from '@dvag/design-system-react';

import i18next from 'i18next';

import { checkIcon } from 'utils/util';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { FC } from 'react';
import { getSummaryText } from './searchInformation/getSummaryText';
import { Filter } from './filter/Filter';
import { Separator } from './separator';

import './style.css';

const translation = {
  newHouseHold: i18next.t('dashboard.newHouseHold'),
};

interface ListHeaderProps {
  initialSearchTerm: string;
  isLoading: boolean;
  count: number | undefined;
  handleSearch: (string: string) => void;
  formErrorStatus?: number;
}

export const ListHeader: FC<ListHeaderProps> = ({
  initialSearchTerm,
  isLoading,
  count,
  handleSearch,
  formErrorStatus,
}) => {
  const renderHouseholdsSummary = () =>
    (isLoading || !!count) && (
      <DxContainer color="white" className="summary-text-container">
        <DxText className="summary-text summary-text-header-list">
          {getSummaryText(initialSearchTerm, isLoading, count)}
        </DxText>
      </DxContainer>
    );

  return (
    <>
      <DxContainer color="white" className="flex search-action-container" data-testid="list-header">
        <Filter
          initialSearchTerm={initialSearchTerm}
          onSubmit={handleSearch}
          formErrorStatus={formErrorStatus}
        />

        <DxContainer color="white" className="view-person-container">
          <Separator hiddenOnMobile />
          <DxButton
            data-testid="addHousehold"
            id="addHousehold"
            onClick={() => handleNavigateToPath('/haushalt/hinzufuegen', '', appNames.person)}
            type="primary-m"
            label={translation.newHouseHold}
            icon={checkIcon('plus-kreis')}
            stretch
          />
        </DxContainer>
      </DxContainer>

      {!formErrorStatus &&
        initialSearchTerm &&
        initialSearchTerm.length > 0 &&
        renderHouseholdsSummary()}
    </>
  );
};
