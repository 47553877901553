import { useQuery } from '@tanstack/react-query';
import { kivpApi } from 'service/api/kivpApi';
import { checkException, TError } from 'config/reactQuery';

export const useGetUrlFromModule = (moduleType: string) => {
  const queryKey = ['module-type', moduleType];
  const productService = kivpApi();
  const queryFn = async () => {
    const response = await productService.getUrlFromModule(moduleType);
    return response.data || '';
  };

  return useQuery({
    queryKey,
    queryFn,
    throwOnError: (error: TError) => checkException(error, false, true),
  });
};
