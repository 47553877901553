import { navigateToMicroApp } from '@dvag/dfs-orchestrator-client/navigation';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { Routes } from 'named-urls';
import { buildPath } from './buildPath';

export const handleNavigateToPath = (
  route: string | Routes,
  householdId: string | number | undefined,
  microApp = appNames.advisor,
): void => {
  const path = buildPath(route, { householdId: householdId as string });
  navigateToMicroApp(microApp, path);
};
