import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getToken } from '@dvag/dfs-orchestrator-client';
import { getEnvironmentAppUrls } from '@dvag/dfs-constant-config/app-urls';

import { buildPath } from 'service/buildPath';
import { route } from 'config/route';

import '../../style.css';

export const RecommendationPresentationPage = ({ teamBuilding }: { teamBuilding: boolean }) => {
  const token = getToken();
  const { startEvent, finishEvent } = {
    startEvent: 'fivo-back-to-start',
    finishEvent: 'fivo-presentation-finished',
  };

  type IframeEventsData = 'fivo-back-to-start' | 'fivo-presentation-finished';
  interface IframeEvents {
    data: IframeEventsData;
  }

  const { householdId = '' } = useParams();

  const navigate = useNavigate();

  const handleMessage = useCallback(
    ({ data }: IframeEvents) => {
      if (data === startEvent) {
        navigate(-1);
      }
      if (data === finishEvent) {
        const recommendationPage = buildPath(route.recommendationPage, {
          householdId,
        });
        const teambuildingPage = buildPath(route.teamBuilding, {
          householdId,
        });
        navigate(teamBuilding ? teambuildingPage : recommendationPage);
      }
    },
    [finishEvent, householdId, navigate, startEvent, teamBuilding],
  );

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', handleMessage);
    }
    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', handleMessage);
      }
    };
  }, [handleMessage]);

  const [presentationUrl, setPresentationUrl] = useState('');

  useEffect(() => {
    const { teambuildingPresentation, recommendationPresentation } = getEnvironmentAppUrls();
    const url = teamBuilding ? teambuildingPresentation : recommendationPresentation;
    setPresentationUrl(url);
  }, [teamBuilding, token]);

  return (
    <div className="recommendation-iframe-container">
      <iframe
        className="recommendation-iframe"
        src={`${presentationUrl}?jwt=${token}&noAuthRedirect=true`}
        title="recommendation-iframe"
      />
    </div>
  );
};
