import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useAutosave } from '@dvag/dfs-ui-blocks/hooks/useAutosave';
import {
  DxButton,
  DxContainer,
  DxDateInput,
  DxDropdown,
  DxDropdownOption,
  DxGrid,
  DxProcessHeader,
  DxText,
} from '@dvag/design-system-react';
import { navigate, navigatePrevious } from '@dvag/dfs-orchestrator-client/messengers';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { UnsavedOrPendingModal } from 'component/UnsavedOrPendingModal';
import { useGetPersonIdList } from 'hooks/useGetPersonIdList';
import { useCreateAppointment } from 'hooks/useCreateAppointment';
import { useUpdateAppointment } from 'hooks/useUpdateAppointment';
import { useAppointment } from 'hooks/useAppointment';
import { earlierDataError, futureDateOnly } from 'utils/validation';
import { useContinuation } from 'utils/useContinuation';
import { rangeOfTimes } from 'utils/times';
import { buildPath } from 'service/buildPath';
import { appointmentType } from 'service/model/interviewConclusion';
import { route } from 'config/route';
import { mandatoryFieldMessage } from 'form/util';
import { RecruitingCard } from './RecruitingCard/RecruitingCard';
import { OGCButtons } from '../components/OGCButtons/OGCButtons';
import './style.css';

const translations = {
  title: i18next.t('OGC.interviewConclusion.recruitPage.title'),
  contentHeader: i18next.t('OGC.interviewConclusion.recruitPage.header'),
  inputHeader: i18next.t('OGC.interviewConclusion.recruitPage.inputHeader'),
  dateLabel: i18next.t('OGC.interviewConclusion.recruitPage.dateLabel'),
  timeLabel: i18next.t('OGC.interviewConclusion.recruitPage.timeLabel'),
  dreamJob: i18next.t('OGC.interviewConclusion.recruitPage.dreamJob'),
  presentation: i18next.t('OGC.interviewConclusion.recruitPage.presentation'),
  directAccess: i18next.t('OGC.interviewConclusion.recruitPage.directAccess'),
  back: i18next.t('OGC.buttons.back'),
  next: i18next.t('OGC.buttons.next'),
  flexibilityCard: i18next.t('OGC.interviewConclusion.recruitPage.flexibilityCard'),
  performanceCard: i18next.t('OGC.interviewConclusion.recruitPage.performanceCard'),
  trainingCard: i18next.t('OGC.interviewConclusion.recruitPage.trainingCard'),
  advisorCard: i18next.t('OGC.interviewConclusion.recruitPage.advisorCard'),
  chooseOption: i18next.t('OGC.interviewConclusion.recruitPage.chooseOption'),
};

type RecruitingCardType = {
  icon: IconName;
  text: string;
};

const cards: RecruitingCardType[] = [
  { icon: 'unternehmerische-freiheit', text: translations.flexibilityCard },
  { icon: 'geldschein', text: translations.performanceCard },
  { icon: 'fachliche-ausbildung', text: translations.trainingCard },
  { icon: 'startergespraech', text: translations.advisorCard },
];

const defaultData = {
  id: '',
  date: '',
  time: '',
  householdId: '',
  personId: '',
  status: '',
  type: '',
  startDateTime: '',
};

const times = rangeOfTimes(8, 21, 30);

export const Recruiting = () => {
  const { householdId } = useParams() as { householdId: string };
  const appointment = useAppointment({ householdId, type: appointmentType.career });
  const personId = useGetPersonIdList(householdId).data?.[0];
  const updateAppointmentMutation = useUpdateAppointment({
    householdId,
    personId,
    type: appointmentType.career,
  });
  const createAppointmentMutation = useCreateAppointment({
    householdId,
    personId,
    type: appointmentType.career,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        date: yup
          .string()
          .nullable()
          .test('validateDate', earlierDataError, futureDateOnly)
          .test(
            'validateDateInDependencyOfTime ',
            mandatoryFieldMessage,
            (value, context) => !(!value && !!context.parent.time),
          ),
        time: yup
          .string()
          .nullable()
          .test(
            'validateDateInDependencyOfDate ',
            mandatoryFieldMessage,
            (value, context) => !(!value && !!context.parent.date),
          ),
      }),
    [],
  );

  const { continuation, getContinuationHandler, getCanContinueHandler, onContinuationRequest } =
    useContinuation();

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const { register, flushChanges, setValue, triggerValidation } = useAutosave({
    data: appointment.data,
    defaultData,
    createResource: createAppointmentMutation.mutateAsync,
    updateResource: updateAppointmentMutation.mutateAsync,
    onIsDirtyChange: (isDirtyParam) => onCanContinueChange(!isDirtyParam),
    onIsSubmittingChange: setIsSubmitting,
    resourceSchema,
  });

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  const handelNavigateToTeamBuildingPresentation = () =>
    navigate(
      `/${appNames.advisor}${buildPath(route.teamBuildingPresentation, {
        householdId,
      })}`,
    );
  const handleNavigateToDirectAccess = () =>
    navigate(`/${appNames.advisor}${buildPath(route.teamBuilding, { householdId })}`);
  const handleForward = () =>
    navigate(`/${appNames.advisor}${buildPath(route.analyseDashboard, { householdId })}`);

  return (
    <>
      <DxProcessHeader headline={translations.title} />
      <DxContainer type="page" className="recruit-container">
        <DxContainer className="content-container">
          <DxText type="h5">{translations.contentHeader}</DxText>
          <DxGrid
            id="ogc-recruiting-card-container"
            key="recruiting-card-container"
            className="card-grid-container"
            mq4="3-3-3-3"
            mq3="4-4-4/(4)-4-(4)"
            mq2="6-6/6-6"
            mq1="12/*"
          >
            {cards.map((card) => (
              <RecruitingCard key={`container-${card.icon}`} icon={card.icon} text={card.text} />
            ))}
          </DxGrid>
          <DxContainer className="separator" />
          <DxText type="h5">{translations.inputHeader}</DxText>
          <DxGrid className="input-container" mq1="12/*" mq2="6-6/*">
            <DxDateInput
              disabled={appointment.isFetching}
              id="ogc-recruiting-appointment-date-input"
              data-testid="ogc-recruiting-appointment-date-input"
              label={translations.dateLabel}
              {...register('date')}
              onValueChange={(e) => {
                setValue('date', e.detail);
                if (e.detail === null) {
                  setValue('time', null);
                }
                triggerValidation();
              }}
            />
            <DxDropdown
              disabled={appointment.isFetching}
              id="ogc-recruiting-time-selection-dropdown"
              data-testid="ogc-recruiting-time-selection-dropdown"
              label={translations.timeLabel}
              placeholder={translations.chooseOption}
              {...register('time')}
              onValueChange={(e) => {
                setValue('time', e.detail);
                if (e.detail === null) {
                  setValue('date', null);
                }
                triggerValidation();
              }}
            >
              {times.map((time) => (
                <DxDropdownOption
                  data-testid={`ogc-time-selection-dropdown-option-${time}`}
                  value={time}
                  label={time}
                  key={`key-${time}`}
                />
              ))}
            </DxDropdown>
          </DxGrid>
          <DxContainer className="dream-job-container">
            <DxText type="h5">{translations.dreamJob}</DxText>
            <DxContainer className="presentation-container">
              <DxContainer className="presentation-button">
                <DxButton
                  data-testid="ogc-teambuilding-presentation-button"
                  type="secondary-m"
                  label={translations.presentation}
                  icon="Information"
                  iconposition="right"
                  stretch
                  onClick={getContinuationHandler(handelNavigateToTeamBuildingPresentation)}
                />
              </DxContainer>
              <DxContainer className="presentation-button">
                <DxButton
                  data-testid="ogc-teambuilding-button"
                  className="direct-access-button"
                  type="secondary-m"
                  label={translations.directAccess}
                  icon="Pfeil-rechts"
                  iconposition="right"
                  stretch
                  onClick={getContinuationHandler(handleNavigateToDirectAccess)}
                />
              </DxContainer>
            </DxContainer>
          </DxContainer>
        </DxContainer>
      </DxContainer>
      <DxContainer type="page" className="button-outer-container">
        <OGCButtons
          id="ogc-recruiting-button"
          data-testid="ogc-recruiting-button"
          handleForwardClick={getContinuationHandler(handleForward)}
          handleBackClick={getContinuationHandler(navigatePrevious)}
        />
      </DxContainer>
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </>
  );
};
