import { appNames } from '@dvag/dfs-constant-config/app-names';
import { useMutation } from '@tanstack/react-query';

import { route } from 'config/route';
import { personApi } from 'service/api/personApi';
import { handleNavigateToPath } from 'service/handleNavigateToPath';

export const useViewHousehold = () => {
  const personService = personApi();

  const mutationFn = async (householdId: string) => {
    await personService.updateLastAccessedHouseholdList(householdId);

    const nextUrl = route.householdDashboardId;
    handleNavigateToPath(nextUrl, householdId, appNames.advisor);
  };

  const { isPending, isError, isSuccess, status, mutateAsync } = useMutation({
    mutationFn,
    onSuccess: (_, householdId) => {
      const nextUrl = route.householdDashboardId;
      handleNavigateToPath(nextUrl, householdId, appNames.advisor);
    },
  });

  const viewHousehold = async (householdId: string) => {
    if (!isPending) {
      mutateAsync(householdId);
    }
  };

  return {
    viewHousehold,
    isPending,
    isError,
    isSuccess,
    status,
  };
};
