import { useQuery } from '@tanstack/react-query';
import { getCountryList } from '@dvag/dfs-constant-config/get-country-list';

import { personApi } from 'service/api/personApi';
import { PersonSearchItem } from 'type/person';
import { randomUUID } from 'utils/randomUuid';
import { DataEntity } from '@dvag/dfs-api-client/interface/error';
import { checkException } from 'config/reactQuery';

interface ConfigCountry {
  code: string;
  dialCode: string;
  isDefault?: boolean;
  name: string;
}

const setCountryName = (personList: PersonSearchItem[], countryList: ConfigCountry[]) =>
  personList.map((item) => {
    const newItem = { ...item };
    if (item.address) {
      const countryCode = item.address.country;
      const country = countryList.find((c) => c.code === countryCode);
      if (country) {
        newItem.address = {
          ...item.address,
          countryName: country.name,
        };
      }
    }

    return newItem;
  });

export const useSearchPerson = (searchTerm = '') => {
  const countryList = getCountryList();

  const personService = personApi();
  return useQuery<PersonSearchItem[], DataEntity>({
    queryKey: [`?searchTerm=${searchTerm}`],
    queryFn: async () => {
      if (searchTerm.length === 0) return [];

      const response = await personService.getSearchPerson(searchTerm);

      let list = response.data || [];
      list = setCountryName(list, countryList);

      return randomUUID(list);
    },
    enabled: !!countryList.length,
    retry: false,
    throwOnError: (error) => checkException(error, true),
  });
};
