import React from 'react';

import { DxIcon, DxListItem } from '@dvag/design-system-react';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';

import { checkIcon } from 'utils/util';

import './style.css';

interface ItemProps {
  id?: string;
  title?: string;
  cursorStyle?: string;
  icon: IconName;
  onItemClick: () => void;
  renderRightSection?: () => React.JSX.Element;
}

export const Item = ({
  id,
  icon,
  onItemClick,
  renderRightSection,
  title,
  cursorStyle,
}: ItemProps) => (
  <DxListItem
    style={{ cursor: cursorStyle || 'pointer' }}
    icon={checkIcon(icon)}
    onClick={onItemClick}
    data-testid={id}
    key={id}
    id={id}
    label={title}
  >
    <div slot="content" className="dashboard-icon-container">
      {renderRightSection ? (
        renderRightSection()
      ) : (
        <DxIcon color="default" icon={checkIcon('chevron-rechts')} size={16} className="chevron" />
      )}
    </div>
  </DxListItem>
);
