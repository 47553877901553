import { constant } from './constant';

const local = 'advisoryDashboard';

export const advisoryDashboard = {
  beratungThumbnail: `${constant.route}${local}/beratung.jpeg`,
  futureFinancialSituationThumbnail: `${constant.route}${local}/financialHappiness.jpeg`,
  futureHHBThumbnail: `${constant.route}${local}/futureHHB.png`,
  recapThumbnail: `${constant.route}${local}/recap.jpeg`,
};
