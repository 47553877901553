import i18next from 'i18next';
import { getContext } from '@dvag/dfs-orchestrator-client';

import { CooperationSectionListData } from 'type/sectionList';
import { dashboardUtil } from 'service/model/dashboardUtil';

const translationContext = {
  clientsAcquireClients: i18next.t('dashboard.cooperationList.clientsAcquireClients'),
  dreamJob: i18next.t('dashboard.cooperationList.dreamJob'),
  directAccess: i18next.t('dashboard.cooperationList.directAccess'),
  presentation: i18next.t('dashboard.cooperationList.presentation'),
};
export const useUtil = ({
  recommendationPermission,
  teambuildingPermission,
  dashboardPath,
}: {
  recommendationPermission: boolean;
  teambuildingPermission: boolean;
  dashboardPath: string;
}) => {
  const cooperationList: CooperationSectionListData[] = [];
  if (recommendationPermission) {
    cooperationList.push({
      icon: 'daumen-hoch',
      title: translationContext.clientsAcquireClients,
      path: {
        route: `/haushalt/:householdId/${dashboardPath}/sektion/kundenwerbenkunden`,
        microApp: dashboardUtil.ADVISOR,
      },
      id: 'kunden-werben-kunden',
    });
  }

  if (teambuildingPermission) {
    cooperationList.push({
      icon: 'familienfreigabe',
      title: translationContext.dreamJob,
      path: {
        route: `/haushalt/:householdId/${dashboardPath}/sektion/berufmitzukunft`,
        microApp: dashboardUtil.ADVISOR,
      },
      id: 'teamaufbau',
    });
  }

  const recommendationList: CooperationSectionListData[] = [
    {
      icon: 'slideshow',
      title: translationContext.presentation,
      path: {
        route: '/haushalt/:householdId/empfehlungprasentation',
        microApp: dashboardUtil.ADVISOR,
      },
      id: 'presentation',
    },
  ];

  const { user } = getContext();
  if (user?.advisor?.urlList.homepage) {
    recommendationList.push({
      icon: 'vorspulen',
      title: translationContext.directAccess,
      path: { route: '/haushalt/:householdId/weiterempfehlung', microApp: dashboardUtil.ADVISOR },
      id: 'direkteinstieg',
    });
  }

  const teambuildingList: CooperationSectionListData[] = [
    {
      icon: 'slideshow',
      title: translationContext.presentation,
      path: {
        route: '/haushalt/:householdId/teamaufbauprasentation',
        microApp: dashboardUtil.ADVISOR,
      },
      id: 'presentation',
    },
    {
      icon: 'vorspulen',
      title: translationContext.directAccess,
      path: { route: '/haushalt/:householdId/teamaufbau', microApp: dashboardUtil.ADVISOR },
      id: 'direkteinstieg',
    },
  ];

  return {
    cooperationList,
    recommendationList,
    teambuildingList,
  };
};
