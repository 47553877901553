import i18next from 'i18next';
import { Impression } from '../../type/onGoodCooperation';

export const appointmentType = {
  career: 'CAREER',
  financial: 'FINANCIAL',
};

export const interviewStepNames = {
  preImpression: 'preImpression',
  postImpression: 'postImpression',
  postImpressionComment: 'postImpressionComment',
  futureWishes: 'futureWishes',
  recommendedInterestedParties: 'recommendedInterestedParties',
};

export const preImpression: Impression[] = [
  {
    id: 'skeptikal',
    label: i18next.t('OGC.interviewConclusion.evaluateBefore.skeptical'),
    icon: 'skeptisch',
    value: 'SKEPTIKAL',
  },
  {
    id: 'neutral',
    label: i18next.t('OGC.interviewConclusion.evaluateBefore.neutral'),
    icon: 'neutral',
    value: 'NEUTRAL',
  },
  {
    id: 'curious',
    label: i18next.t('OGC.interviewConclusion.evaluateBefore.curious'),
    icon: 'neugierig',
    value: 'CURIOUS',
  },
];

export const postImpression: Impression[] = [
  {
    id: 'as-before',
    label: i18next.t('OGC.interviewConclusion.evaluateAfter.asBefore'),
    icon: 'neutral',
    value: 'NEUTRAL',
  },
  {
    id: 'surprised',
    label: i18next.t('OGC.interviewConclusion.evaluateAfter.surprised'),
    icon: 'lachen',
    value: 'POSITIVE',
  },
  {
    id: 'impressed',
    label: i18next.t('OGC.interviewConclusion.evaluateAfter.impressed'),
    icon: 'zufrieden',
    value: 'EXCITED',
  },
];
