import React from 'react';

import {
  DxCard,
  DxCardContent,
  DxCardImage,
  DxContainer,
  DxIcon,
  DxSpacer,
  DxText,
} from '@dvag/design-system-react';

import i18next from 'i18next';

import { checkIcon } from 'utils/util';
import './style.css';

interface TopCardProps {
  title: string;
  tag: string;
  description: string;
  image: string;
  onClick: () => void;
  coverPosition: string;
}

export const TopCard = ({
  tag,
  title,
  description,
  image,
  onClick,
  coverPosition,
}: TopCardProps) => {
  const locale = i18next.getFixedT('de');
  const companyPresentation = locale('analyseDashboard.top.companyPresentation.header');

  const getDisplayValue = (value: string) => {
    const firstHalf = value.slice(0, 12);
    const secondHalf = value.slice(12);
    const hyphenedTitle = (
      <>
        {firstHalf}&shy;{secondHalf}
      </>
    );
    return value === companyPresentation ? hyphenedTitle : value;
  };

  return (
    <DxCard data-test-id={tag} data-testid={tag} onClick={onClick} className="pointer">
      <DxCardImage
        url={image}
        fit="cover"
        coverheightmq1="120px"
        coverpositionmq3={coverPosition}
        style={{ width: '100%' }}
      />
      <DxCardContent>
        <DxText type="ps" color="headline" style={{ hyphens: 'manual' }}>
          {getDisplayValue(title)}
        </DxText>
        <DxSpacer size="16v" />
        <DxContainer color="white" className="card-description-container">
          <DxContainer color="white">
            <DxText type="it">{description}</DxText>
          </DxContainer>
          <DxContainer color="white">
            <DxIcon
              color="default"
              size={24}
              icon={checkIcon('chevron-rechts')}
              className="no-margin"
            />
          </DxContainer>
        </DxContainer>
      </DxCardContent>
    </DxCard>
  );
};
