import React from 'react';

import { DxTextInput } from '@dvag/design-system-react';
import { checkIcon } from 'utils/util';

interface SearchInputProps {
  id: string;
  onChange: (value: string) => void;
  value: string | undefined;
  placeholder: string;
  handleSubmit: () => void;
  errorMessage: string;
}

export const SearchInput = ({
  id,
  onChange,
  value,
  placeholder,
  handleSubmit,
  errorMessage,
}: SearchInputProps) => (
  <DxTextInput
    size="m"
    id={id}
    onValueChange={(event) => onChange(event.target.value)}
    value={value}
    placeholder={placeholder}
    icon={checkIcon('lupe')}
    onEnterPress={handleSubmit}
    errormessage={errorMessage}
    data-testid="hhw-text-input"
  />
);
