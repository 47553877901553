import { getContext } from '@dvag/dfs-orchestrator-client';
import { DxProcessHeader, DxText } from '@dvag/design-system-react';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';

import '../../style.css';

const { aussendienst, assistent, innendienst } = {
  aussendienst: 'AUSSENDIENST',
  innendienst: 'INNENDIENST',
  assistent: 'ASSISTENT',
};

export const DashboardHeader = () => {
  const { householdId } = useParams<{ householdId: string }>();
  const userContext = getContext().user;

  const { firstName, lastName } = userContext.advisor;

  const isAssistant =
    !!userContext?.parentUserId &&
    [aussendienst, assistent, innendienst].includes(userContext?.role);

  const advisorName = `${firstName} ${lastName}`;

  const name = isAssistant
    ? `${userContext?.assistant.firstName} ${userContext?.assistant.lastName}`
    : advisorName;

  const headerLabel = householdId
    ? i18next.t('dashboard.clientWelcome')
    : `${i18next.t('dashboard.welcome')} ${name}`;
  return (
    <DxProcessHeader headline={headerLabel}>
      {isAssistant && (
        <div className="flex" slot="note" data-testid="dashboard-header">
          <DxText type="ps">{i18next.t('dashboard.behalf')}</DxText>
          <DxText type="ps-bold" className="behalf-name">
            {advisorName}
          </DxText>
        </div>
      )}
    </DxProcessHeader>
  );
};
