export const rangeOfTimes = (start: number, finish: number, interval: number): string[] => {
  finish += 1;
  const times: string[] = [];
  const hourThreshold = 10;
  const minutesInHour = 60;
  const hours = Array.from({ length: finish - start }, (_, index) => {
    const hour = start + index;
    return hour < hourThreshold ? `0${hour}` : hour;
  });
  hours.forEach((hour, index) => {
    if (index < hours.length - 1) {
      for (let min = 0; min < minutesInHour; min += interval) {
        const minute = min.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        times.push(`${hour}:${minute}`);
      }
    } else {
      times.push(`${hour}:00`);
    }
  });
  return times;
};
