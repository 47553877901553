import { getGraphQLClient } from 'graphql/graphQLClient';
import { SettingMutationTransactionInputDto, useSettingsMutation } from 'graphql/advisor/generates';
import { useNotification } from 'utils/notification/NotificationContext';

export const useCreateOrUpdateSettings = () => {
  const { showError } = useNotification();

  const settingMutation = useSettingsMutation(getGraphQLClient('advisor'), {
    onError: () => {
      showError();
    },
    onSuccess: (data) => {
      const failedTransaction = data.setting.transactionList.find(
        (transaction) => transaction.errorList?.length,
      );
      if (failedTransaction) {
        showError({ description: failedTransaction.errorList?.[0].message });
      }
    },
  });

  return {
    ...settingMutation,
    mutateAsync: (transactionList: SettingMutationTransactionInputDto[]) =>
      settingMutation
        .mutateAsync({
          settingsInput: {
            transactionList: transactionList.map((transactionItem) => ({
              operation: transactionItem.operation,
              data: {
                code: transactionItem.data.code,
                selectedOptions: transactionItem.data.selectedOptions,
              },
            })),
          },
        })
        .then((output) => output.setting.transactionList),
  };
};
