import { useQuery } from '@tanstack/react-query';
import { appointmentApi } from 'service/api/appointmentApi';
import { useGetPersonIdList } from './useGetPersonIdList';
import { extractDateAndTime } from '../utils/dateTimeUtils';
import { Appointment } from '../type/advisor';

interface PropTypes {
  householdId: string;
  type: string;
}

export const useAppointment = ({ householdId, type }: PropTypes) => {
  const appointmentService = appointmentApi();
  const personId = useGetPersonIdList(householdId).data?.[0];

  return useQuery<Appointment>({
    queryKey: [`appointment-${type}-${householdId}`],
    queryFn: async () => {
      const appointment = await appointmentService.getAppointment({
        householdId,
        type,
      });

      if (appointment.data.length) {
        const res = appointment.data[0];
        return { ...res, ...extractDateAndTime(res.startDateTime) };
      }

      const newAppointment = await appointmentService.postAppointment({
        householdId,
        personId,
        type,
      });

      return newAppointment.data;
    },
    enabled: !!householdId && !!personId,
  });
};
