import { useGetPersonIdListQuery } from 'graphql/person/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';

export const useGetPersonIdList = (householdId?: string) =>
  useGetPersonIdListQuery(
    getGraphQLClient(),
    {
      householdId: householdId || '',
    },
    {
      select: (personInHouseholdList) =>
        personInHouseholdList.household.basicData?.personInHouseholdList?.map(({ id }) => id),
      retry: false,
      enabled: !!householdId,
    },
  );
