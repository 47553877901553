import React from 'react';

import { DxContainer, DxSpacer } from '@dvag/design-system-react';

import { checkIsMobile } from 'utils/windowSize';

import './style.css';

type PropType = {
  hiddenOnMobile?: boolean;
};

export const Separator = ({ hiddenOnMobile }: PropType) => {
  const isMobile = checkIsMobile(759);
  const breakpoint = isMobile ? '24v' : '24h';
  const separatorClassName = isMobile ? 'horizontal-separator' : 'vertical-separator';

  if (hiddenOnMobile && isMobile) {
    return <DxSpacer size="24v" data-testid="separator-mobile" />;
  }

  return (
    <DxContainer color="white" className="separator-dashboard-container" data-testid="separator">
      <DxSpacer size={breakpoint} />
      <div className={separatorClassName} />
      <DxSpacer size={breakpoint} />
    </DxContainer>
  );
};
