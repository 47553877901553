import React, { ReactNode } from 'react';

interface AccordionItemProps {
  isOpen: boolean;
  content: ReactNode;
}

export const AccordionItem = ({ content, isOpen }: AccordionItemProps) => (
  <div>{isOpen && content}</div>
);
