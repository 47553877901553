import React, { useState } from 'react';

import { DxListItem } from '@dvag/design-system-react';

import { PersonSearchItem } from 'type/person';
import { getPersonFullName } from 'utils/util';

interface MatchedPersonTypes {
  person: PersonSearchItem;
  onClick: () => void;
}

export const MatchedPerson = ({ person, onClick }: MatchedPersonTypes) => {
  const [isPersonClicked, setIsPersonClicked] = useState(false);

  const oneTimeClick = () => {
    if (!isPersonClicked) {
      onClick();
      setIsPersonClicked(true);
    }
  };

  const householdSublabel = () => {
    if (!person?.address) return '';

    const { countryName, street, zipCode, city } = person.address;

    const addressLabel = [];
    if (street) addressLabel.push(street);
    if (zipCode) addressLabel.push(zipCode);
    if (city) addressLabel.push(city);
    if (countryName) addressLabel.push(countryName);

    return addressLabel.join(', ');
  };

  return (
    <DxListItem
      id={person.id}
      label={getPersonFullName(person)}
      sublabel={householdSublabel()}
      actionindicator="navigate"
      data-testid="select-person"
      className="name-wr small-layout-item"
      onClick={oneTimeClick}
    />
  );
};
