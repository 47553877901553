import React from 'react';
import { DxCard, DxCardContent } from '@dvag/design-system-react';

import i18next from 'i18next';

import { NoResult } from './NoResult';

interface ErrorPropTypes {
  errorStatus?: number;
  searchTerm?: string;
}

const getErrorMessage = ({ errorStatus, searchTerm }: ErrorPropTypes) => {
  if (errorStatus === 416) return i18next.t('dashboard.search.requestError');
  return searchTerm
    ? i18next.t('dashboard.search.empty.message', { name: searchTerm })
    : i18next.t('dashboard.recent.empty.message');
};

export const ErrorLoadingList = ({ searchTerm, errorStatus }: ErrorPropTypes) => {
  const errorMessage = getErrorMessage({ errorStatus, searchTerm });

  return (
    <DxCard className="error-loading-card" data-testid="error-loading-list-container">
      <DxCardContent className="error-loading-card-content">
        <NoResult id="error-loading-list" message={errorMessage} />
      </DxCardContent>
    </DxCard>
  );
};
