import { DxList } from '@dvag/design-system-react';

import { HouseholdInterface, SelectedHousehold } from 'type/household';
import { useViewHousehold } from '../hooks/useViewHousehold';

import { Household } from './household/Household';
import { Loading } from './Loading';

import './style.css';

interface HouseholdListProps {
  households: HouseholdInterface[];
  isLoading: boolean;
  setSelectedHouseHold: (data: SelectedHousehold) => void;
}

export const HouseholdList = ({
  households,
  isLoading,
  setSelectedHouseHold,
}: HouseholdListProps) => {
  const { viewHousehold } = useViewHousehold();

  if (isLoading) return <Loading />;

  return households && households.length ? (
    <DxList size="l" data-testid="household-list-container">
      {households.map((household) => {
        const { personList, address, householdId } = household;
        return (
          <div className="pointer" key={householdId}>
            <Household
              data-testid={`view-household-${householdId}`}
              personList={personList}
              onClick={() => {
                setSelectedHouseHold({ householdId, personList });
                viewHousehold(householdId);
              }}
              address={address}
            />
          </div>
        );
      })}
    </DxList>
  ) : null;
};
