import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import pagesResourcesDe from '@dvag/dfs-constant-config/translation/de';
import pagesResourcesEn from '@dvag/dfs-constant-config/translation/en';

import en from './en.json';
import de from './de.json';

export const locales = {
  german: 'de',
  english: 'en',
};

export const resources = {
  en: {
    advisor: en,
    pages: pagesResourcesEn,
  },
  de: {
    advisor: de,
    pages: pagesResourcesDe,
  },
} as const;

export const defaultNS = 'advisor';

export const defaultLocale = locales.german;

export const getLocale = (): string => i18n.language;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['advisor', 'pages'],
    resources,
    defaultNS,
    detection: {
      order: ['navigator'],
    },
    fallbackLng: defaultLocale,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;
