import { QueryClient } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';

export interface TError extends Error {
  response: {
    status: number;
  };
  config: {
    url: string;
  };
  cause: number;
  status: number;
}

const maxFailureCount = 5;

export const checkException = (
  error: TError | unknown,
  ignoreNotFound = false,
  ignoreForbidden = false,
) => {
  const statusesToCheck = [
    HttpStatusCode.Unauthorized,
    HttpStatusCode.BadRequest,
    ...(ignoreNotFound ? [] : [HttpStatusCode.NotFound]),
    ...(ignoreForbidden ? [] : [HttpStatusCode.Forbidden]),
  ];

  return statusesToCheck.includes((error as TError).response?.status || (error as TError).status);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: (failureCount, error) =>
        failureCount <= maxFailureCount &&
        ![HttpStatusCode.Forbidden, HttpStatusCode.Unauthorized, HttpStatusCode.NotFound].includes(
          (error as TError).response?.status || (error as TError).status,
        ),
      throwOnError: (error) => checkException(error),
    },
    mutations: {
      throwOnError: (error) => checkException(error),
    },
  },
});
