import React from 'react';
import { useParams } from 'react-router-dom';

import {
  DxButton,
  DxContainer,
  DxGrid,
  DxProcessHeader,
  DxSpacer,
} from '@dvag/design-system-react';
import { appNames } from '@dvag/dfs-constant-config/app-names';
import { navigate } from '@dvag/dfs-orchestrator-client/messengers';
import i18next from 'i18next';

import { TopCard } from 'component/TopCard/TopCard';
import { LeftCard } from 'component/BottomCards/leftCard/LeftCard';
import { RightCard } from 'component/BottomCards/rightCard/RightCard';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { useGetHouseholdDashboard } from 'hooks/useGetHouseholdDashboard';
import { AnalyseTopPagesData } from 'type/sectionList';
import { topSectionPageList } from './topPages.config';
import '../AdvisoryDashboard/style.css';

export const AnalyseDashboard = () => {
  const { householdId } = useParams();

  const householdDashboard = useGetHouseholdDashboard();

  const goToPage = (page: AnalyseTopPagesData) => {
    if (!householdId) return;
    if (page.nextUrl) {
      navigate(page.getPath(householdId), page.nextUrl(householdId));
    } else {
      navigate(page.getPath(householdId));
    }
  };

  const navigateToInterviewConclusion = () => {
    handleNavigateToPath(
      '/haushalt/:householdId/analyse/terminabschluss/fuenf-schritte',
      householdId,
      appNames.vpd,
    );
  };

  if (householdDashboard.isLoading) return null;
  return (
    <>
      <DxProcessHeader headline={i18next.t('analyseDashboard.headline')} />
      <DxSpacer size="8v" />
      <DxContainer type="page" color="background">
        <DxGrid mq1="12/12/12/12" rowgap="24v">
          <DxGrid mq1="12/*" mq2="12/*" mq3="4-4-4">
            {topSectionPageList.map((item) => (
              <TopCard
                tag={item.id}
                key={item.id}
                title={item.displayValue}
                description={item.description}
                image={item.image}
                onClick={() => goToPage(item)}
                coverPosition={item.coverPosition}
              />
            ))}
          </DxGrid>

          <DxGrid mq1="12/*" mq3="6-6">
            <LeftCard />
            <RightCard />
          </DxGrid>

          <DxContainer color="background" className="advisor-bottom-button-container">
            <DxButton
              data-testid="button-to-interview-conclusion"
              onClick={navigateToInterviewConclusion}
              type="secondary-m"
              label={i18next.t('analyseDashboard.basicData.buttonAppointment')}
            />
          </DxContainer>
          <DxSpacer size="8v" />
        </DxGrid>
      </DxContainer>
    </>
  );
};
