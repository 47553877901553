import { useOrchestratorContext } from '../orchestrator';

interface UseRouteAuthenticationType {
  orchestratorIsInitialized: boolean;
  profile?: {
    [key: string]: string | number | boolean;
  };
}

export const useRouteAuthentication = (): UseRouteAuthenticationType => {
  const [orchestratorIsInitialized, orchestratorContext] = useOrchestratorContext();
  const { user } = orchestratorContext;

  return {
    orchestratorIsInitialized,
    profile: user as { [key: string]: string | number | boolean },
  };
};
