import { useGetConsentModalDataQuery } from 'graphql/person/generates';
import { getGraphQLClient } from 'graphql/graphQLClient';

export const getConsentDataQueryKey = (personId: string) => ['getConsentModalData', { personId }];

export const useGetConsentModalData = (personId: string, householdId: string) =>
  useGetConsentModalDataQuery(
    getGraphQLClient(),
    { personId, householdId },
    {
      enabled: !!personId,
      select: (response) => response,
    },
  );
