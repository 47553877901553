export const extractDateAndTime = (date: string | null | undefined) => {
  if (date) {
    const [dateValue] = date.split('T');
    const newDate = new Date(date);
    const hours: number = newDate.getHours();
    const minutes = newDate.getMinutes();
    const minutesThreshold = 9;
    const formattedHours = hours > minutesThreshold ? hours : `0${hours}`;
    const formattedMinutes = minutes > minutesThreshold ? minutes : `0${minutes}`;
    return {
      date: dateValue,
      time: `${formattedHours}:${formattedMinutes}`,
    };
  }

  return {};
};

export const createISODate = ({ date, time }: { date: string; time: string }) => {
  const [hours, minutes] = time.split(':');
  const isoDate = new Date(date);
  isoDate.setHours(hours as unknown as number);
  isoDate.setMinutes(minutes as unknown as number);
  return isoDate.toISOString();
};
