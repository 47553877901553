import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DxContainer, DxIcon, DxList, DxListItem } from '@dvag/design-system-react';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';

import { checkIcon } from 'utils/util';

import './style.css';

type LinkType = {
  title: string;
  icon: IconName;
  onClick: () => void;
  display?: boolean;
  isDisabled?: boolean;
};

type ItemType = {
  title: string;
  subtitle: string;
  chevronIcon: IconName;
  image: string;
  onClick: (householdId: string | undefined) => void;
  id: string;
  display: boolean | undefined;
  linkList: LinkType[] | undefined;
  hasHousehold: boolean | undefined;
  disabledClassName: string;
};

export const Item = ({
  title,
  subtitle,
  chevronIcon,
  image,
  onClick,
  id,
  display,
  linkList,
  hasHousehold,
  disabledClassName,
}: ItemType) => {
  const { householdId } = useParams();
  const [isOpen, setToggle] = useState(false);

  useEffect(() => {
    if (!hasHousehold) {
      setToggle(false);
    }
  }, [hasHousehold]);

  return (
    <>
      <DxContainer
        color="white"
        style={{
          display: display ? '' : 'none',
          zIndex: 1,
        }}
        className={`${disabledClassName} flex pointer`}
        onClick={() => {
          if (disabledClassName) return;
          if (linkList) {
            setToggle((prev) => !prev);
          } else {
            onClick(householdId);
          }
        }}
        data-testid={id}
      >
        <DxContainer
          color="transparent"
          className="item-image"
          style={{
            background: `url(${image})`,
            backgroundSize: 'cover',
          }}
        />
        <DxListItem id={id} label={title} sublabel={subtitle} className="full-width">
          <DxContainer
            color="white"
            className="flex align-center upper-icon-container"
            slot="content"
          >
            <DxIcon
              icon={checkIcon(
                chevronIcon === 'chevron-unten' && isOpen ? 'chevron-hoch' : chevronIcon,
              )}
              size={16}
              color="default"
              className="no-margin"
            />
          </DxContainer>
        </DxListItem>
      </DxContainer>

      {linkList && isOpen && (
        <DxList size="l" className="link-list-border-bottom">
          {linkList?.map(
            ({
              title: linkTitle,
              icon,
              onClick: onSubItemClick,
              display: displayLink,
              isDisabled,
            }) =>
              displayLink && (
                <DxListItem
                  disabled={isDisabled}
                  data-testid={linkTitle}
                  id={linkTitle}
                  key={linkTitle}
                  label={linkTitle}
                  className={`document-card pointer ${isDisabled && 'disabled-card'}`}
                  icon={checkIcon(icon)}
                  onClick={onSubItemClick}
                  actionindicator="navigate"
                />
              ),
          )}
        </DxList>
      )}
    </>
  );
};
