import React from 'react';
import {
  DxButton,
  DxCard,
  DxCardContent,
  DxCardHeader,
  DxContainer,
  DxGrid,
  DxList,
  DxProcessHeader,
  DxSpacer,
} from '@dvag/design-system-react';
import i18next from 'i18next';

import { TopCard } from 'component/TopCard/TopCard';
import { Item } from 'component/BottomCards/Card/Item';
import { useScreenData } from './utils/useScreenData';

import { CardListContainer } from '../HouseholdDashboard/component/CardListContainer';
import { CooperationCard } from '../HouseholdDashboard/component/CooperationCard/CooperationCard';

import './style.css';

export const AdvisoryDashboard = () => {
  const { upperList, bottomList, buttonAppointment } = useScreenData();
  let size;
  const columnAmount = upperList.filter((item) => item.show);
  if (columnAmount.length === 3) {
    size = { mq1: '12/*', mq2: '12/*', mq3: '4-4-4' };
  } else {
    size = { mq1: '12/*', mq2: '6-6/12', mq3: '6-6' };
  }

  return (
    <>
      <DxProcessHeader
        headline={i18next.t('advisoryDashboard.headline')}
        data-testid="favorites-headline"
      />
      <DxSpacer size="8v" />
      <DxContainer type="page" color="transparent">
        <DxGrid mq1="12/12/12/12" rowgap="24v">
          <DxGrid mq1={size.mq1} mq2={size.mq2} mq3={size.mq3}>
            {upperList.map(
              (item) =>
                item.show && (
                  <TopCard
                    tag={item.id}
                    key={item.id}
                    title={item.title}
                    description={item.subtitle}
                    image={item.img}
                    onClick={item.onClick}
                    coverPosition={item.coverPosition}
                  />
                ),
            )}
          </DxGrid>

          <CardListContainer>
            <DxCard>
              <DxCardHeader slot="header" label={i18next.t('advisoryDashboard.bancassurance')} />
              <DxCardContent>
                <DxList size="m">
                  {bottomList.map(
                    ({ icon, id, onClick, title, show }) =>
                      show && (
                        <Item id={id} key={id} onItemClick={onClick} title={title} icon={icon} />
                      ),
                  )}
                </DxList>
              </DxCardContent>
            </DxCard>
            <CooperationCard dashboardPath="beratung" />
          </CardListContainer>
          <DxContainer className="advisor-bottom-button-container" color="transparent">
            <DxButton
              type="secondary-m"
              data-testid={buttonAppointment.id}
              id={buttonAppointment.id}
              onClick={buttonAppointment.onClick}
              label={buttonAppointment.label}
            />
          </DxContainer>
          <DxSpacer size="8v" />
        </DxGrid>
      </DxContainer>
    </>
  );
};
