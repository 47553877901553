import i18next from 'i18next';

export const getSummaryText = (name: string, isLoading: boolean, count: number | undefined) => {
  if (isLoading && name) {
    return i18next.t('dashboard.search.loading');
  }
  if (name && !+name) {
    return i18next.t('dashboard.search.information', { count, name });
  }
  return i18next.t('dashboard.search.latestHouseholds');
};
