import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AddressDto = {
  __typename?: 'AddressDto';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

export type AdvisorDto = {
  __typename?: 'AdvisorDto';
  address: AddressDto;
  birthday: Scalars['String']['output'];
  communicationChannel: CommunicationChannelDto;
  entryDate: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hasHomepage: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
  practiceStage: Scalars['Float']['output'];
  salutation: Salutation;
  structuralLevel: Scalars['Float']['output'];
  tenant: TenantDto;
  urlList: UrlListDto;
};

export type AdvisorSchema = {
  __typename?: 'AdvisorSchema';
  advisor?: Maybe<AdvisorDto>;
  assistant: AssistantDto;
  etag?: Maybe<Scalars['String']['output']>;
  favorites: Array<FavoriteDto>;
  permission: PermissionValueDto;
  permissionList: Array<Scalars['String']['output']>;
  settings: Array<SettingDto>;
  userId: Scalars['String']['output'];
  userInformation: UserDto;
};


export type AdvisorSchemaAssistantArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type AdvisorSchemaPermissionArgs = {
  permissionName: Scalars['String']['input'];
};


export type AdvisorSchemaPermissionListArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AssistantDto = {
  __typename?: 'AssistantDto';
  advisorList: Array<Maybe<UserDto>>;
};

export type CommunicationChannelDto = {
  __typename?: 'CommunicationChannelDto';
  email: Scalars['String']['output'];
  fax?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  telephone: Scalars['String']['output'];
};

export type CustomError = {
  __typename?: 'CustomError';
  code: Scalars['Float']['output'];
  message: Scalars['String']['output'];
};

export type DeleteMutationTransactionOutputDto = {
  __typename?: 'DeleteMutationTransactionOutputDto';
  errorList?: Maybe<Array<CustomError>>;
  /** DELETE */
  operation: Operation;
};

export type FavoriteDto = {
  __typename?: 'FavoriteDto';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  orderPreviousPage?: Maybe<Scalars['String']['output']>;
};

export type FavoriteMutationInputDto = {
  /** Required for: CREATE, UPDATE, DELETE */
  transactionList: Array<FavoriteMutationTransactionInputDto>;
};

export type FavoriteMutationOutputDto = {
  __typename?: 'FavoriteMutationOutputDto';
  etag: Scalars['String']['output'];
  transactionList: Array<FavoriteMutationTransactionOutputUnion>;
};

export type FavoriteMutationTransactionDataInputDto = {
  /** Required for: CREATE & UPDATE & DELETE */
  code: Scalars['String']['input'];
  /** Required for: UPDATE & DELETE */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Required for: CREATE & UPDATE */
  orderPreviousPage?: InputMaybe<Scalars['String']['input']>;
};

export type FavoriteMutationTransactionInputDto = {
  data: FavoriteMutationTransactionDataInputDto;
  operation: Operation;
};

export type FavoriteMutationTransactionOutputUnion = DeleteMutationTransactionOutputDto | UpsertFavoriteMutationTransactionOutputDto;

export type Mutation = {
  __typename?: 'Mutation';
  favorite: FavoriteMutationOutputDto;
  setting: SettingMutationOutputDto;
};


export type MutationFavoriteArgs = {
  favoriteInput: FavoriteMutationInputDto;
};


export type MutationSettingArgs = {
  settingInput: SettingMutationInputDto;
};

export enum Operation {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type PermissionValueDto = {
  __typename?: 'PermissionValueDto';
  hasPermission: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  user: AdvisorSchema;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Salutation {
  Frau = 'Frau',
  Herr = 'Herr'
}

export enum SelectedOption {
  AdvisoryModules = 'ADVISORY_MODULES',
  Max = 'MAX',
  Min = 'MIN',
  Off = 'OFF',
  On = 'ON',
  Pyramid = 'PYRAMID'
}

export type SettingDto = {
  __typename?: 'SettingDto';
  code: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isAllowedMultiple?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<SelectedOption>>;
  selectedOptions: Array<SelectedOption>;
};

export type SettingMutationInputDto = {
  /** Required for: CREATE & UPDATE */
  transactionList: Array<SettingMutationTransactionInputDto>;
};

export type SettingMutationOutputDto = {
  __typename?: 'SettingMutationOutputDto';
  transactionList: Array<UpsertSettingMutationTransactionOutputDto>;
};

export type SettingMutationTransactionDataInputDto = {
  /** Required for: CREATE & UPDATE */
  code: Scalars['String']['input'];
  /** Required for: CREATE & UPDATE */
  selectedOptions: Array<SelectedOption>;
};

export type SettingMutationTransactionInputDto = {
  data: SettingMutationTransactionDataInputDto;
  operation: Operation;
};

export type TenantDto = {
  __typename?: 'TenantDto';
  id?: Maybe<Scalars['String']['output']>;
  name: TenantName;
};

export enum TenantName {
  Allfinanz = 'ALLFINANZ',
  AllfinanzAg = 'ALLFINANZ_AG',
  Dvag = 'DVAG',
  DvagWien = 'DVAG_WIEN',
  Unknown = 'UNKNOWN'
}

export type UpsertFavoriteMutationTransactionOutputDto = {
  __typename?: 'UpsertFavoriteMutationTransactionOutputDto';
  data?: Maybe<FavoriteDto>;
  errorList?: Maybe<Array<CustomError>>;
  /** CREATE or UPDATE */
  operation: Operation;
};

export type UpsertSettingMutationTransactionOutputDto = {
  __typename?: 'UpsertSettingMutationTransactionOutputDto';
  data?: Maybe<SettingDto>;
  errorList?: Maybe<Array<CustomError>>;
  /** CREATE or UPDATE */
  operation: Operation;
};

export type UrlListDto = {
  __typename?: 'UrlListDto';
  dreamJob?: Maybe<Scalars['String']['output']>;
  dreamJobWhatsapp: Scalars['String']['output'];
  homepage?: Maybe<Scalars['String']['output']>;
  teamBuilding: Scalars['String']['output'];
};

export type UserDto = {
  __typename?: 'UserDto';
  firstName?: Maybe<Scalars['String']['output']>;
  hasLoginPermission?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  salutation?: Maybe<Salutation>;
  supervisorId?: Maybe<Scalars['String']['output']>;
  tenant: TenantDto;
  type?: Maybe<UserType>;
};

export enum UserType {
  Assistent = 'ASSISTENT',
  Aussendienst = 'AUSSENDIENST',
  Autotest = 'AUTOTEST',
  Hotline = 'HOTLINE',
  Innendienst = 'INNENDIENST',
  KonzernMitarbeiter = 'KONZERN_MITARBEITER',
  Mitbenutzer = 'MITBENUTZER'
}

export type GetUserFavoritesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserFavoritesQuery = { __typename?: 'Query', user: { __typename?: 'AdvisorSchema', favorites: Array<{ __typename?: 'FavoriteDto', id: string, code: string, orderPreviousPage?: string | null }> } };

export type GetUserPermissionsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserPermissionsQuery = { __typename?: 'Query', user: { __typename?: 'AdvisorSchema', permissionList: Array<string> } };

export type GetUserSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSettingsQuery = { __typename?: 'Query', user: { __typename?: 'AdvisorSchema', settings: Array<{ __typename?: 'SettingDto', id?: string | null, code: string, isAllowedMultiple?: boolean | null, name?: string | null, options?: Array<SelectedOption> | null, selectedOptions: Array<SelectedOption> }> } };

export type FavoritesMutationVariables = Exact<{
  favoritesInput: FavoriteMutationInputDto;
}>;


export type FavoritesMutation = { __typename?: 'Mutation', favorite: { __typename?: 'FavoriteMutationOutputDto', transactionList: Array<{ __typename?: 'DeleteMutationTransactionOutputDto', operation: Operation, errorList?: Array<{ __typename?: 'CustomError', code: number, message: string }> | null } | { __typename?: 'UpsertFavoriteMutationTransactionOutputDto', operation: Operation, data?: { __typename?: 'FavoriteDto', id: string, code: string, orderPreviousPage?: string | null } | null, errorList?: Array<{ __typename?: 'CustomError', code: number, message: string }> | null }> } };

export type SettingsMutationVariables = Exact<{
  settingsInput: SettingMutationInputDto;
}>;


export type SettingsMutation = { __typename?: 'Mutation', setting: { __typename?: 'SettingMutationOutputDto', transactionList: Array<{ __typename?: 'UpsertSettingMutationTransactionOutputDto', operation: Operation, data?: { __typename?: 'SettingDto', id?: string | null, code: string, selectedOptions: Array<SelectedOption> } | null, errorList?: Array<{ __typename?: 'CustomError', code: number, message: string }> | null }> } };



export const GetUserFavoritesDocument = `
    query getUserFavorites {
  user {
    favorites {
      id
      code
      orderPreviousPage
    }
  }
}
    `;

export const useGetUserFavoritesQuery = <
      TData = GetUserFavoritesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUserFavoritesQueryVariables,
      options?: Omit<UseQueryOptions<GetUserFavoritesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUserFavoritesQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserFavoritesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['getUserFavorites'] : ['getUserFavorites', variables],
    queryFn: fetcher<GetUserFavoritesQuery, GetUserFavoritesQueryVariables>(client, GetUserFavoritesDocument, variables, headers),
    ...options
  }
    )};

export const GetUserPermissionsDocument = `
    query getUserPermissions($id: String) {
  user {
    permissionList(id: $id)
  }
}
    `;

export const useGetUserPermissionsQuery = <
      TData = GetUserPermissionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUserPermissionsQueryVariables,
      options?: Omit<UseQueryOptions<GetUserPermissionsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUserPermissionsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserPermissionsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['getUserPermissions'] : ['getUserPermissions', variables],
    queryFn: fetcher<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(client, GetUserPermissionsDocument, variables, headers),
    ...options
  }
    )};

export const GetUserSettingsDocument = `
    query getUserSettings {
  user {
    settings {
      id
      code
      isAllowedMultiple
      name
      options
      selectedOptions
    }
  }
}
    `;

export const useGetUserSettingsQuery = <
      TData = GetUserSettingsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUserSettingsQueryVariables,
      options?: Omit<UseQueryOptions<GetUserSettingsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUserSettingsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserSettingsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['getUserSettings'] : ['getUserSettings', variables],
    queryFn: fetcher<GetUserSettingsQuery, GetUserSettingsQueryVariables>(client, GetUserSettingsDocument, variables, headers),
    ...options
  }
    )};

export const FavoritesDocument = `
    mutation favorites($favoritesInput: FavoriteMutationInputDto!) {
  favorite(favoriteInput: $favoritesInput) {
    transactionList {
      ... on UpsertFavoriteMutationTransactionOutputDto {
        operation
        data {
          id
          code
          orderPreviousPage
        }
        errorList {
          code
          message
        }
      }
      ... on DeleteMutationTransactionOutputDto {
        operation
        errorList {
          code
          message
        }
      }
    }
  }
}
    `;

export const useFavoritesMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<FavoritesMutation, TError, FavoritesMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<FavoritesMutation, TError, FavoritesMutationVariables, TContext>(
      {
    mutationKey: ['favorites'],
    mutationFn: (variables?: FavoritesMutationVariables) => fetcher<FavoritesMutation, FavoritesMutationVariables>(client, FavoritesDocument, variables, headers)(),
    ...options
  }
    )};

export const SettingsDocument = `
    mutation settings($settingsInput: SettingMutationInputDto!) {
  setting(settingInput: $settingsInput) {
    transactionList {
      ... on UpsertSettingMutationTransactionOutputDto {
        operation
        data {
          id
          code
          selectedOptions
        }
        errorList {
          code
          message
        }
      }
    }
  }
}
    `;

export const useSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SettingsMutation, TError, SettingsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SettingsMutation, TError, SettingsMutationVariables, TContext>(
      {
    mutationKey: ['settings'],
    mutationFn: (variables?: SettingsMutationVariables) => fetcher<SettingsMutation, SettingsMutationVariables>(client, SettingsDocument, variables, headers)(),
    ...options
  }
    )};
