import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import i18next from 'i18next';
import { NotificationContext } from './NotificationContext';
import { Notification, NotificationErrorData, NotificationSuccessData } from './types';
import { NotificationComponent } from './NotificationComponent';

export type NotificationProviderProps = {
  children: ReactNode;
};

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [data, setData] = useState<Notification | null>(null);

  const hide = () => {
    setData(null);
  };

  const showSuccess = ({ title, description, noTitle }: NotificationSuccessData) => {
    setData({ type: 'success', title, description, noTitle });
  };

  const defaultErrorTitle = i18next.t('general.toasters.loading.generic.title');
  const defaultErrorDescription = i18next.t('general.toasters.loading.error');
  const getErrorDescription = (status?: number): string => {
    switch (status) {
      case 404:
      case 409:
        return i18next.t(`general.toasters.loading.${status}.description`);
      default:
        return i18next.t('general.toasters.loading.error');
    }
  };

  const showError = useCallback(
    (errorData?: NotificationErrorData) => {
      if (!errorData) {
        setData({
          type: 'error',
          title: defaultErrorTitle,
          description: defaultErrorDescription,
          noTitle: false
        });
        return;
      }

      const { status, title, description, noTitle } = errorData;

      if (status === 403) return;

      setData({
        type: 'error',
        title: title || defaultErrorTitle,
        noTitle,
        description: description || getErrorDescription(status),
      });
    },
    [defaultErrorDescription, defaultErrorTitle],
  );

  const value = useMemo(() => ({ data, hide, showSuccess, showError }), [data, showError]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <NotificationComponent />
    </NotificationContext.Provider>
  );
};
