import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config/api-urls';
import { getContext, getToken } from '@dvag/dfs-orchestrator-client';
import { GraphQLClient } from 'graphql-request';

const getHeaders = () => {
  const accessToken = getToken();
  const { user } = getContext();
  return {
    Authorization: `Bearer ${accessToken}`,
    ...(user.parentUserId ? { 'parent-user-id': user.parentUserId } : {}),
  };
};

const graphQlUrl = getEnvironmentApiUrls().graphQl;

export const getGraphQLClient = (service: keyof typeof graphQlUrl = 'person') => {
  const url = graphQlUrl[service];
  return new GraphQLClient(url, { headers: getHeaders() });
};
