import { DxButton } from '@dvag/design-system-react';
import i18next from 'i18next';
import { Booking } from 'type/advisor';
import { checkIcon } from 'utils/util';

import { moduleType } from '../../utils/intex';
import { useGetUrlFromModule } from '../../service/useGetUrlFromModule';

interface RecommendationBasicPageActionButtonsProps {
  booking: Booking | undefined;
  teambuildingUrl?: string;
  teambuilding?: boolean;
}

const translation = {
  ten_questions: i18next.t('recommendationPage.ten_questions'),
  consultFolder: i18next.t('recommendationPage.consultFolder'),
  makeAnAppointment: i18next.t('recommendationPage.makeAnAppointment'),
  EasyVB: i18next.t('recommendationPage.EasyVB'),
};

export const RecommendationBasicPageActionButtons = ({
  booking,
  teambuildingUrl,
  teambuilding = false,
}: RecommendationBasicPageActionButtonsProps) => {
  const currentModuleType = teambuilding ? moduleType.TEAMBUILDING : moduleType.RECOMMENDATION;
  const { data: url, isError } = useGetUrlFromModule(currentModuleType);
  return (
    <div className="recommendation-basic-page-actions-container">
      {teambuilding && teambuildingUrl && (
        <DxButton
          id="ten-questions-button"
          data-testid="ten-questions-button"
          icon={checkIcon('link-extern')}
          iconposition="right"
          type="text"
          onClick={() =>
            window.open(
              `${teambuildingUrl}/karriere/10-fragen.html`,
              '_blank',
              'noopener,noreferrer',
            )
          }
          label={translation.ten_questions}
        />
      )}
      {url && !isError && (
        <DxButton
          id="consult-folder-button"
          data-testid="consult-folder-button"
          icon={checkIcon('link-extern')}
          iconposition="right"
          type="text"
          onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
          label={translation.consultFolder}
        />
      )}
      {booking?.url && (
        <DxButton
          id="booking-button"
          data-testid="booking-button"
          icon={checkIcon('link-extern')}
          iconposition="right"
          type="text"
          onClick={() => window.open(booking.url, '_blank', 'noopener,noreferrer')}
          label={translation.makeAnAppointment}
        />
      )}
      {teambuilding && (
        <DxButton
          id="easy-vb-button"
          data-testid="easy-vb-button"
          icon={checkIcon('link-extern')}
          iconposition="right"
          type="text"
          onClick={() =>
            window.open('https://vm-vb-einstellung.dvag/#/', '_blank', 'noopener,noreferrer')
          }
          label={translation.EasyVB}
        />
      )}
    </div>
  );
};
