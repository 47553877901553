import { appNames } from '@dvag/dfs-constant-config/app-names';
import { sharedAppRoutes } from '@dvag/dfs-constant-config/shared-app-names';
import i18next from 'i18next';

import { buildPath } from 'service/buildPath';
import { analyseDashboard } from 'config/asset/analyseDashboard';
import { AnalyseTopPagesData } from 'type/sectionList';

const topSections = {
  companyPresentations: {
    name: 'companyPresentation',
    id: 'company-presentation',
    getPath: (householdId: string) =>
      `/${appNames.vpd}${buildPath('/haushalt/:householdId/analyse/unternehmenspraesentation', {
        householdId,
      })}`,
    displayValue: i18next.t('analyseDashboard.top.companyPresentation.header'),
    description: i18next.t('analyseDashboard.top.companyPresentation.description'),
    image: analyseDashboard.companyPresentation,
    coverPosition: 'center',
  },
  wishesAndGoals: {
    name: 'wishesAndGoals',
    id: 'wishes-and-goals',
    getPath: (householdId: string) =>
      `/${appNames.person}${buildPath('/haushalt/:householdId/analyse/wuensche-und-ziele', {
        householdId,
      })}`,
    displayValue: i18next.t('analyseDashboard.top.wishesAndGoals.header'),
    description: i18next.t('analyseDashboard.top.wishesAndGoals.description'),
    image: analyseDashboard.wishesAndGoals,
    coverPosition: 'center right',
    nextUrl: (householdId: string) =>
      `/${appNames.vpd}${buildPath(sharedAppRoutes.vpd.analysis.ffg.start, { householdId })}`,
  },
  financialHappinessFormula: {
    name: 'financialHappinessFormula',
    id: 'financial-happiness-formula',
    getPath: (householdId: string) =>
      `/${appNames.vpd}${buildPath(sharedAppRoutes.vpd.analysis.ffg.start, {
        householdId,
      })}`,
    displayValue: i18next.t('analyseDashboard.top.financialHappiness.header'),
    description: i18next.t('analyseDashboard.top.financialHappiness.description'),
    image: analyseDashboard.financialHappiness,
    coverPosition: 'center left',
  },
};

export const topSectionPageList: AnalyseTopPagesData[] = [
  topSections.companyPresentations,
  topSections.wishesAndGoals,
  topSections.financialHappinessFormula,
];
