import React, { useState } from 'react';

import i18next from 'i18next';

import { SearchInput } from './searchInput/SearchInput';
import './style.css';

interface FilterProps {
  initialSearchTerm?: string;
  onSubmit: (value: string) => void;
  formErrorStatus?: number;
}
const MIN_TERM_LENGTH = 3;

export const Filter = ({ initialSearchTerm = '', onSubmit, formErrorStatus }: FilterProps) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [inputError, setInputError] = useState(false);

  let rangeInputError = formErrorStatus === 416;
  let personIdError = formErrorStatus === 404;

  const handleSubmit = () => {
    const term = searchTerm ? searchTerm.trim() : '';

    if (term.length >= MIN_TERM_LENGTH) {
      onSubmit(term);
    } else {
      onSubmit('');
      setInputError(true);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    onSubmit('');
    setInputError(false);
    rangeInputError = false;
    personIdError = false;
  };

  const handleChange = (value: string) => {
    if (searchTerm && searchTerm.length > 1 && !value) {
      handleClear();
    }

    setInputError(false);
    rangeInputError = false;
    personIdError = false;
    setSearchTerm(value);
  };

  const handleErrorMessage = () => {
    if (inputError) {
      return i18next.t('dashboard.filter.input.search.error', {
        minTermLength: MIN_TERM_LENGTH,
      });
    }
    if (rangeInputError) {
      return i18next.t('dashboard.filter.input.search.rangeError');
    }
    if (personIdError) {
      return i18next.t('dashboard.filter.input.search.wrongPersonId');
    }
    return '';
  };

  return (
    <form
      className="filter-box"
      style={{ marginBottom: formErrorStatus && !handleErrorMessage() ? '20px' : '' }}
      data-testid="hhw-form"
    >
      <SearchInput
        id="searchName"
        onChange={handleChange}
        placeholder={i18next.t('dashboard.filter.input.search.placeholder')}
        value={searchTerm}
        handleSubmit={handleSubmit}
        errorMessage={handleErrorMessage()}
      />
    </form>
  );
};
