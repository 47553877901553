import { getToken } from "@dvag/dfs-orchestrator-client";

export interface AuthAdapterInterface {
  handleForbidden(message: string): Promise<unknown>;
  handleUnauthorized(message: string): Promise<unknown>;
  authenticationToken(): Promise<string | undefined>;
}

export const AuthAdapter = () => ({
  async authenticationToken() {    
    return getToken();
  },

  handleForbidden(message: string) {
    return Promise.resolve(message);
  },

  handleUnauthorized(message: string) {
    return Promise.resolve(message);
  },
});
