import { constant } from './constant';

const local = 'dashboard';

export const dashboard = {
  DashboardHeader: `${constant.route}${local}/DashboardHeader.jpg`,
  Analyse: `${constant.route}${local}/Analyse.jpg`,
  Beratung: `${constant.route}${local}/Beratung.jpg`,
  Produkte: `${constant.route}${local}/Produkte.jpg`,
  Planning: `${constant.route}${local}/Planning.jpg`,
};
