import React from 'react';

import { DxText } from '@dvag/design-system-react';
import { PersonFullName } from 'type/person';

import { isNameListContain } from './isNameListContain';

import './style.css';

export const getPersonFullName = (person: PersonFullName | null) =>
  `${person?.firstName} ${person?.lastName}`;

interface NameListProps {
  list: PersonFullName[];
  searchTerm?: string[];
}

export const NameList = ({ list, searchTerm = [] }: NameListProps) => (
  <div className="name-wr" data-testid="name-wr">
    <DxText color="headline">
      {list.map((person, index) =>
        isNameListContain(searchTerm, person.firstName, person.lastName) ? (
          <span className="matched-name" key={`${index.toString()}`} data-testid="matched-name-wr">
            {getPersonFullName(person)}
          </span>
        ) : (
          <span className="name" key={`${index.toString()}`}>
            {getPersonFullName(person)}
          </span>
        ),
      )}
    </DxText>
  </div>
);
