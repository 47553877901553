import { DxAlert, DxButton } from '@dvag/design-system-react';

import i18next from 'i18next';

import { useEffect, useState } from 'react';

interface UnsavedDataModalProps {
  onCancel: (() => void) | undefined;
  onConfirm: (() => void) | undefined;
  showModal: boolean;
}

export const UnsavedDataModal = ({ onCancel, onConfirm, showModal }: UnsavedDataModalProps) => {
  const [show, setShow] = useState(false);
  const translation = {
    attention: i18next.t('warning.attention'),
    unsavedDataText: i18next.t('warning.unsavedDataText'),
    leave: i18next.t('general.leave'),
    cancel: i18next.t('general.cancel'),
  };
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const escPrevent = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escPrevent);
    return () => {
      document.removeEventListener('keydown', escPrevent);
    };
  }, []);

  return (
    <DxAlert
      id="moving-away-alert"
      data-testid="moving-away-alert-unsaved"
      visible={show}
      titlestring={translation.attention}
      actionsdirection="row"
      content={translation.unsavedDataText}
    >
      <DxButton
        type="primary-s"
        id="unsaved-data-confirm"
        data-testid="unsaved-data-confirm"
        onClick={() => {
          setShow(false);
          onConfirm?.();
        }}
        label={translation.leave}
        slot="action"
      />
      <DxButton
        type="text"
        id="unsaved-data-cancel"
        data-testid="unsaved-data-cancel"
        onClick={onCancel}
        label={translation.cancel}
        slot="action"
      />
    </DxAlert>
  );
};
