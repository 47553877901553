import {
  DxButton,
  DxCard,
  DxCardContent,
  DxContainer,
  DxGrid,
  DxIcon,
  DxProcessHeader,
  DxText,
} from '@dvag/design-system-react';
import { PageCodes } from '@dvag/dfs-constant-config';
import i18next from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { UpdateManyResources } from '@dvag/dfs-ui-blocks/hooks/useAutosaveHelper';
import { useGetFavorites, useUpdateFavorites } from '@dvag/dfs-ui-blocks/hooks';
import { getToken } from '@dvag/dfs-orchestrator-client';
import { navigatePrevious } from '@dvag/dfs-orchestrator-client/messengers';
import {
  FavoritePage,
  filterFavorites,
  useFavoritesAutosave,
  useFavoritesSearch,
} from '@dvag/dfs-ui-blocks/components';
import { checkIcon } from 'utils/util';
import { useGetFavoritesCatalog } from 'hooks/useGetFavoritesCatalog';
import { FavoritesCatalog } from './component/FavoritesCatalog/FavoritesCatalog';
import { ListsSeparator } from './component/ListsSeparator/ListsSeparator';
import SearchInput from './component/SearchInput/SearchInput';
import { UserFavorites } from './component/UserFavorites/UserFavorites';
import { useNotification } from '../../utils/notification/NotificationContext';
import { useContinuation } from '../../utils/useContinuation';
import { UnsavedOrPendingModal } from '../../component/UnsavedOrPendingModal';
import { permissionMap } from '../../utils/permission/permissionMap';
import featureFlag from '../../service/featureFlag';
import './style.css';

export const Favorites = () => {
  const pagesQuery = useGetFavorites(
    getToken,
    featureFlag.gb?.isOn(permissionMap.canAccessNSimulation),
    featureFlag.gb?.isOn('new-catalog-dashboard'),
  );

  const [favorites, setFavorites] = useState<FavoritePage[]>(pagesQuery.data);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const catalogFavoritesQuery = useGetFavoritesCatalog(
    featureFlag.gb?.isOn(permissionMap.canAccessNSimulation),
  );
  const [catalog, setCatalog] = useState(catalogFavoritesQuery.data || []);

  const { showError } = useNotification();
  const errorToast = (errorMessage: string) => {
    showError({ description: errorMessage });
  };

  const { continuation, getContinuationHandler, getCanContinueHandler, onContinuationRequest } =
    useContinuation();
  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const updateFavorites = useUpdateFavorites(errorToast, getToken);

  const [favoritesCatalog, setFavoritesCatalog] = useState<FavoritePage[]>([]);

  const getAliases = (code: PageCodes) => catalog.find((p) => p.code === code)?.aliases || [];

  const onIsDirtyChange = (isDirtyParam: boolean) => {
    if (!isDirtyParam) {
      getCanContinueHandler();
    }
    onCanContinueChange(!isDirtyParam);
  };

  const { move, remove, append, getAllResources, flushChanges } = useFavoritesAutosave(
    favorites,
    updateFavorites.mutateAsync as unknown as UpdateManyResources<FavoritePage>,
    (requestResponses) => {
      const deletedFavorites = requestResponses
        .filter((requestResponse) => requestResponse.action === 'DELETE')
        .map((a) => ({ ...a.request }));
      setFavoritesCatalog((currentFavorites) => [
        ...currentFavorites.map((fav) => {
          const deletedFavorite = deletedFavorites.find((df) => df.code === fav.code);
          if (deletedFavorite) {
            delete deletedFavorite.id;
            return deletedFavorite;
          }
          return fav;
        }),
      ]);
    },
    setIsSubmitting,
    onIsDirtyChange,
  );

  const { filteredFavorites, searchValue, setSearchValue } = useFavoritesSearch(
    getAllResources(),
    false,
    getAliases,
  );

  useEffect(() => {
    const hasFavorite = (code: string) =>
      pagesQuery.data.find((favorite) => code === favorite.code);
    setFavorites(pagesQuery.data);
    setCatalog(catalogFavoritesQuery.data || []);

    setFavoritesCatalog(
      catalogFavoritesQuery.data?.filter(
        ({ code, permissions, featureFlags }) =>
          !hasFavorite(code) &&
          featureFlags?.every((featureFlagCode) => featureFlag.gb?.isOn(featureFlagCode)) &&
          permissions?.every((permission) =>
            window?.orchestratorContext?.user?.permissions.includes(permission),
          ),
      ) || [],
    );
  }, [catalogFavoritesQuery.data, pagesQuery.data]);

  const filteredFavoritesCatalog = filterFavorites(
    searchValue,
    false,
    favoritesCatalog,
    getAliases,
  );

  useEffect(() => {
    onContinuationRequest(flushChanges);
  }, [flushChanges, onContinuationRequest]);

  return (
    <>
      <DxProcessHeader
        headline={i18next.t('favorites.headline')}
        data-testid="favorites-headline"
      />
      <DxContainer type="page" color="transparent">
        <DxCard className="favorites-card">
          <DxCardContent>
            <DxContainer color="transparent" className="flex">
              <DxContainer color="transparent">
                <DxIcon
                  color="gray-83"
                  icon={checkIcon('information')}
                  size={24}
                  className="info-icon"
                />
              </DxContainer>
              <DxText type="ps">{i18next.t('favorites.information')}</DxText>
            </DxContainer>

            <DxGrid mq1="12/12/12" rowgap="24">
              <SearchInput searchValue={searchValue} isDisabled={false} onSearch={setSearchValue} />
              <DxContainer className="favorites-lists-container" color="transparent">
                <DxGrid
                  mq1="12/12/12"
                  mq2="12/12/12"
                  mq3="5-2-5"
                  mq4="5-2-5"
                  mq5="5-2-5"
                  className="content-grid"
                >
                  <UserFavorites
                    pages={filteredFavorites || []}
                    onItemMove={move}
                    onItemRemove={(_favoriteIndex, favorite) => {
                      setFavoritesCatalog([...favoritesCatalog, favorite]);
                      remove(getAllResources().findIndex((f) => f.code === favorite.code));
                    }}
                    canMoveItems={!searchValue}
                  />
                  <ListsSeparator />
                  <FavoritesCatalog
                    catalogFavorites={filteredFavoritesCatalog || []}
                    onItemClick={(favorite) => {
                      setFavoritesCatalog(favoritesCatalog.filter((f) => f.code !== favorite.code));
                      append(favorite);
                    }}
                  />
                </DxGrid>
              </DxContainer>
            </DxGrid>
          </DxCardContent>
        </DxCard>
        <DxButton
          type="text"
          style={{ marginBottom: '8px' }}
          data-testid="favorites-back"
          id="favorites-back"
          onClick={getContinuationHandler(navigatePrevious)}
          label={i18next.t('favorites.exitAndBack')}
          icon={checkIcon('pfeil-links')}
        />
      </DxContainer>
      <UnsavedOrPendingModal
        showPending={continuation !== undefined && isSubmitting}
        showUnsaved={continuation !== undefined && !isSubmitting}
        onCancel={continuation?.onCancel}
        onConfirm={continuation?.onConfirm}
      />
    </>
  );
};
