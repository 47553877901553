import { useMutation } from '@tanstack/react-query';

import { personApi } from 'service/api/personApi';
import { BasicDataConsentPayload } from 'type/basicData';
import { useNotification } from 'utils/notification/NotificationContext';
import { CustomError } from '@dvag/dfs-api-client/error';

export const useUpdatePersonConsentData = (personId: string) => {
  const { showError } = useNotification();
  const personService = personApi();
  return useMutation<
    Awaited<ReturnType<typeof personService.updatePerson>>,
    CustomError,
    BasicDataConsentPayload,
    unknown
  >({
    mutationFn: async (data: BasicDataConsentPayload) => {
      const payload = {
        ...data,
      };
      delete payload.status;

      return personService.updatePerson(personId, payload);
    },
    onError: (error: CustomError) => {
      showError({ status: error?.status });
    },
  });
};
