import React from 'react';
import { useParams } from 'react-router-dom';
import { DxContainer, DxCard, DxList } from '@dvag/design-system-react';

import featureFlag from 'service/featureFlag';
import { permissionMap } from 'utils/permission/permissionMap';
import { useGetHouseholdDashboard } from 'hooks/useGetHouseholdDashboard';
import { hasApprovedConsent } from 'utils/util';
import { showMessage } from '@dvag/dfs-orchestrator-client/messengers';

import { NotificationErrorData } from 'utils/notification/types';
import { useGetFavoritesWithAction } from 'hooks/useGetFavoritesWithAction';
import { getUpperList } from './getUpperList';
import { Item } from './Item';

import './style.css';

export const UpperList = ({ personId }: { personId: string | undefined }) => {
  const { householdId, selectedPersonId } = useParams();
  const householdDashboard = useGetHouseholdDashboard();
  const hasHousehold = !!householdId;
  const disabledClass = !hasHousehold ? 'disabled-container' : '';
  const showPlanning = 'permission.apps.dc.fremdverarbeitung_erlauben';
  const showShoppingCart = 'permission.vpd.app-web-vp-digital.shopping-cart';
  const showBankConnection = 'permission.vpd.app-web-vp-digital.kontoauslese';
  const showAnalysisEvaluation = 'permission.vpd.app-web-vp-digital.konzeptmitstrategie';
  const showCoachReport = 'permission.vpd.app-web-vp-digital.coachreport';
  const showAnalysisEvaluationFlag = featureFlag.gb?.isOn(
    permissionMap.canAccessAnalysisEvaluationFlag,
  );
  const showCoachReportFlag = featureFlag.gb?.isOn(permissionMap.canAccessCoachReportFlag);

  type Permission =
    | 'permission.apps.dc.fremdverarbeitung_erlauben'
    | 'permission.vpd.app-web-vp-digital.shopping-cart'
    | 'permission.vpd.app-web-vp-digital.kontoauslese'
    | 'permission.vpd.app-web-vp-digital.konzeptmitstrategie'
    | 'permission.vpd.app-web-vp-digital.coachreport';
  const showPermission = (permission: Permission) =>
    window?.orchestratorContext?.user?.permissions?.includes(permission);
  const hasAtLeastOneConsent = !!householdDashboard.data?.consentList?.find((consent) =>
    hasApprovedConsent(consent.status),
  );

  const { pageAction } = useGetFavoritesWithAction(householdId);

  const upperList = getUpperList({
    householdId,
    personId,
    showPlanning: showPermission(showPlanning),
    showShoppingCart: showPermission(showShoppingCart),
    showBankConnection: showPermission(showBankConnection),
    showAnalysisEvaluation: showPermission(showAnalysisEvaluation) && showAnalysisEvaluationFlag,
    showCoachReport: showPermission(showCoachReport) && showCoachReportFlag,
    hasAtLeastOneConsent,
    showAlert: (data?: NotificationErrorData) =>
      showMessage(data?.title || '', data?.description || '', 'error', 'modal'),
    pageAction,
    isHouseholdDataFetched: householdDashboard.isFetched,
  });

  return (
    <DxContainer
      color="transparent"
      className={`${disabledClass && !selectedPersonId ? 'dashboard-item-container-wr' : ''}`}
      data-testid="upper-list-container"
    >
      <DxCard color="transparent">
        <DxList>
          {upperList.map(
            ({ title, subtitle, image, chevronIcon, onClick, id, display, linkList }) => (
              <Item
                disabledClassName={disabledClass}
                key={id}
                id={id}
                title={title}
                subtitle={subtitle}
                image={image}
                chevronIcon={chevronIcon}
                onClick={onClick}
                display={display}
                linkList={linkList}
                hasHousehold={hasHousehold}
              />
            ),
          )}
        </DxList>
      </DxCard>
    </DxContainer>
  );
};
