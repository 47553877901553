import { useQuery } from '@tanstack/react-query';
import { appointmentApi } from 'service/api/appointmentApi';

type FeedbackProp = {
  householdId?: string;
  appointmentId?: string;
};

export const getFeedbackKey = (householdId?: string, appointmentId?: string) => [
  `feedback-${appointmentId}-${householdId}`,
];

export const useFeedback = ({ householdId, appointmentId }: FeedbackProp) => {
  const appointmentService = appointmentApi();
  return useQuery({
    queryKey: getFeedbackKey(householdId, appointmentId),
    queryFn: async () =>
      appointmentService.getFeedback({
        appointmentId,
        householdId,
      }),
    enabled: !!appointmentId && !!householdId,
    select: (response) => response?.data,
  });
};
